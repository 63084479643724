.login-container {
    min-width: 300px;
    display: grid;
    grid-template-columns: 4fr 5fr;
    background: #fff;
    border:1px solid #efeeee;
}



.login-container .login-form-wrapper {
    padding: 2.5rem 2.5rem 6rem;
}

.login-container .side-wrapper {
    background: #334ecd;
    color:#fff; 
    padding: 3rem 100px;
    display: flex;
    align-items: center;
}

.login-container .side-wrapper h1 {
    font-size: 48px;
    font-weight: 800;

}   


.login-container .bp3-input {
    background: #f6f6f6;
    box-shadow: none;
    /* border: 1px solid #f2f2f2; */
}

.login-container label.bp3-label {
    font-size: 14px;
    font-weight: 500;
}

.bp3-form-group label.bp3-label  {
    margin-bottom: 8px;
}

.login-container .bp3-form-group {
    margin-bottom: 1.3rem;
}


.login-form-wrapper .head {
    margin-bottom: 3rem;
    margin-top: 2rem;
}




button:disabled {
    opacity: 0.6;
}
.logo-group {
    display: grid;
    grid-template-columns: 40px 1fr;
}

.logo-group .logo-icon {
    width: 100%;

}

.logo-group .logo-icon img {
    width: 100%;
}

.logo-group .logo-text {
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    color: #3d3d3d;
}