.App {
  /* text-align: center; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,.07);
  justify-content: space-between;
  display: flex;
  /* width: 100%; */
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px !important;
  /* font-size: calc(10px + 2vmin); */
  color: #262627;
  padding:1px 20px;
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  z-index: 100;

}

.logoIcon {
  margin-left: 18px;
}


.h-top-adjust {
  margin-top: 60px;
}

.w-adjust {
  margin-left: 105px;
  width: calc(100vw - 105px);
}

.app-header-left {
  font-size: 16px;
}

.App-link {
  color: #61dafb;
}

.App {
  background: #fafafa;
}


.content-container {
  min-height: calc(100vh - 4rem);
  position: relative;
  z-index: 1;
}

/* .content-container::after {
  content:'';
  position: absolute;
  width: 100vw;
  height: 15vh;
  background: rgb(255, 104, 104);
  z-index: -1;
  top:0;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.campaign {
    /* width:178px;
    height:200px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 2px 12px rgba(0,0,0,.06); */
  /* margin: 15px; */
  background: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  
}

.campaign:hover::before {
  opacity: 1;
}

/* .campaign::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 2px 12px rgba(0,0,0,.06), 0 8px 14px rgba(0,0,0,.04), 0 12px 16px rgba(0,0,0,.02);
  border-radius: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
} */

.campaign-list {
 
  display: grid;
        grid-template-columns: 1fr 1fr 1fr;
}

.campaign .campaign-detail {
  flex:3;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.campaign-new {
  background: #15B371
}

.campaign .campaign-stats {
  flex:1
}

.blockbuilder-branding {
  display: none;
}


.logoWrap {
  font-size: 18px;
  font-weight: 800;
  color:#FF5757
}


a {
  text-decoration: none;

}

a:hover {
  text-decoration: none;
}

.pri-button {
  background: #fe5756; 
  padding: 10px 20px;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
 }



 .box-container {
  min-width: 300px;
  display: grid;
  
  background: #fff;
  border:1px solid #efeeee;
}


.settings-container { 
  grid-template-columns: 1fr 7fr;
}

.dash-table--head {
  color: #a1a1a1 !important;
  font-weight: 300 !important;
}

.dash-table thead {
  /* background: #f8f8f8; */
  border-radius: 10px
}

table.dash-table tbody tr:first-child td {
  /* box-shadow: none!important; */
}

table.dash-table tbody td {
  padding: 16px 30px 16px 0px;
}

table.dash-table th {
  padding:11px 21px 11px 0;

}


.sm-table td {
  font-size: 13px;
}



.dash-table--head-gray {
  font-size: 12px;
  color: #a5a5a5;
}

.copy-button {
  max-width: 25px;
  max-height: 25px;
}

.copy-button::before {
  font-size: 13px !important;
}

