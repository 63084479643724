.dash-side {
    width: 105px;
    border-right: 1px solid #e5e5e5;
    background: #fff;
    position: fixed;
    top: 60px;
    height: calc(100vh - 60px);
    left: 0;
}
#HW_badge_cont {
    /* position: absolute !important; */
    /* top: 0;
    right: 25px; */

  }


.dash-menu-ul  {
    margin: 0;
    padding: 0;
}


.dash-menu-li-button {
    padding: 22px 0px;
    border-radius: 0;
}

.dash-menu-li-button--active {
    border-right:3px solid #116ba3;
    background-color: #f3faff !important;
}

.dash-menu-li-button--active .txt {
    opacity: 1 !important;
}


.dash-menu-li-button  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dash-menu-li-button .txt {
    font-size: 12px;
    opacity: 0.7;
    margin-top: 10px;
}

.dash-menu-li-button .bp3-icon {
    margin: 0;
}