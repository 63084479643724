/* CircularStd @font-face kit */
@font-face {
	font-family: 'CircularStd';
	src: url('./fonts/CircularStd-Black.eot');
	src: url('./fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'), url('./fonts/CircularStd-Black.woff') format('woff'), url('./fonts/CircularStd-Black.ttf') format('truetype'), url('./fonts/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 800;
	font-style: normal;
  }

  @font-face {
	font-family: 'CircularStd';
	src: url('./fonts/CircularStd-Bold.eot');
	src: url('./fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/CircularStd-Bold.woff') format('woff'), url('./fonts/CircularStd-Bold.ttf') format('truetype'), url('./fonts/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 600;
	font-style: normal;
  }

  @font-face {
	font-family: 'CircularStd';
	src: url('./fonts/CircularStd-Medium.eot');
	src: url('./fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/CircularStd-Medium.woff') format('woff'), url('./fonts/CircularStd-Medium.ttf') format('truetype'), url('./fonts/CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 500;
	font-style: normal;
  }
 
  @font-face {
	font-family: 'CircularStd';
	src: url('./fonts/CircularStd-Book.eot');
	src: url('./fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'), url('./fonts/CircularStd-Book.woff') format('woff'), url('./fonts/CircularStd-Book.ttf') format('truetype'), url('./fonts/CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd') format('svg');
	font-weight: 400;
	font-style: normal;
  }


html {
	font-family: 'CircularStd', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
body {
	margin: 0;
	padding: 0;
	color: hsl(0, 0%, 20%) !important;
	font-family: 'CircularStd', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: normal;
	/* font-size: .8rem; */
	text-decoration: none;
	position: relative;
	width: 100%;
	height: 100%;
	line-height: 1.25;
	overflow: auto
}
body.red {
	background: hsl(352, 84%, 56%)
}
.shopperLand {
	font-family: 'CircularStd', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.shopperLand * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.shopperLand .appearance-none {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}
.shopperLand input:not([type='submit']),
.shopperLand textarea {
	outline: 0;
	color: #444
}
.shopperLand a {
	outline: 0
}
.shopperLand textarea {
	resize: none
}
.shopperLand input[type='text'],
.shopperLand input[type='email'],
.shopperLand input[type='password'],
.shopperLand input[type='tel'],
.shopperLand input[type='url'],
.shopperLand input[type='submit'],
.shopperLand[contenteditable=plaintext-only],
.shopperLand[contenteditable=true],
.shopperLand button,
.shopperLand textarea,
.shopperLand .styled-input {
	border-width: 2px;
	border-style: solid;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}
.shopperLand input[type='text']:not(.border-thick),
.shopperLand input[type='email']:not(.border-thick),
.shopperLand input[type='password']:not(.border-thick),
.shopperLand input[type='tel']:not(.border-thick),
.shopperLand input[type='url']:not(.border-thick),
.shopperLand input[type='submit']:not(.border-thick),
.shopperLand[contenteditable=plaintext-only]:not(.border-thick),
.shopperLand[contenteditable=true]:not(.border-thick),
.shopperLand button:not(.border-thick),
.shopperLand textarea:not(.border-thick),
.shopperLand .styled-input:not(.border-thick) {
	border-color: rgba(0, 0, 0, 0)
}
.shopperLand input[type='text']:not(.border-thick):active,
.shopperLand input[type='email']:not(.border-thick):active,
.shopperLand input[type='password']:not(.border-thick):active,
.shopperLand input[type='tel']:not(.border-thick):active,
.shopperLand input[type='url']:not(.border-thick):active,
.shopperLand input[type='submit']:not(.border-thick):active,
.shopperLand[contenteditable=plaintext-only]:not(.border-thick):active,
.shopperLand[contenteditable=true]:not(.border-thick):active,
.shopperLand button:not(.border-thick):active,
.shopperLand textarea:not(.border-thick):active,
.shopperLand .styled-input:not(.border-thick):active,
.shopperLand input[type='text']:not(.border-thick):focus,
.shopperLand input[type='email']:not(.border-thick):focus,
.shopperLand input[type='password']:not(.border-thick):focus,
.shopperLand input[type='tel']:not(.border-thick):focus,
.shopperLand input[type='url']:not(.border-thick):focus,
.shopperLand input[type='submit']:not(.border-thick):focus,
.shopperLand[contenteditable=plaintext-only]:not(.border-thick):focus,
.shopperLand[contenteditable=true]:not(.border-thick):focus,
.shopperLand button:not(.border-thick):focus,
.shopperLand textarea:not(.border-thick):focus,
.shopperLand .styled-input:not(.border-thick):focus {
	border-color: rgba(0, 0, 0, 0.25)
}
.shopperLand[contenteditable][contenteditable] {
	border-width: 0
}
.shopperLand button.border-transparent:not(.border-thick) {
	border-color: transparent
}
.shopperLand .text-white input,
.shopperLand input.text-white,
.shopperLand .text-white textarea {
	color: white
}
.shopperLand .text-white input[type='text'],
.shopperLand .text-white input[type='email'],
.shopperLand .text-white input[type='password'],
.shopperLand .text-white input[type='tel'],
.shopperLand .text-white input[type='submit'],
.shopperLand .text-white[contenteditable=plaintext-only],
.shopperLand .text-white[contenteditable=true],
.shopperLand .text-white button,
.shopperLand .text-white textarea,
.shopperLand .text-white .styled-input {
	border-color: rgba(255, 255, 255, 0)
}
.shopperLand .text-white input[type='text']:active,
.shopperLand .text-white input[type='email']:active,
.shopperLand .text-white input[type='password']:active,
.shopperLand .text-white input[type='tel']:active,
.shopperLand .text-white input[type='submit']:active,
.shopperLand .text-white[contenteditable=plaintext-only]:active,
.shopperLand .text-white[contenteditable=true]:active,
.shopperLand .text-white button:active,
.shopperLand .text-white textarea:active,
.shopperLand .text-white .styled-input:active,
.shopperLand .text-white input[type='text']:focus,
.shopperLand .text-white input[type='email']:focus,
.shopperLand .text-white input[type='password']:focus,
.shopperLand .text-white input[type='tel']:focus,
.shopperLand .text-white input[type='submit']:focus,
.shopperLand .text-white[contenteditable=plaintext-only]:focus,
.shopperLand .text-white[contenteditable=true]:focus,
.shopperLand .text-white button:focus,
.shopperLand .text-white textarea:focus,
.shopperLand .text-white .styled-input:focus,
.shopperLand .text-white input[type='text'].active,
.shopperLand .text-white input[type='email'].active,
.shopperLand .text-white input[type='password'].active,
.shopperLand .text-white input[type='tel'].active,
.shopperLand .text-white input[type='submit'].active,
.shopperLand .text-white[contenteditable=plaintext-only].active,
.shopperLand .text-white[contenteditable=true].active,
.shopperLand .text-white button.active,
.shopperLand .text-white textarea.active,
.shopperLand .text-white .styled-input.active {
	border-color: rgba(255, 255, 255, 0.25)
}
.shopperLand a {
	color: hsl(0, 0%, 20%)
}
.shopperLand a:not(.underline) {
	text-decoration: none
}
.shopperLand input.focusable:focus,
.shopperLand .focusable:focus {
	outline: 0;
	box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1)
}


.shopperLand button {
    background: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    -webkit-transition: .2s;
    transition: .2s;
    text-shadow: none;
    border: none;
    
    display: inline-block;
    font-size: 14px;
    margin: 0;
  
    vertical-align: top;
    white-space: nowrap;
}

.shopperLand .shopper-cta-btn {
    background-color: #f15521!important;
    border-color: #ff7227!important;
    color:#fff;
    height: 50px;
    color: #fff;
    font-family: 'CircularStd', sans-serif !important;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -webkit-transition: .2s;
    transition: .2s;
    white-space: nowrap;
    
    letter-spacing: normal;

    text-shadow: none!important;
    text-transform: none!important;
    padding:0 25px;
}

.shopperLand .shopper-cta-btn .btn-mainText {
    font-weight: 500;
    font-size:18px;
    margin-right: 5px;
}

.shopperLand .shopper-cta-btn .btn-smallText {
    font-weight: 300;
}






.focusable-stroked:focus {
	outline: 0;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1)
}
.flex {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex
}
.flex-inline {
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex
}
.flex-column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column
}
.flex-wrap {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}
.flex-auto {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-width: 0;
	min-height: 0
}
.flex-none {
	-webkit-box-flex: 0;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none
}
.flex-reverse {
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse
}
.flex-column-reverse {
	-webkit-flex-direction: column-reverse;
	flex-direction: column-reverse
}
.items-start {
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	-ms-grid-row-align: flex-start;
	align-items: flex-start
}
.items-end {
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	-ms-grid-row-align: flex-end;
	align-items: flex-end
}
.items-center {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center
}
.items-baseline {
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	-ms-grid-row-align: baseline;
	align-items: baseline
}
.items-stretch {
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	-ms-grid-row-align: stretch;
	align-items: stretch
}
.self-start {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start
}
.self-end {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end
}
.self-center {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center
}
.self-baseline {
	-webkit-align-self: baseline;
	-ms-flex-item-align: baseline;
	align-self: baseline
}
.self-stretch {
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch
}
.justify-start {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start
}
.justify-end {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end
}
.justify-center {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center
}
.justify-between {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between
}
.justify-around {
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around
}
.content-start {
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start
}
.content-end {
	-webkit-align-content: flex-end;
	-ms-flex-line-pack: end;
	align-content: flex-end
}
.content-center {
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center
}
.content-between {
	-webkit-align-content: space-between;
	-ms-flex-line-pack: justify;
	align-content: space-between
}
.content-around {
	-webkit-align-content: space-around;
	-ms-flex-line-pack: distribute;
	align-content: space-around
}
.content-stretch {
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch
}
.order-0 {
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0
}
.order-1 {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1
}
.order-2 {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2
}
.order-3 {
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3
}
.order-last {
	-webkit-box-ordinal-group: 100000;
	-webkit-order: 99999;
	-ms-flex-order: 99999;
	order: 99999
}
@media (max-width: 39.99rem) {
	.xs-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}
	.xs-flex-inline {
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex
	}
	.xs-flex-column {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.xs-flex-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.xs-flex-auto {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		min-width: 0;
		min-height: 0
	}
	.xs-flex-none {
		-webkit-box-flex: 0;
		-webkit-flex: none;
		-ms-flex: none;
		flex: none
	}
	.xs-flex-reverse {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.xs-flex-column-reverse {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.xs-items-start {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		-ms-grid-row-align: flex-start;
		align-items: flex-start
	}
	.xs-items-end {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		-ms-grid-row-align: flex-end;
		align-items: flex-end
	}
	.xs-items-center {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		-ms-grid-row-align: center;
		align-items: center
	}
	.xs-items-baseline {
		-webkit-box-align: baseline;
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		-ms-grid-row-align: baseline;
		align-items: baseline
	}
	.xs-items-stretch {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		-ms-grid-row-align: stretch;
		align-items: stretch
	}
	.xs-self-start {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.xs-self-end {
		-webkit-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.xs-self-center {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.xs-self-baseline {
		-webkit-align-self: baseline;
		-ms-flex-item-align: baseline;
		align-self: baseline
	}
	.xs-self-stretch {
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch
	}
	.xs-justify-start {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.xs-justify-end {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.xs-justify-center {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center
	}
	.xs-justify-between {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between
	}
	.xs-justify-around {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around
	}
	.xs-content-start {
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start
	}
	.xs-content-end {
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end
	}
	.xs-content-center {
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center
	}
	.xs-content-between {
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between
	}
	.xs-content-around {
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around
	}
	.xs-content-stretch {
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch
	}
	.xs-order-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0
	}
	.xs-order-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.xs-order-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2
	}
	.xs-order-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3
	}
	.xs-order-last {
		-webkit-box-ordinal-group: 100000;
		-webkit-order: 99999;
		-ms-flex-order: 99999;
		order: 99999
	}
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
	.sm-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}
	.sm-flex-inline {
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex
	}
	.sm-flex-column {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.sm-flex-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.sm-flex-auto {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		min-width: 0;
		min-height: 0
	}
	.sm-flex-none {
		-webkit-box-flex: 0;
		-webkit-flex: none;
		-ms-flex: none;
		flex: none
	}
	.sm-flex-reverse {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.sm-flex-column-reverse {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.sm-items-start {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		-ms-grid-row-align: flex-start;
		align-items: flex-start
	}
	.sm-items-end {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		-ms-grid-row-align: flex-end;
		align-items: flex-end
	}
	.sm-items-center {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		-ms-grid-row-align: center;
		align-items: center
	}
	.sm-items-baseline {
		-webkit-box-align: baseline;
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		-ms-grid-row-align: baseline;
		align-items: baseline
	}
	.sm-items-stretch {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		-ms-grid-row-align: stretch;
		align-items: stretch
	}
	.sm-self-start {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.sm-self-end {
		-webkit-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.sm-self-center {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.sm-self-baseline {
		-webkit-align-self: baseline;
		-ms-flex-item-align: baseline;
		align-self: baseline
	}
	.sm-self-stretch {
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch
	}
	.sm-justify-start {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.sm-justify-end {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.sm-justify-center {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center
	}
	.sm-justify-between {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between
	}
	.sm-justify-around {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around
	}
	.sm-content-start {
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start
	}
	.sm-content-end {
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end
	}
	.sm-content-center {
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center
	}
	.sm-content-between {
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between
	}
	.sm-content-around {
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around
	}
	.sm-content-stretch {
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch
	}
	.sm-order-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0
	}
	.sm-order-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.sm-order-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2
	}
	.sm-order-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3
	}
	.sm-order-last {
		-webkit-box-ordinal-group: 100000;
		-webkit-order: 99999;
		-ms-flex-order: 99999;
		order: 99999
	}
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
	.md-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}
	.md-flex-inline {
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex
	}
	.md-flex-column {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.md-flex-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.md-flex-auto {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		min-width: 0;
		min-height: 0
	}
	.md-flex-none {
		-webkit-box-flex: 0;
		-webkit-flex: none;
		-ms-flex: none;
		flex: none
	}
	.md-flex-reverse {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.md-flex-column-reverse {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.md-items-start {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		-ms-grid-row-align: flex-start;
		align-items: flex-start
	}
	.md-items-end {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		-ms-grid-row-align: flex-end;
		align-items: flex-end
	}
	.md-items-center {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		-ms-grid-row-align: center;
		align-items: center
	}
	.md-items-baseline {
		-webkit-box-align: baseline;
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		-ms-grid-row-align: baseline;
		align-items: baseline
	}
	.md-items-stretch {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		-ms-grid-row-align: stretch;
		align-items: stretch
	}
	.md-self-start {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.md-self-end {
		-webkit-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.md-self-center {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.md-self-baseline {
		-webkit-align-self: baseline;
		-ms-flex-item-align: baseline;
		align-self: baseline
	}
	.md-self-stretch {
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch
	}
	.md-justify-start {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.md-justify-end {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.md-justify-center {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center
	}
	.md-justify-between {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between
	}
	.md-justify-around {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around
	}
	.md-content-start {
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start
	}
	.md-content-end {
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end
	}
	.md-content-center {
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center
	}
	.md-content-between {
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between
	}
	.md-content-around {
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around
	}
	.md-content-stretch {
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch
	}
	.md-order-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0
	}
	.md-order-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.md-order-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2
	}
	.md-order-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3
	}
	.md-order-last {
		-webkit-box-ordinal-group: 100000;
		-webkit-order: 99999;
		-ms-flex-order: 99999;
		order: 99999
	}
}
@media (min-width: 72rem) {
	.lg-flex {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}
	.lg-flex-inline {
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex
	}
	.lg-flex-column {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.lg-flex-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.lg-flex-auto {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		min-width: 0;
		min-height: 0
	}
	.lg-flex-none {
		-webkit-box-flex: 0;
		-webkit-flex: none;
		-ms-flex: none;
		flex: none
	}
	.lg-flex-reverse {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.lg-flex-column-reverse {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.lg-items-start {
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		-ms-grid-row-align: flex-start;
		align-items: flex-start
	}
	.lg-items-end {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		-ms-grid-row-align: flex-end;
		align-items: flex-end
	}
	.lg-items-center {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		-ms-grid-row-align: center;
		align-items: center
	}
	.lg-items-baseline {
		-webkit-box-align: baseline;
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		-ms-grid-row-align: baseline;
		align-items: baseline
	}
	.lg-items-stretch {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		-ms-grid-row-align: stretch;
		align-items: stretch
	}
	.lg-self-start {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start
	}
	.lg-self-end {
		-webkit-align-self: flex-end;
		-ms-flex-item-align: end;
		align-self: flex-end
	}
	.lg-self-center {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center
	}
	.lg-self-baseline {
		-webkit-align-self: baseline;
		-ms-flex-item-align: baseline;
		align-self: baseline
	}
	.lg-self-stretch {
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch
	}
	.lg-justify-start {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start
	}
	.lg-justify-end {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}
	.lg-justify-center {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center
	}
	.lg-justify-between {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between
	}
	.lg-justify-around {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around
	}
	.lg-content-start {
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start
	}
	.lg-content-end {
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end
	}
	.lg-content-center {
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center
	}
	.lg-content-between {
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between
	}
	.lg-content-around {
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around
	}
	.lg-content-stretch {
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch
	}
	.lg-order-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0
	}
	.lg-order-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1
	}
	.lg-order-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2
	}
	.lg-order-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3
	}
	.lg-order-last {
		-webkit-box-ordinal-group: 100000;
		-webkit-order: 99999;
		-ms-flex-order: 99999;
		order: 99999
	}
}
.understroke {
	padding-bottom: .14rem;
	border-bottom: 2px solid hsla(0, 0%, 0%, 0.1)
}
.understroke.thick {
	border-width: 3px
}
.understroke.link:hover,
.understroke.link:focus,
.understroke.active,
.hover-container:hover .understroke {
	border-color: currentColor
}
.understroke.disabled,
.understroke.disabled:hover {
	border-color: transparent
}
a.link {
	cursor: pointer
}
.link:hover {
	color: hsl(0, 0%, 20%)
}
.link-quiet:hover,
.link-quiet-focusable:hover,
.link-quiet-focusable:focus,
.hover-container:hover .link-quiet {
	opacity: .85
}
.link-quieter:hover,
.link-quieter-focusable:hover,
.link-quieter-focusable:focus,
.hover-container:hover .link-quieter {
	opacity: .7
}
.link-unquiet:hover,
.link-unquiet-focusable:hover,
.link-unquiet-focusable:focus,
.hover-container:hover .link-unquiet {
	opacity: 1
}
.print-color-exact {
	-webkit-print-color-adjust: exact
}
.white,
.white-focus:focus,
.white-hover:hover {
	background-color: hsl(0, 0%, 100%)
}
.dark,
.dark-focus:focus,
.dark-hover:hover {
	background-color: hsl(0, 0%, 20%)
}
.gray,
.gray-focus:focus,
.gray-hover:hover {
	background-color: #666
}
.lighten1,
.lighten1-focus:focus,
.lighten1-hover:hover {
	background-color: hsla(0, 0%, 100%, 0.05)
}
.lighten2,
.lighten2-focus:focus,
.lighten2-hover:hover {
	background-color: hsla(0, 0%, 100%, 0.1)
}
.lighten3,
.lighten3-focus:focus,
.lighten3-hover:hover {
	background-color: hsla(0, 0%, 100%, 0.25)
}
.lighten4,
.lighten4-focus:focus,
.lighten4-hover:hover {
	background-color: hsla(0, 0%, 100%, 0.5)
}
.darken1,
.darken1-focus:focus,
.darken1-hover:hover,
.darken1-active:active,
.hover-container:hover .parent-hover-darken1 {
	background-color: hsla(0, 0%, 0%, 0.05)
}
.darken2,
.darken2-focus:focus,
.darken2-hover:hover,
.darken2-active:active {
	background-color: hsla(0, 0%, 0%, 0.1)
}
.darken3,
.darken3-focus:focus,
.darken3-hover:hover,
.darken3-active:active {
	background-color: hsla(0, 0%, 0%, 0.25)
}
.darken4,
.darken4-focus:focus,
.darken4-hover:hover,
.darken4-active:active {
	background-color: hsla(0, 0%, 0%, 0.5)
}
.stroked {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.05)
}
.stroked1 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.stroked2 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.stroked3 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.5)
}
.stroked-inset {
	box-shadow: inset 0 0 0 2px hsla(0, 0%, 0%, 0.05)
}
.stroked1-inset {
	box-shadow: inset 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.stroked2-inset {
	box-shadow: inset 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.text-white.stroked,
.text-white .stroked {
	box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.05)
}
.text-white.stroked1,
.text-white .stroked1 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.1)
}
.text-white.stroked2,
.text-white .stroked2 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 100%, 0.25)
}
.stroked-thick {
	box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.05)
}
.stroked1-thick {
	box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1)
}
.stroked2-thick {
	box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.25)
}
.clickable-stroked {
	box-shadow: 0 0 0 2px transparent
}
.clickable-stroked:hover,
.clickable-stroked:focus,
.clickable-stroked:active,
.clickable-stroked.stroked1 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.clickable-stroked-inset {
	box-shadow: inset 0 0 0 2px transparent
}
.clickable-stroked-inset:hover,
.clickable-stroked-inset:focus,
.clickable-stroked-inset:active {
	box-shadow: inset 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.clickable-stroked1 {
	box-shadow: 0 0 0 2px transparent
}
.clickable-stroked1:hover,
.clickable-stroked1:focus,
.clickable-stroked1:active,
.clickable-stroked1.stroked2 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.clickable-stroked1-inset {
	box-shadow: inset 0 0 0 2px transparent
}
.clickable-stroked1-inset:hover,
.clickable-stroked1-inset:focus,
.clickable-stroked1-inset:active {
	box-shadow: inset 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.clickable-stroked-thick {
	box-shadow: 0 0 0 5px transparent
}
.clickable-stroked-thick:hover,
.clickable-stroked-thick:focus,
.clickable-stroked-thick:active {
	box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1)
}
.clickable-stroked1-thick {
	box-shadow: 0 0 0 5px transparent
}
.clickable-stroked1-thick:hover,
.clickable-stroked1-thick:focus,
.clickable-stroked1-thick:active {
	box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.25)
}
.clickable-stroked1-light-thick {
	box-shadow: 0 0 0 5px transparent
}
.clickable-stroked1-light-thick:hover,
.clickable-stroked1-light-thick:focus,
.clickable-stroked1-light-thick:active {
	box-shadow: 0 0 0 5px hsla(0, 0%, 100%, 0.5)
}
.clickable-stroked-thick-inset {
	box-shadow: inset 0 0 0 5px transparent
}
.clickable-stroked-thick-inset:hover,
.clickable-stroked-thick-inset:focus,
.clickable-stroked-thick-inset:active {
	box-shadow: inset 0 0 0 5px hsla(0, 0%, 0%, 0.1)
}
.clickable-stroked2 {
	box-shadow: 0 0 0 2px transparent
}
.clickable-stroked2:hover,
.clickable-stroked2:focus,
.clickable-stroked2:active,
.clickable-stroked2.stroked3 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.5)
}
.card-hover-darken1 {
	box-shadow: 0 0 0 5px transparent
}
.card-hover-darken1:hover,
.card-hover-darken1:focus,
.card-hover-darken1:active {
	box-shadow: 0 0 0 10px hsla(0, 0%, 0%, 0.05)
}
.card-hover-darken4 {
	box-shadow: 0 0 0 5px transparent
}
.card-hover-darken4:hover,
.card-hover-darken4:focus,
.card-hover-darken4:active {
	box-shadow: 0 0 0 10px hsla(0, 0%, 0%, 0.5)
}
.card-hover-white {
	box-shadow: 0 0 0 5px transparent
}
.card-hover-white:hover,
.card-hover-white:focus,
.card-hover-white:active {
	box-shadow: 0 0 0 10px hsl(0, 0%, 100%)
}
.button-stroked {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.05)
}
.button-stroked:hover,
.button-stroked:focus,
.button-stroked:active,
.focus-container:focus .button-stroked {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.button-stroked.stroked2 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.button-stroked1 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.1)
}
.button-stroked1:hover,
.button-stroked1:focus,
.button-stroked1:active,
.focus-container:focus .button-stroked1 {
	box-shadow: 0 0 0 2px hsla(0, 0%, 0%, 0.25)
}
.border-lighten1 {
	border-color: hsla(0, 0%, 100%, 0.05)
}
.border-lighten2 {
	border-color: hsla(0, 0%, 100%, 0.1)
}
.border-lighten3 {
	border-color: hsla(0, 0%, 100%, 0.25)
}
.border-darken1,
.border-darken1-hover:hover {
	border-color: hsla(0, 0%, 0%, 0.05)
}
.border-darken2,
.border-darken2-hover:hover,
.focus-container:focus .parent-focus-border-darken2 {
	border-color: hsla(0, 0%, 0%, 0.1)
}
.border-darken3,
.border-darken3-hover:hover {
	border-color: hsla(0, 0%, 0%, 0.25)
}
.text-white,
a.text-white,
.shopperLand a.text-white,
.text-white a,
.shopperLand .text-white a {
	color: hsl(0, 0%, 100%);
	fill: hsl(0, 0%, 100%)
}
.text-white.link:hover,
a.text-white.link:hover,
.shopperLand a.text-white.link:hover,
.text-white a.link:hover,
.shopperLand .text-white a.link:hover {
	color: hsla(0, 0%, 100%, 0.8)
}
.text-white.understroke,
a.text-white.understroke,
.shopperLand a.text-white.understroke,
.text-white a.understroke,
.shopperLand .text-white a.understroke {
	border-color: hsla(0, 0%, 100%, 0.25)
}
.text-white.understroke.link:hover,
a.text-white.understroke.link:hover,
.shopperLand a.text-white.understroke.link:hover,
.text-white a.understroke.link:hover,
.shopperLand .text-white a.understroke.link:hover,
.text-white.understroke.link.active,
a.text-white.understroke.link.active,
.shopperLand a.text-white.understroke.link.active,
.text-white a.understroke.link.active,
.shopperLand .text-white a.understroke.link.active {
	border-color: hsla(0, 0%, 100%, 0.8)
}
.text-dark,
a.text-dark,
.text-dark a {
	color: hsl(0, 0%, 20%);
	fill: hsl(0, 0%, 20%)
}
.text-dark.link:hover,
a.text-dark.link:hover,
.text-dark a.link:hover {
	color: hsla(0, 0%, 0%, 0.8)
}
.text-dark.understroke,
a.text-dark.understroke,
.text-dark a.understroke {
	border-color: hsla(0, 0%, 0%, 0.25)
}
.text-dark.understroke.link:hover,
a.text-dark.understroke.link:hover,
.text-dark a.understroke.link:hover,
.text-dark.understroke.link.active,
a.text-dark.understroke.link.active,
.text-dark a.understroke.link.active {
	border-color: hsla(0, 0%, 0%, 0.8)
}
.universe-dark {
	background-color: #292A3E
}
.foreign-key-blue {
	background-color: #e9eef9
}
.text-universe-dark,
a.text-universe-dark,
.text-universe-dark-focus:focus,
.focus-container:focus .text-universe-dark-focus,
.shopperLand a.text-universe-dark,
.text-universe-dark a,
.shopperLand .text-universe-dark a {
	color: #292A3E;
	fill: #292A3E
}
.text-universe-dark.link:hover,
a.text-universe-dark.link:hover,
.text-universe-dark-focus:focus.link:hover,
.focus-container:focus .text-universe-dark-focus.link:hover,
.shopperLand a.text-universe-dark.link:hover,
.text-universe-dark a.link:hover,
.shopperLand .text-universe-dark a.link:hover {
	color: #000001;
	fill: #000001
}
.text-universe-dark.understroke,
a.text-universe-dark.understroke,
.text-universe-dark-focus:focus.understroke,
.focus-container:focus .text-universe-dark-focus.understroke,
.shopperLand a.text-universe-dark.understroke,
.text-universe-dark a.understroke,
.shopperLand .text-universe-dark a.understroke {
	border-color: #292A3E
}
.text-universe-dark.understroke.link:hover,
a.text-universe-dark.understroke.link:hover,
.text-universe-dark-focus:focus.understroke.link:hover,
.focus-container:focus .text-universe-dark-focus.understroke.link:hover,
.shopperLand a.text-universe-dark.understroke.link:hover,
.text-universe-dark a.understroke.link:hover,
.shopperLand .text-universe-dark a.understroke.link:hover,
.text-universe-dark.understroke.link.active,
a.text-universe-dark.understroke.link.active,
.text-universe-dark-focus:focus.understroke.link.active,
.focus-container:focus .text-universe-dark-focus.understroke.link.active,
.shopperLand a.text-universe-dark.understroke.link.active,
.text-universe-dark a.understroke.link.active,
.shopperLand .text-universe-dark a.understroke.link.active {
	border-color: #000001
}
.yellow,
.yellowBright,
.yellow-focus:focus {
	background-color: #fcb400
}
.yellow-base,
.yellowBase,
.yellow-base:focus,
.hover-container:hover .parent-hover-yellow-base {
	background-color: #e08d00
}
.yellow-dark1,
.yellowDark1,
.yellowDark1-hover:hover,
.yellowDark1-focus:focus {
	background-color: #b87503
}
.yellow-light1,
.yellowLight1,
.yellowLight1-hover:hover,
.yellowLight1-focus:focus {
	background-color: #ffd66e
}
.yellow-light2,
.yellow-light,
.yellowLight2,
.yellowLight,
.yellowLight2-hover:hover,
.yellowLight-hover:hover,
.yellowLight2-focus:focus,
.yellowLight-focus:focus {
	background-color: #ffeab6
}
.yellow-border,
.border-yellow {
	border-color: #fcb400
}
.border-yellow-base {
	border-color: #e08d00
}
.border-yellow-light1 {
	border-color: #ffd66e
}
.border-yellow-light2 {
	border-color: #ffeab6
}
.border-yellow-focus:focus,
.focus-container:focus .parent-focus-border-yellow {
	border-color: #fcb400
}
.stroked-yellow-light1 {
	box-shadow: 0 0 0 2px #ffd66e
}
.stroked-yellow-light2 {
	box-shadow: 0 0 0 2px #ffeab6
}
.stroked-yellow {
	box-shadow: 0 0 0 2px #fcb400
}
.stroked-yellow-inset {
	box-shadow: inset 0 0 0 2px #fcb400
}
.stroked-yellow-light2-inset {
	box-shadow: inset 0 0 0 2px #ffeab6
}
.text-yellow,
a.text-yellow,
.text-yellow-focus:focus,
.focus-container:focus .text-yellow-focus,
.shopperLand a.text-yellow,
.text-yellow a,
.shopperLand .text-yellow a {
	color: #fcb400;
	fill: #fcb400
}
.text-yellow.link:hover,
a.text-yellow.link:hover,
.text-yellow-focus:focus.link:hover,
.focus-container:focus .text-yellow-focus.link:hover,
.shopperLand a.text-yellow.link:hover,
.text-yellow a.link:hover,
.shopperLand .text-yellow a.link:hover {
	color: #966b00;
	fill: #966b00
}
.text-yellow.understroke,
a.text-yellow.understroke,
.text-yellow-focus:focus.understroke,
.focus-container:focus .text-yellow-focus.understroke,
.shopperLand a.text-yellow.understroke,
.text-yellow a.understroke,
.shopperLand .text-yellow a.understroke {
	border-color: #fcb400
}
.text-yellow.understroke.link:hover,
a.text-yellow.understroke.link:hover,
.text-yellow-focus:focus.understroke.link:hover,
.focus-container:focus .text-yellow-focus.understroke.link:hover,
.shopperLand a.text-yellow.understroke.link:hover,
.text-yellow a.understroke.link:hover,
.shopperLand .text-yellow a.understroke.link:hover,
.text-yellow.understroke.link.active,
a.text-yellow.understroke.link.active,
.text-yellow-focus:focus.understroke.link.active,
.focus-container:focus .text-yellow-focus.understroke.link.active,
.shopperLand a.text-yellow.understroke.link.active,
.text-yellow a.understroke.link.active,
.shopperLand .text-yellow a.understroke.link.active {
	border-color: #966b00
}
.text-yellow-dark1-hover:hover,
a.text-yellow-dark1-hover:hover {
	color: #b87503;
	fill: #b87503
}
[data-color=yellow].coloredBackground {
	background-color: #e08d00 !important
}
[data-color=yellow].brightColoredBackground {
	background-color: #fcb400
}
[data-color=yellow].lightColoredBackground {
	background-color: #ffeab6
}
[data-color=yellow].light1ColoredBackground {
	background-color: #ffd66e
}
[data-color=yellow].darkColoredBackground {
	background-color: #b87503
}
[data-color=yellow].coloredBorder {
	border-color: #e08d00
}
[data-color=yellow].lightColoredBorder {
	border-color: #ffeab6
}
[data-color=yellow].coloredText {
	color: #e08d00;
	fill: #e08d00
}
[data-color=yellow].darkColoredText {
	color: #b87503;
	fill: #b87503
}
[data-color=yellow] .parentColoredBackground {
	background-color: #e08d00 !important
}
[data-color=yellow] .parentBrightColoredBackground {
	background-color: #fcb400
}
[data-color=yellow] .parentLightColoredBackground {
	background-color: #ffeab6
}
[data-color=yellow] .parentDarkColoredBackground {
	background-color: #b87503
}
[data-color=yellow] .parentColoredBorder {
	border-color: #b87503 !important
}
[data-color=yellow] .parentColoredText {
	color: #e08d00
}
[data-color=yellow] .parentLightColoredText {
	color: #ffeab6
}
[data-color=yellow] .parentColoredFill {
	fill: #e08d00
}
[data-color=yellow] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(224, 141, 0, 0), #e08d00)
}
[data-color=yellow] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(224, 141, 0, 0), #e08d00)
}
[data-color=yellow] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(224, 141, 0, 0), #ffa814)
}
[data-color=yellow] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(224, 141, 0, 0), #ffa814)
}
[data-color=yellow] .parentComplementaryColoredBackground {
	background-color: #1283da !important
}
[data-color="yellowLight"].coloredBackground {
	background-color: #ffeab6 !important
}
[data-color="yellowLight"].brightColoredBackground {
	background-color: #ffeab6
}
[data-color="yellowLight"].lightColoredBackground {
	background-color: #ffeab6
}
[data-color="yellowLight"].light1ColoredBackground {
	background-color: #ffeab6
}
[data-color="yellowLight"].coloredBorder {
	border-color: #ffeab6
}
[data-color="yellowLight"].lightColoredBorder {
	border-color: #ffeab6
}
[data-color="yellowLight"].coloredText {
	color: #ffeab6;
	fill: #ffeab6
}
[data-color="yellowLight"] .parentColoredBackground {
	background-color: #ffeab6 !important
}
[data-color="yellowLight"] .parentBrightColoredBackground {
	background-color: #ffeab6
}
[data-color="yellowLight"] .parentLightColoredBackground {
	background-color: #ffeab6
}
[data-color="yellowLight"] .parentColoredBorder {
	border-color: #ffeab6 !important
}
[data-color="yellowLight"] .parentColoredText {
	color: #ffeab6
}
[data-color="yellowLight"] .parentLightColoredText {
	color: #ffeab6
}
[data-color="yellowLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(255, 234, 182, 0), #ffeab6)
}
[data-color="yellowLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(255, 234, 182, 0), #ffeab6)
}
[data-color="yellowLight"] .parentComplementaryColoredBackground {
	background-color: #1283da !important
}
[data-color="yellowLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.orange,
.orangeBright,
.orange-focus:focus {
	background-color: #ff6f2c
}
.orange-base,
.orangeBase,
.orange-base:focus,
.hover-container:hover .parent-hover-orange-base {
	background-color: #f7653b
}
.orange-dark1,
.orangeDark1,
.orangeDark1-hover:hover,
.orangeDark1-focus:focus {
	background-color: #d74d26
}
.orange-light1,
.orangeLight1,
.orangeLight1-hover:hover,
.orangeLight1-focus:focus {
	background-color: #ffa981
}
.orange-light2,
.orange-light,
.orangeLight2,
.orangeLight,
.orangeLight2-hover:hover,
.orangeLight-hover:hover,
.orangeLight2-focus:focus,
.orangeLight-focus:focus {
	background-color: #fee2d5
}
.orange-border,
.border-orange {
	border-color: #ff6f2c
}
.border-orange-base {
	border-color: #f7653b
}
.border-orange-light1 {
	border-color: #ffa981
}
.border-orange-light2 {
	border-color: #fee2d5
}
.border-orange-focus:focus,
.focus-container:focus .parent-focus-border-orange {
	border-color: #ff6f2c
}
.stroked-orange-light1 {
	box-shadow: 0 0 0 2px #ffa981
}
.stroked-orange-light2 {
	box-shadow: 0 0 0 2px #fee2d5
}
.stroked-orange {
	box-shadow: 0 0 0 2px #ff6f2c
}
.stroked-orange-inset {
	box-shadow: inset 0 0 0 2px #ff6f2c
}
.stroked-orange-light2-inset {
	box-shadow: inset 0 0 0 2px #fee2d5
}
.text-orange,
a.text-orange,
.text-orange-focus:focus,
.focus-container:focus .text-orange-focus,
.shopperLand a.text-orange,
.text-orange a,
.shopperLand .text-orange a {
	color: #ff6f2c;
	fill: #ff6f2c
}
.text-orange.link:hover,
a.text-orange.link:hover,
.text-orange-focus:focus.link:hover,
.focus-container:focus .text-orange-focus.link:hover,
.shopperLand a.text-orange.link:hover,
.text-orange a.link:hover,
.shopperLand .text-orange a.link:hover {
	color: #c53f00;
	fill: #c53f00
}
.text-orange.understroke,
a.text-orange.understroke,
.text-orange-focus:focus.understroke,
.focus-container:focus .text-orange-focus.understroke,
.shopperLand a.text-orange.understroke,
.text-orange a.understroke,
.shopperLand .text-orange a.understroke {
	border-color: #ff6f2c
}
.text-orange.understroke.link:hover,
a.text-orange.understroke.link:hover,
.text-orange-focus:focus.understroke.link:hover,
.focus-container:focus .text-orange-focus.understroke.link:hover,
.shopperLand a.text-orange.understroke.link:hover,
.text-orange a.understroke.link:hover,
.shopperLand .text-orange a.understroke.link:hover,
.text-orange.understroke.link.active,
a.text-orange.understroke.link.active,
.text-orange-focus:focus.understroke.link.active,
.focus-container:focus .text-orange-focus.understroke.link.active,
.shopperLand a.text-orange.understroke.link.active,
.text-orange a.understroke.link.active,
.shopperLand .text-orange a.understroke.link.active {
	border-color: #c53f00
}
.text-orange-dark1-hover:hover,
a.text-orange-dark1-hover:hover {
	color: #d74d26;
	fill: #d74d26
}
[data-color=orange].coloredBackground {
	background-color: #f7653b !important
}
[data-color=orange].brightColoredBackground {
	background-color: #ff6f2c
}
[data-color=orange].lightColoredBackground {
	background-color: #fee2d5
}
[data-color=orange].light1ColoredBackground {
	background-color: #ffa981
}
[data-color=orange].darkColoredBackground {
	background-color: #d74d26
}
[data-color=orange].coloredBorder {
	border-color: #f7653b
}
[data-color=orange].lightColoredBorder {
	border-color: #fee2d5
}
[data-color=orange].coloredText {
	color: #f7653b;
	fill: #f7653b
}
[data-color=orange].darkColoredText {
	color: #d74d26;
	fill: #d74d26
}
[data-color=orange] .parentColoredBackground {
	background-color: #f7653b !important
}
[data-color=orange] .parentBrightColoredBackground {
	background-color: #ff6f2c
}
[data-color=orange] .parentLightColoredBackground {
	background-color: #fee2d5
}
[data-color=orange] .parentDarkColoredBackground {
	background-color: #d74d26
}
[data-color=orange] .parentColoredBorder {
	border-color: #d74d26 !important
}
[data-color=orange] .parentColoredText {
	color: #f7653b
}
[data-color=orange] .parentLightColoredText {
	color: #fee2d5
}
[data-color=orange] .parentColoredFill {
	fill: #f7653b
}
[data-color=orange] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(247, 101, 59, 0), #f7653b)
}
[data-color=orange] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(247, 101, 59, 0), #f7653b)
}
[data-color=orange] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(247, 101, 59, 0), #f98b6c)
}
[data-color=orange] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(247, 101, 59, 0), #f98b6c)
}
[data-color=orange] .parentComplementaryColoredBackground {
	background-color: #1283da !important
}
[data-color="orangeLight"].coloredBackground {
	background-color: #fee2d5 !important
}
[data-color="orangeLight"].brightColoredBackground {
	background-color: #fee2d5
}
[data-color="orangeLight"].lightColoredBackground {
	background-color: #fee2d5
}
[data-color="orangeLight"].light1ColoredBackground {
	background-color: #fee2d5
}
[data-color="orangeLight"].coloredBorder {
	border-color: #fee2d5
}
[data-color="orangeLight"].lightColoredBorder {
	border-color: #fee2d5
}
[data-color="orangeLight"].coloredText {
	color: #fee2d5;
	fill: #fee2d5
}
[data-color="orangeLight"] .parentColoredBackground {
	background-color: #fee2d5 !important
}
[data-color="orangeLight"] .parentBrightColoredBackground {
	background-color: #fee2d5
}
[data-color="orangeLight"] .parentLightColoredBackground {
	background-color: #fee2d5
}
[data-color="orangeLight"] .parentColoredBorder {
	border-color: #fee2d5 !important
}
[data-color="orangeLight"] .parentColoredText {
	color: #fee2d5
}
[data-color="orangeLight"] .parentLightColoredText {
	color: #fee2d5
}
[data-color="orangeLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(254, 226, 213, 0), #fee2d5)
}
[data-color="orangeLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(254, 226, 213, 0), #fee2d5)
}
[data-color="orangeLight"] .parentComplementaryColoredBackground {
	background-color: #1283da !important
}
[data-color="orangeLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.red,
.redBright,
.red-focus:focus {
	background-color: #f82b60
}
.red-base,
.redBase,
.red-base:focus,
.hover-container:hover .parent-hover-red-base {
	background-color: #e52e4d
}
.red-dark1,
.redDark1,
.redDark1-hover:hover,
.redDark1-focus:focus {
	background-color: #ba1e45
}
.red-light1,
.redLight1,
.redLight1-hover:hover,
.redLight1-focus:focus {
	background-color: #ff9eb7
}
.red-light2,
.red-light,
.redLight2,
.redLight,
.redLight2-hover:hover,
.redLight-hover:hover,
.redLight2-focus:focus,
.redLight-focus:focus {
	background-color: #ffdce5
}
.red-border,
.border-red {
	border-color: #f82b60
}
.border-red-base {
	border-color: #e52e4d
}
.border-red-light1 {
	border-color: #ff9eb7
}
.border-red-light2 {
	border-color: #ffdce5
}
.border-red-focus:focus,
.focus-container:focus .parent-focus-border-red {
	border-color: #f82b60
}
.stroked-red-light1 {
	box-shadow: 0 0 0 2px #ff9eb7
}
.stroked-red-light2 {
	box-shadow: 0 0 0 2px #ffdce5
}
.stroked-red {
	box-shadow: 0 0 0 2px #f82b60
}
.stroked-red-inset {
	box-shadow: inset 0 0 0 2px #f82b60
}
.stroked-red-light2-inset {
	box-shadow: inset 0 0 0 2px #ffdce5
}
.text-red,
a.text-red,
.text-red-focus:focus,
.focus-container:focus .text-red-focus,
.shopperLand a.text-red,
.text-red a,
.shopperLand .text-red a {
	color: #f82b60;
	fill: #f82b60
}
.text-red.link:hover,
a.text-red.link:hover,
.text-red-focus:focus.link:hover,
.focus-container:focus .text-red-focus.link:hover,
.shopperLand a.text-red.link:hover,
.text-red a.link:hover,
.shopperLand .text-red a.link:hover {
	color: #b70634;
	fill: #b70634
}
.text-red.understroke,
a.text-red.understroke,
.text-red-focus:focus.understroke,
.focus-container:focus .text-red-focus.understroke,
.shopperLand a.text-red.understroke,
.text-red a.understroke,
.shopperLand .text-red a.understroke {
	border-color: #f82b60
}
.text-red.understroke.link:hover,
a.text-red.understroke.link:hover,
.text-red-focus:focus.understroke.link:hover,
.focus-container:focus .text-red-focus.understroke.link:hover,
.shopperLand a.text-red.understroke.link:hover,
.text-red a.understroke.link:hover,
.shopperLand .text-red a.understroke.link:hover,
.text-red.understroke.link.active,
a.text-red.understroke.link.active,
.text-red-focus:focus.understroke.link.active,
.focus-container:focus .text-red-focus.understroke.link.active,
.shopperLand a.text-red.understroke.link.active,
.text-red a.understroke.link.active,
.shopperLand .text-red a.understroke.link.active {
	border-color: #b70634
}
.text-red-dark1-hover:hover,
a.text-red-dark1-hover:hover {
	color: #ba1e45;
	fill: #ba1e45
}
[data-color=red].coloredBackground {
	background-color: #e52e4d !important
}
[data-color=red].brightColoredBackground {
	background-color: #f82b60
}
[data-color=red].lightColoredBackground {
	background-color: #ffdce5
}
[data-color=red].light1ColoredBackground {
	background-color: #ff9eb7
}
[data-color=red].darkColoredBackground {
	background-color: #ba1e45
}
[data-color=red].coloredBorder {
	border-color: #e52e4d
}
[data-color=red].lightColoredBorder {
	border-color: #ffdce5
}
[data-color=red].coloredText {
	color: #e52e4d;
	fill: #e52e4d
}
[data-color=red].darkColoredText {
	color: #ba1e45;
	fill: #ba1e45
}
[data-color=red] .parentColoredBackground {
	background-color: #e52e4d !important
}
[data-color=red] .parentBrightColoredBackground {
	background-color: #f82b60
}
[data-color=red] .parentLightColoredBackground {
	background-color: #ffdce5
}
[data-color=red] .parentDarkColoredBackground {
	background-color: #ba1e45
}
[data-color=red] .parentColoredBorder {
	border-color: #ba1e45 !important
}
[data-color=red] .parentColoredText {
	color: #e52e4d
}
[data-color=red] .parentLightColoredText {
	color: #ffdce5
}
[data-color=red] .parentColoredFill {
	fill: #e52e4d
}
[data-color=red] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(229, 46, 77, 0), #e52e4d)
}
[data-color=red] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(229, 46, 77, 0), #e52e4d)
}
[data-color=red] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(229, 46, 77, 0), #eb5b74)
}
[data-color=red] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(229, 46, 77, 0), #eb5b74)
}
[data-color=red] .parentComplementaryColoredBackground {
	background-color: #01a9db !important
}
[data-color="redLight"].coloredBackground {
	background-color: #ffdce5 !important
}
[data-color="redLight"].brightColoredBackground {
	background-color: #ffdce5
}
[data-color="redLight"].lightColoredBackground {
	background-color: #ffdce5
}
[data-color="redLight"].light1ColoredBackground {
	background-color: #ffdce5
}
[data-color="redLight"].coloredBorder {
	border-color: #ffdce5
}
[data-color="redLight"].lightColoredBorder {
	border-color: #ffdce5
}
[data-color="redLight"].coloredText {
	color: #ffdce5;
	fill: #ffdce5
}
[data-color="redLight"] .parentColoredBackground {
	background-color: #ffdce5 !important
}
[data-color="redLight"] .parentBrightColoredBackground {
	background-color: #ffdce5
}
[data-color="redLight"] .parentLightColoredBackground {
	background-color: #ffdce5
}
[data-color="redLight"] .parentColoredBorder {
	border-color: #ffdce5 !important
}
[data-color="redLight"] .parentColoredText {
	color: #ffdce5
}
[data-color="redLight"] .parentLightColoredText {
	color: #ffdce5
}
[data-color="redLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(255, 220, 229, 0), #ffdce5)
}
[data-color="redLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(255, 220, 229, 0), #ffdce5)
}
[data-color="redLight"] .parentComplementaryColoredBackground {
	background-color: #01a9db !important
}
[data-color="redLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.pink,
.pinkBright,
.pink-focus:focus {
	background-color: #ff08c2
}
.pink-base,
.pinkBase,
.pink-base:focus,
.hover-container:hover .parent-hover-pink-base {
	background-color: #e929ba
}
.pink-dark1,
.pinkDark1,
.pinkDark1-hover:hover,
.pinkDark1-focus:focus {
	background-color: #b2158b
}
.pink-light1,
.pinkLight1,
.pinkLight1-hover:hover,
.pinkLight1-focus:focus {
	background-color: #f99de2
}
.pink-light2,
.pink-light,
.pinkLight2,
.pinkLight,
.pinkLight2-hover:hover,
.pinkLight-hover:hover,
.pinkLight2-focus:focus,
.pinkLight-focus:focus {
	background-color: #ffdaf6
}
.pink-border,
.border-pink {
	border-color: #ff08c2
}
.border-pink-base {
	border-color: #e929ba
}
.border-pink-light1 {
	border-color: #f99de2
}
.border-pink-light2 {
	border-color: #ffdaf6
}
.border-pink-focus:focus,
.focus-container:focus .parent-focus-border-pink {
	border-color: #ff08c2
}
.stroked-pink-light1 {
	box-shadow: 0 0 0 2px #f99de2
}
.stroked-pink-light2 {
	box-shadow: 0 0 0 2px #ffdaf6
}
.stroked-pink {
	box-shadow: 0 0 0 2px #ff08c2
}
.stroked-pink-inset {
	box-shadow: inset 0 0 0 2px #ff08c2
}
.stroked-pink-light2-inset {
	box-shadow: inset 0 0 0 2px #ffdaf6
}
.text-pink,
a.text-pink,
.text-pink-focus:focus,
.focus-container:focus .text-pink-focus,
.shopperLand a.text-pink,
.text-pink a,
.shopperLand .text-pink a {
	color: #ff08c2;
	fill: #ff08c2
}
.text-pink.link:hover,
a.text-pink.link:hover,
.text-pink-focus:focus.link:hover,
.focus-container:focus .text-pink-focus.link:hover,
.shopperLand a.text-pink.link:hover,
.text-pink a.link:hover,
.shopperLand .text-pink a.link:hover {
	color: #a10079;
	fill: #a10079
}
.text-pink.understroke,
a.text-pink.understroke,
.text-pink-focus:focus.understroke,
.focus-container:focus .text-pink-focus.understroke,
.shopperLand a.text-pink.understroke,
.text-pink a.understroke,
.shopperLand .text-pink a.understroke {
	border-color: #ff08c2
}
.text-pink.understroke.link:hover,
a.text-pink.understroke.link:hover,
.text-pink-focus:focus.understroke.link:hover,
.focus-container:focus .text-pink-focus.understroke.link:hover,
.shopperLand a.text-pink.understroke.link:hover,
.text-pink a.understroke.link:hover,
.shopperLand .text-pink a.understroke.link:hover,
.text-pink.understroke.link.active,
a.text-pink.understroke.link.active,
.text-pink-focus:focus.understroke.link.active,
.focus-container:focus .text-pink-focus.understroke.link.active,
.shopperLand a.text-pink.understroke.link.active,
.text-pink a.understroke.link.active,
.shopperLand .text-pink a.understroke.link.active {
	border-color: #a10079
}
.text-pink-dark1-hover:hover,
a.text-pink-dark1-hover:hover {
	color: #b2158b;
	fill: #b2158b
}
[data-color=pink].coloredBackground {
	background-color: #e929ba !important
}
[data-color=pink].brightColoredBackground {
	background-color: #ff08c2
}
[data-color=pink].lightColoredBackground {
	background-color: #ffdaf6
}
[data-color=pink].light1ColoredBackground {
	background-color: #f99de2
}
[data-color=pink].darkColoredBackground {
	background-color: #b2158b
}
[data-color=pink].coloredBorder {
	border-color: #e929ba
}
[data-color=pink].lightColoredBorder {
	border-color: #ffdaf6
}
[data-color=pink].coloredText {
	color: #e929ba;
	fill: #e929ba
}
[data-color=pink].darkColoredText {
	color: #b2158b;
	fill: #b2158b
}
[data-color=pink] .parentColoredBackground {
	background-color: #e929ba !important
}
[data-color=pink] .parentBrightColoredBackground {
	background-color: #ff08c2
}
[data-color=pink] .parentLightColoredBackground {
	background-color: #ffdaf6
}
[data-color=pink] .parentDarkColoredBackground {
	background-color: #b2158b
}
[data-color=pink] .parentColoredBorder {
	border-color: #b2158b !important
}
[data-color=pink] .parentColoredText {
	color: #e929ba
}
[data-color=pink] .parentLightColoredText {
	color: #ffdaf6
}
[data-color=pink] .parentColoredFill {
	fill: #e929ba
}
[data-color=pink] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(233, 41, 186, 0), #e929ba)
}
[data-color=pink] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(233, 41, 186, 0), #e929ba)
}
[data-color=pink] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(233, 41, 186, 0), #ee57c9)
}
[data-color=pink] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(233, 41, 186, 0), #ee57c9)
}
[data-color=pink] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="pinkLight"].coloredBackground {
	background-color: #ffdaf6 !important
}
[data-color="pinkLight"].brightColoredBackground {
	background-color: #ffdaf6
}
[data-color="pinkLight"].lightColoredBackground {
	background-color: #ffdaf6
}
[data-color="pinkLight"].light1ColoredBackground {
	background-color: #ffdaf6
}
[data-color="pinkLight"].coloredBorder {
	border-color: #ffdaf6
}
[data-color="pinkLight"].lightColoredBorder {
	border-color: #ffdaf6
}
[data-color="pinkLight"].coloredText {
	color: #ffdaf6;
	fill: #ffdaf6
}
[data-color="pinkLight"] .parentColoredBackground {
	background-color: #ffdaf6 !important
}
[data-color="pinkLight"] .parentBrightColoredBackground {
	background-color: #ffdaf6
}
[data-color="pinkLight"] .parentLightColoredBackground {
	background-color: #ffdaf6
}
[data-color="pinkLight"] .parentColoredBorder {
	border-color: #ffdaf6 !important
}
[data-color="pinkLight"] .parentColoredText {
	color: #ffdaf6
}
[data-color="pinkLight"] .parentLightColoredText {
	color: #ffdaf6
}
[data-color="pinkLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(255, 218, 246, 0), #ffdaf6)
}
[data-color="pinkLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(255, 218, 246, 0), #ffdaf6)
}
[data-color="pinkLight"] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="pinkLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.purple,
.purpleBright,
.purple-focus:focus {
	background-color: #8b46ff
}
.purple-base,
.purpleBase,
.purple-base:focus,
.hover-container:hover .parent-hover-purple-base {
	background-color: #7c39ed
}
.purple-dark1,
.purpleDark1,
.purpleDark1-hover:hover,
.purpleDark1-focus:focus {
	background-color: #6b1cb0
}
.purple-light1,
.purpleLight1,
.purpleLight1-hover:hover,
.purpleLight1-focus:focus {
	background-color: #cdb0ff
}
.purple-light2,
.purple-light,
.purpleLight2,
.purpleLight,
.purpleLight2-hover:hover,
.purpleLight-hover:hover,
.purpleLight2-focus:focus,
.purpleLight-focus:focus {
	background-color: #ede2fe
}
.purple-border,
.border-purple {
	border-color: #8b46ff
}
.border-purple-base {
	border-color: #7c39ed
}
.border-purple-light1 {
	border-color: #cdb0ff
}
.border-purple-light2 {
	border-color: #ede2fe
}
.border-purple-focus:focus,
.focus-container:focus .parent-focus-border-purple {
	border-color: #8b46ff
}
.stroked-purple-light1 {
	box-shadow: 0 0 0 2px #cdb0ff
}
.stroked-purple-light2 {
	box-shadow: 0 0 0 2px #ede2fe
}
.stroked-purple {
	box-shadow: 0 0 0 2px #8b46ff
}
.stroked-purple-inset {
	box-shadow: inset 0 0 0 2px #8b46ff
}
.stroked-purple-light2-inset {
	box-shadow: inset 0 0 0 2px #ede2fe
}
.text-purple,
a.text-purple,
.text-purple-focus:focus,
.focus-container:focus .text-purple-focus,
.shopperLand a.text-purple,
.text-purple a,
.shopperLand .text-purple a {
	color: #8b46ff;
	fill: #8b46ff
}
.text-purple.link:hover,
a.text-purple.link:hover,
.text-purple-focus:focus.link:hover,
.focus-container:focus .text-purple-focus.link:hover,
.shopperLand a.text-purple.link:hover,
.text-purple a.link:hover,
.shopperLand .text-purple a.link:hover {
	color: #5300df;
	fill: #5300df
}
.text-purple.understroke,
a.text-purple.understroke,
.text-purple-focus:focus.understroke,
.focus-container:focus .text-purple-focus.understroke,
.shopperLand a.text-purple.understroke,
.text-purple a.understroke,
.shopperLand .text-purple a.understroke {
	border-color: #8b46ff
}
.text-purple.understroke.link:hover,
a.text-purple.understroke.link:hover,
.text-purple-focus:focus.understroke.link:hover,
.focus-container:focus .text-purple-focus.understroke.link:hover,
.shopperLand a.text-purple.understroke.link:hover,
.text-purple a.understroke.link:hover,
.shopperLand .text-purple a.understroke.link:hover,
.text-purple.understroke.link.active,
a.text-purple.understroke.link.active,
.text-purple-focus:focus.understroke.link.active,
.focus-container:focus .text-purple-focus.understroke.link.active,
.shopperLand a.text-purple.understroke.link.active,
.text-purple a.understroke.link.active,
.shopperLand .text-purple a.understroke.link.active {
	border-color: #5300df
}
.text-purple-dark1-hover:hover,
a.text-purple-dark1-hover:hover {
	color: #6b1cb0;
	fill: #6b1cb0
}
[data-color=purple].coloredBackground {
	background-color: #7c39ed !important
}
[data-color=purple].brightColoredBackground {
	background-color: #8b46ff
}
[data-color=purple].lightColoredBackground {
	background-color: #ede2fe
}
[data-color=purple].light1ColoredBackground {
	background-color: #cdb0ff
}
[data-color=purple].darkColoredBackground {
	background-color: #6b1cb0
}
[data-color=purple].coloredBorder {
	border-color: #7c39ed
}
[data-color=purple].lightColoredBorder {
	border-color: #ede2fe
}
[data-color=purple].coloredText {
	color: #7c39ed;
	fill: #7c39ed
}
[data-color=purple].darkColoredText {
	color: #6b1cb0;
	fill: #6b1cb0
}
[data-color=purple] .parentColoredBackground {
	background-color: #7c39ed !important
}
[data-color=purple] .parentBrightColoredBackground {
	background-color: #8b46ff
}
[data-color=purple] .parentLightColoredBackground {
	background-color: #ede2fe
}
[data-color=purple] .parentDarkColoredBackground {
	background-color: #6b1cb0
}
[data-color=purple] .parentColoredBorder {
	border-color: #6b1cb0 !important
}
[data-color=purple] .parentColoredText {
	color: #7c39ed
}
[data-color=purple] .parentLightColoredText {
	color: #ede2fe
}
[data-color=purple] .parentColoredFill {
	fill: #7c39ed
}
[data-color=purple] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(124, 57, 237, 0), #7c39ed)
}
[data-color=purple] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(124, 57, 237, 0), #7c39ed)
}
[data-color=purple] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(124, 57, 237, 0), #9b68f1)
}
[data-color=purple] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(124, 57, 237, 0), #9b68f1)
}
[data-color=purple] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="purpleLight"].coloredBackground {
	background-color: #ede2fe !important
}
[data-color="purpleLight"].brightColoredBackground {
	background-color: #ede2fe
}
[data-color="purpleLight"].lightColoredBackground {
	background-color: #ede2fe
}
[data-color="purpleLight"].light1ColoredBackground {
	background-color: #ede2fe
}
[data-color="purpleLight"].coloredBorder {
	border-color: #ede2fe
}
[data-color="purpleLight"].lightColoredBorder {
	border-color: #ede2fe
}
[data-color="purpleLight"].coloredText {
	color: #ede2fe;
	fill: #ede2fe
}
[data-color="purpleLight"] .parentColoredBackground {
	background-color: #ede2fe !important
}
[data-color="purpleLight"] .parentBrightColoredBackground {
	background-color: #ede2fe
}
[data-color="purpleLight"] .parentLightColoredBackground {
	background-color: #ede2fe
}
[data-color="purpleLight"] .parentColoredBorder {
	border-color: #ede2fe !important
}
[data-color="purpleLight"] .parentColoredText {
	color: #ede2fe
}
[data-color="purpleLight"] .parentLightColoredText {
	color: #ede2fe
}
[data-color="purpleLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(237, 226, 254, 0), #ede2fe)
}
[data-color="purpleLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(237, 226, 254, 0), #ede2fe)
}
[data-color="purpleLight"] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="purpleLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.blue,
.blueBright,
.blue-focus:focus {
	background-color: #2d7ff9
}
.blue-base,
.blueBase,
.blue-base:focus,
.hover-container:hover .parent-hover-blue-base {
	background-color: #1283da
}
.blue-dark1,
.blueDark1,
.blueDark1-hover:hover,
.blueDark1-focus:focus {
	background-color: #2750ae
}
.blue-light1,
.blueLight1,
.blueLight1-hover:hover,
.blueLight1-focus:focus {
	background-color: #9cc7ff
}
.blue-light2,
.blue-light,
.blueLight2,
.blueLight,
.blueLight2-hover:hover,
.blueLight-hover:hover,
.blueLight2-focus:focus,
.blueLight-focus:focus {
	background-color: #cfdfff
}
.blue-border,
.border-blue {
	border-color: #2d7ff9
}
.border-blue-base {
	border-color: #1283da
}
.border-blue-light1 {
	border-color: #9cc7ff
}
.border-blue-light2 {
	border-color: #cfdfff
}
.border-blue-focus:focus,
.focus-container:focus .parent-focus-border-blue {
	border-color: #2d7ff9
}
.stroked-blue-light1 {
	box-shadow: 0 0 0 2px #9cc7ff
}
.stroked-blue-light2 {
	box-shadow: 0 0 0 2px #cfdfff
}
.stroked-blue {
	box-shadow: 0 0 0 2px #2d7ff9
}
.stroked-blue-inset {
	box-shadow: inset 0 0 0 2px #2d7ff9
}
.stroked-blue-light2-inset {
	box-shadow: inset 0 0 0 2px #cfdfff
}
.text-blue,
a.text-blue,
.text-blue-focus:focus,
.focus-container:focus .text-blue-focus,
.shopperLand a.text-blue,
.text-blue a,
.shopperLand .text-blue a {
	color: #2d7ff9;
	fill: #2d7ff9
}
.text-blue.link:hover,
a.text-blue.link:hover,
.text-blue-focus:focus.link:hover,
.focus-container:focus .text-blue-focus.link:hover,
.shopperLand a.text-blue.link:hover,
.text-blue a.link:hover,
.shopperLand .text-blue a.link:hover {
	color: #054ebb;
	fill: #054ebb
}
.text-blue.understroke,
a.text-blue.understroke,
.text-blue-focus:focus.understroke,
.focus-container:focus .text-blue-focus.understroke,
.shopperLand a.text-blue.understroke,
.text-blue a.understroke,
.shopperLand .text-blue a.understroke {
	border-color: #2d7ff9
}
.text-blue.understroke.link:hover,
a.text-blue.understroke.link:hover,
.text-blue-focus:focus.understroke.link:hover,
.focus-container:focus .text-blue-focus.understroke.link:hover,
.shopperLand a.text-blue.understroke.link:hover,
.text-blue a.understroke.link:hover,
.shopperLand .text-blue a.understroke.link:hover,
.text-blue.understroke.link.active,
a.text-blue.understroke.link.active,
.text-blue-focus:focus.understroke.link.active,
.focus-container:focus .text-blue-focus.understroke.link.active,
.shopperLand a.text-blue.understroke.link.active,
.text-blue a.understroke.link.active,
.shopperLand .text-blue a.understroke.link.active {
	border-color: #054ebb
}
.text-blue-dark1-hover:hover,
a.text-blue-dark1-hover:hover {
	color: #2750ae;
	fill: #2750ae
}
[data-color=blue].coloredBackground {
	background-color: #1283da !important
}
[data-color=blue].brightColoredBackground {
	background-color: #2d7ff9
}
[data-color=blue].lightColoredBackground {
	background-color: #cfdfff
}
[data-color=blue].light1ColoredBackground {
	background-color: #9cc7ff
}
[data-color=blue].darkColoredBackground {
	background-color: #2750ae
}
[data-color=blue].coloredBorder {
	border-color: #1283da
}
[data-color=blue].lightColoredBorder {
	border-color: #cfdfff
}
[data-color=blue].coloredText {
	color: #1283da;
	fill: #1283da
}
[data-color=blue].darkColoredText {
	color: #2750ae;
	fill: #2750ae
}
[data-color=blue] .parentColoredBackground {
	background-color: #1283da !important
}
[data-color=blue] .parentBrightColoredBackground {
	background-color: #2d7ff9
}
[data-color=blue] .parentLightColoredBackground {
	background-color: #cfdfff
}
[data-color=blue] .parentDarkColoredBackground {
	background-color: #2750ae
}
[data-color=blue] .parentColoredBorder {
	border-color: #2750ae !important
}
[data-color=blue] .parentColoredText {
	color: #1283da
}
[data-color=blue] .parentLightColoredText {
	color: #cfdfff
}
[data-color=blue] .parentColoredFill {
	fill: #1283da
}
[data-color=blue] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(18, 131, 218, 0), #1283da)
}
[data-color=blue] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(18, 131, 218, 0), #1283da)
}
[data-color=blue] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(18, 131, 218, 0), #319cee)
}
[data-color=blue] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(18, 131, 218, 0), #319cee)
}
[data-color=blue] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="blueLight"].coloredBackground {
	background-color: #cfdfff !important
}
[data-color="blueLight"].brightColoredBackground {
	background-color: #cfdfff
}
[data-color="blueLight"].lightColoredBackground {
	background-color: #cfdfff
}
[data-color="blueLight"].light1ColoredBackground {
	background-color: #cfdfff
}
[data-color="blueLight"].coloredBorder {
	border-color: #cfdfff
}
[data-color="blueLight"].lightColoredBorder {
	border-color: #cfdfff
}
[data-color="blueLight"].coloredText {
	color: #cfdfff;
	fill: #cfdfff
}
[data-color="blueLight"] .parentColoredBackground {
	background-color: #cfdfff !important
}
[data-color="blueLight"] .parentBrightColoredBackground {
	background-color: #cfdfff
}
[data-color="blueLight"] .parentLightColoredBackground {
	background-color: #cfdfff
}
[data-color="blueLight"] .parentColoredBorder {
	border-color: #cfdfff !important
}
[data-color="blueLight"] .parentColoredText {
	color: #cfdfff
}
[data-color="blueLight"] .parentLightColoredText {
	color: #cfdfff
}
[data-color="blueLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(207, 223, 255, 0), #cfdfff)
}
[data-color="blueLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(207, 223, 255, 0), #cfdfff)
}
[data-color="blueLight"] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="blueLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.cyan,
.cyanBright,
.cyan-focus:focus {
	background-color: #18bfff
}
.cyan-base,
.cyanBase,
.cyan-base:focus,
.hover-container:hover .parent-hover-cyan-base {
	background-color: #01a9db
}
.cyan-dark1,
.cyanDark1,
.cyanDark1-hover:hover,
.cyanDark1-focus:focus {
	background-color: #0b76b7
}
.cyan-light1,
.cyanLight1,
.cyanLight1-hover:hover,
.cyanLight1-focus:focus {
	background-color: #77d1f3
}
.cyan-light2,
.cyan-light,
.cyanLight2,
.cyanLight,
.cyanLight2-hover:hover,
.cyanLight-hover:hover,
.cyanLight2-focus:focus,
.cyanLight-focus:focus {
	background-color: #d0f0fd
}
.cyan-border,
.border-cyan {
	border-color: #18bfff
}
.border-cyan-base {
	border-color: #01a9db
}
.border-cyan-light1 {
	border-color: #77d1f3
}
.border-cyan-light2 {
	border-color: #d0f0fd
}
.border-cyan-focus:focus,
.focus-container:focus .parent-focus-border-cyan {
	border-color: #18bfff
}
.stroked-cyan-light1 {
	box-shadow: 0 0 0 2px #77d1f3
}
.stroked-cyan-light2 {
	box-shadow: 0 0 0 2px #d0f0fd
}
.stroked-cyan {
	box-shadow: 0 0 0 2px #18bfff
}
.stroked-cyan-inset {
	box-shadow: inset 0 0 0 2px #18bfff
}
.stroked-cyan-light2-inset {
	box-shadow: inset 0 0 0 2px #d0f0fd
}
.text-cyan,
a.text-cyan,
.text-cyan-focus:focus,
.focus-container:focus .text-cyan-focus,
.shopperLand a.text-cyan,
.text-cyan a,
.shopperLand .text-cyan a {
	color: #18bfff;
	fill: #18bfff
}
.text-cyan.link:hover,
a.text-cyan.link:hover,
.text-cyan-focus:focus.link:hover,
.focus-container:focus .text-cyan-focus.link:hover,
.shopperLand a.text-cyan.link:hover,
.text-cyan a.link:hover,
.shopperLand .text-cyan a.link:hover {
	color: #0080b1;
	fill: #0080b1
}
.text-cyan.understroke,
a.text-cyan.understroke,
.text-cyan-focus:focus.understroke,
.focus-container:focus .text-cyan-focus.understroke,
.shopperLand a.text-cyan.understroke,
.text-cyan a.understroke,
.shopperLand .text-cyan a.understroke {
	border-color: #18bfff
}
.text-cyan.understroke.link:hover,
a.text-cyan.understroke.link:hover,
.text-cyan-focus:focus.understroke.link:hover,
.focus-container:focus .text-cyan-focus.understroke.link:hover,
.shopperLand a.text-cyan.understroke.link:hover,
.text-cyan a.understroke.link:hover,
.shopperLand .text-cyan a.understroke.link:hover,
.text-cyan.understroke.link.active,
a.text-cyan.understroke.link.active,
.text-cyan-focus:focus.understroke.link.active,
.focus-container:focus .text-cyan-focus.understroke.link.active,
.shopperLand a.text-cyan.understroke.link.active,
.text-cyan a.understroke.link.active,
.shopperLand .text-cyan a.understroke.link.active {
	border-color: #0080b1
}
.text-cyan-dark1-hover:hover,
a.text-cyan-dark1-hover:hover {
	color: #0b76b7;
	fill: #0b76b7
}
[data-color=cyan].coloredBackground {
	background-color: #01a9db !important
}
[data-color=cyan].brightColoredBackground {
	background-color: #18bfff
}
[data-color=cyan].lightColoredBackground {
	background-color: #d0f0fd
}
[data-color=cyan].light1ColoredBackground {
	background-color: #77d1f3
}
[data-color=cyan].darkColoredBackground {
	background-color: #0b76b7
}
[data-color=cyan].coloredBorder {
	border-color: #01a9db
}
[data-color=cyan].lightColoredBorder {
	border-color: #d0f0fd
}
[data-color=cyan].coloredText {
	color: #01a9db;
	fill: #01a9db
}
[data-color=cyan].darkColoredText {
	color: #0b76b7;
	fill: #0b76b7
}
[data-color=cyan] .parentColoredBackground {
	background-color: #01a9db !important
}
[data-color=cyan] .parentBrightColoredBackground {
	background-color: #18bfff
}
[data-color=cyan] .parentLightColoredBackground {
	background-color: #d0f0fd
}
[data-color=cyan] .parentDarkColoredBackground {
	background-color: #0b76b7
}
[data-color=cyan] .parentColoredBorder {
	border-color: #0b76b7 !important
}
[data-color=cyan] .parentColoredText {
	color: #01a9db
}
[data-color=cyan] .parentLightColoredText {
	color: #d0f0fd
}
[data-color=cyan] .parentColoredFill {
	fill: #01a9db
}
[data-color=cyan] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(1, 169, 219, 0), #01a9db)
}
[data-color=cyan] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(1, 169, 219, 0), #01a9db)
}
[data-color=cyan] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(1, 169, 219, 0), #11c8fe)
}
[data-color=cyan] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(1, 169, 219, 0), #11c8fe)
}
[data-color=cyan] .parentComplementaryColoredBackground {
	background-color: #e52e4d !important
}
[data-color="cyanLight"].coloredBackground {
	background-color: #d0f0fd !important
}
[data-color="cyanLight"].brightColoredBackground {
	background-color: #d0f0fd
}
[data-color="cyanLight"].lightColoredBackground {
	background-color: #d0f0fd
}
[data-color="cyanLight"].light1ColoredBackground {
	background-color: #d0f0fd
}
[data-color="cyanLight"].coloredBorder {
	border-color: #d0f0fd
}
[data-color="cyanLight"].lightColoredBorder {
	border-color: #d0f0fd
}
[data-color="cyanLight"].coloredText {
	color: #d0f0fd;
	fill: #d0f0fd
}
[data-color="cyanLight"] .parentColoredBackground {
	background-color: #d0f0fd !important
}
[data-color="cyanLight"] .parentBrightColoredBackground {
	background-color: #d0f0fd
}
[data-color="cyanLight"] .parentLightColoredBackground {
	background-color: #d0f0fd
}
[data-color="cyanLight"] .parentColoredBorder {
	border-color: #d0f0fd !important
}
[data-color="cyanLight"] .parentColoredText {
	color: #d0f0fd
}
[data-color="cyanLight"] .parentLightColoredText {
	color: #d0f0fd
}
[data-color="cyanLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(208, 240, 253, 0), #d0f0fd)
}
[data-color="cyanLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(208, 240, 253, 0), #d0f0fd)
}
[data-color="cyanLight"] .parentComplementaryColoredBackground {
	background-color: #e52e4d !important
}
[data-color="cyanLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.teal,
.tealBright,
.teal-focus:focus {
	background-color: #20d9d2
}
.teal-base,
.tealBase,
.teal-base:focus,
.hover-container:hover .parent-hover-teal-base {
	background-color: #02aaa4
}
.teal-dark1,
.tealDark1,
.tealDark1-hover:hover,
.tealDark1-focus:focus {
	background-color: #06a09b
}
.teal-light1,
.tealLight1,
.tealLight1-hover:hover,
.tealLight1-focus:focus {
	background-color: #72ddc3
}
.teal-light2,
.teal-light,
.tealLight2,
.tealLight,
.tealLight2-hover:hover,
.tealLight-hover:hover,
.tealLight2-focus:focus,
.tealLight-focus:focus {
	background-color: #c2f5e9
}
.teal-border,
.border-teal {
	border-color: #20d9d2
}
.border-teal-base {
	border-color: #02aaa4
}
.border-teal-light1 {
	border-color: #72ddc3
}
.border-teal-light2 {
	border-color: #c2f5e9
}
.border-teal-focus:focus,
.focus-container:focus .parent-focus-border-teal {
	border-color: #20d9d2
}
.stroked-teal-light1 {
	box-shadow: 0 0 0 2px #72ddc3
}
.stroked-teal-light2 {
	box-shadow: 0 0 0 2px #c2f5e9
}
.stroked-teal {
	box-shadow: 0 0 0 2px #20d9d2
}
.stroked-teal-inset {
	box-shadow: inset 0 0 0 2px #20d9d2
}
.stroked-teal-light2-inset {
	box-shadow: inset 0 0 0 2px #c2f5e9
}
.text-teal,
a.text-teal,
.text-teal-focus:focus,
.focus-container:focus .text-teal-focus,
.shopperLand a.text-teal,
.text-teal a,
.shopperLand .text-teal a {
	color: #20d9d2;
	fill: #20d9d2
}
.text-teal.link:hover,
a.text-teal.link:hover,
.text-teal-focus:focus.link:hover,
.focus-container:focus .text-teal-focus.link:hover,
.shopperLand a.text-teal.link:hover,
.text-teal a.link:hover,
.shopperLand .text-teal a.link:hover {
	color: #13807c;
	fill: #13807c
}
.text-teal.understroke,
a.text-teal.understroke,
.text-teal-focus:focus.understroke,
.focus-container:focus .text-teal-focus.understroke,
.shopperLand a.text-teal.understroke,
.text-teal a.understroke,
.shopperLand .text-teal a.understroke {
	border-color: #20d9d2
}
.text-teal.understroke.link:hover,
a.text-teal.understroke.link:hover,
.text-teal-focus:focus.understroke.link:hover,
.focus-container:focus .text-teal-focus.understroke.link:hover,
.shopperLand a.text-teal.understroke.link:hover,
.text-teal a.understroke.link:hover,
.shopperLand .text-teal a.understroke.link:hover,
.text-teal.understroke.link.active,
a.text-teal.understroke.link.active,
.text-teal-focus:focus.understroke.link.active,
.focus-container:focus .text-teal-focus.understroke.link.active,
.shopperLand a.text-teal.understroke.link.active,
.text-teal a.understroke.link.active,
.shopperLand .text-teal a.understroke.link.active {
	border-color: #13807c
}
.text-teal-dark1-hover:hover,
a.text-teal-dark1-hover:hover {
	color: #06a09b;
	fill: #06a09b
}
[data-color=teal].coloredBackground {
	background-color: #02aaa4 !important
}
[data-color=teal].brightColoredBackground {
	background-color: #20d9d2
}
[data-color=teal].lightColoredBackground {
	background-color: #c2f5e9
}
[data-color=teal].light1ColoredBackground {
	background-color: #72ddc3
}
[data-color=teal].darkColoredBackground {
	background-color: #06a09b
}
[data-color=teal].coloredBorder {
	border-color: #02aaa4
}
[data-color=teal].lightColoredBorder {
	border-color: #c2f5e9
}
[data-color=teal].coloredText {
	color: #02aaa4;
	fill: #02aaa4
}
[data-color=teal].darkColoredText {
	color: #06a09b;
	fill: #06a09b
}
[data-color=teal] .parentColoredBackground {
	background-color: #02aaa4 !important
}
[data-color=teal] .parentBrightColoredBackground {
	background-color: #20d9d2
}
[data-color=teal] .parentLightColoredBackground {
	background-color: #c2f5e9
}
[data-color=teal] .parentDarkColoredBackground {
	background-color: #06a09b
}
[data-color=teal] .parentColoredBorder {
	border-color: #06a09b !important
}
[data-color=teal] .parentColoredText {
	color: #02aaa4
}
[data-color=teal] .parentLightColoredText {
	color: #c2f5e9
}
[data-color=teal] .parentColoredFill {
	fill: #02aaa4
}
[data-color=teal] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(2, 170, 164, 0), #02aaa4)
}
[data-color=teal] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(2, 170, 164, 0), #02aaa4)
}
[data-color=teal] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(2, 170, 164, 0), #03dcd5)
}
[data-color=teal] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(2, 170, 164, 0), #03dcd5)
}
[data-color=teal] .parentComplementaryColoredBackground {
	background-color: #7c39ed !important
}
[data-color="tealLight"].coloredBackground {
	background-color: #c2f5e9 !important
}
[data-color="tealLight"].brightColoredBackground {
	background-color: #c2f5e9
}
[data-color="tealLight"].lightColoredBackground {
	background-color: #c2f5e9
}
[data-color="tealLight"].light1ColoredBackground {
	background-color: #c2f5e9
}
[data-color="tealLight"].coloredBorder {
	border-color: #c2f5e9
}
[data-color="tealLight"].lightColoredBorder {
	border-color: #c2f5e9
}
[data-color="tealLight"].coloredText {
	color: #c2f5e9;
	fill: #c2f5e9
}
[data-color="tealLight"] .parentColoredBackground {
	background-color: #c2f5e9 !important
}
[data-color="tealLight"] .parentBrightColoredBackground {
	background-color: #c2f5e9
}
[data-color="tealLight"] .parentLightColoredBackground {
	background-color: #c2f5e9
}
[data-color="tealLight"] .parentColoredBorder {
	border-color: #c2f5e9 !important
}
[data-color="tealLight"] .parentColoredText {
	color: #c2f5e9
}
[data-color="tealLight"] .parentLightColoredText {
	color: #c2f5e9
}
[data-color="tealLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(194, 245, 233, 0), #c2f5e9)
}
[data-color="tealLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(194, 245, 233, 0), #c2f5e9)
}
[data-color="tealLight"] .parentComplementaryColoredBackground {
	background-color: #7c39ed !important
}
[data-color="tealLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.green,
.greenBright,
.green-focus:focus {
	background-color: #20c933
}
.green-base,
.greenBase,
.green-base:focus,
.hover-container:hover .parent-hover-green-base {
	background-color: #11af22
}
.green-dark1,
.greenDark1,
.greenDark1-hover:hover,
.greenDark1-focus:focus {
	background-color: #338a17
}
.green-light1,
.greenLight1,
.greenLight1-hover:hover,
.greenLight1-focus:focus {
	background-color: #93e088
}
.green-light2,
.green-light,
.greenLight2,
.greenLight,
.greenLight2-hover:hover,
.greenLight-hover:hover,
.greenLight2-focus:focus,
.greenLight-focus:focus {
	background-color: #d1f7c4
}
.green-border,
.border-green {
	border-color: #20c933
}
.border-green-base {
	border-color: #11af22
}
.border-green-light1 {
	border-color: #93e088
}
.border-green-light2 {
	border-color: #d1f7c4
}
.border-green-focus:focus,
.focus-container:focus .parent-focus-border-green {
	border-color: #20c933
}
.stroked-green-light1 {
	box-shadow: 0 0 0 2px #93e088
}
.stroked-green-light2 {
	box-shadow: 0 0 0 2px #d1f7c4
}
.stroked-green {
	box-shadow: 0 0 0 2px #20c933
}
.stroked-green-inset {
	box-shadow: inset 0 0 0 2px #20c933
}
.stroked-green-light2-inset {
	box-shadow: inset 0 0 0 2px #d1f7c4
}
.text-green,
a.text-green,
.text-green-focus:focus,
.focus-container:focus .text-green-focus,
.shopperLand a.text-green,
.text-green a,
.shopperLand .text-green a {
	color: #20c933;
	fill: #20c933
}
.text-green.link:hover,
a.text-green.link:hover,
.text-green-focus:focus.link:hover,
.focus-container:focus .text-green-focus.link:hover,
.shopperLand a.text-green.link:hover,
.text-green a.link:hover,
.shopperLand .text-green a.link:hover {
	color: #12711d;
	fill: #12711d
}
.text-green.understroke,
a.text-green.understroke,
.text-green-focus:focus.understroke,
.focus-container:focus .text-green-focus.understroke,
.shopperLand a.text-green.understroke,
.text-green a.understroke,
.shopperLand .text-green a.understroke {
	border-color: #20c933
}
.text-green.understroke.link:hover,
a.text-green.understroke.link:hover,
.text-green-focus:focus.understroke.link:hover,
.focus-container:focus .text-green-focus.understroke.link:hover,
.shopperLand a.text-green.understroke.link:hover,
.text-green a.understroke.link:hover,
.shopperLand .text-green a.understroke.link:hover,
.text-green.understroke.link.active,
a.text-green.understroke.link.active,
.text-green-focus:focus.understroke.link.active,
.focus-container:focus .text-green-focus.understroke.link.active,
.shopperLand a.text-green.understroke.link.active,
.text-green a.understroke.link.active,
.shopperLand .text-green a.understroke.link.active {
	border-color: #12711d
}
.text-green-dark1-hover:hover,
a.text-green-dark1-hover:hover {
	color: #338a17;
	fill: #338a17
}
[data-color=green].coloredBackground {
	background-color: #11af22 !important
}
[data-color=green].brightColoredBackground {
	background-color: #20c933
}
[data-color=green].lightColoredBackground {
	background-color: #d1f7c4
}
[data-color=green].light1ColoredBackground {
	background-color: #93e088
}
[data-color=green].darkColoredBackground {
	background-color: #338a17
}
[data-color=green].coloredBorder {
	border-color: #11af22
}
[data-color=green].lightColoredBorder {
	border-color: #d1f7c4
}
[data-color=green].coloredText {
	color: #11af22;
	fill: #11af22
}
[data-color=green].darkColoredText {
	color: #338a17;
	fill: #338a17
}
[data-color=green] .parentColoredBackground {
	background-color: #11af22 !important
}
[data-color=green] .parentBrightColoredBackground {
	background-color: #20c933
}
[data-color=green] .parentLightColoredBackground {
	background-color: #d1f7c4
}
[data-color=green] .parentDarkColoredBackground {
	background-color: #338a17
}
[data-color=green] .parentColoredBorder {
	border-color: #338a17 !important
}
[data-color=green] .parentColoredText {
	color: #11af22
}
[data-color=green] .parentLightColoredText {
	color: #d1f7c4
}
[data-color=green] .parentColoredFill {
	fill: #11af22
}
[data-color=green] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(17, 175, 34, 0), #11af22)
}
[data-color=green] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(17, 175, 34, 0), #11af22)
}
[data-color=green] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(17, 175, 34, 0), #16dd2b)
}
[data-color=green] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(17, 175, 34, 0), #16dd2b)
}
[data-color=green] .parentComplementaryColoredBackground {
	background-color: #e52e4d !important
}
[data-color="greenLight"].coloredBackground {
	background-color: #d1f7c4 !important
}
[data-color="greenLight"].brightColoredBackground {
	background-color: #d1f7c4
}
[data-color="greenLight"].lightColoredBackground {
	background-color: #d1f7c4
}
[data-color="greenLight"].light1ColoredBackground {
	background-color: #d1f7c4
}
[data-color="greenLight"].coloredBorder {
	border-color: #d1f7c4
}
[data-color="greenLight"].lightColoredBorder {
	border-color: #d1f7c4
}
[data-color="greenLight"].coloredText {
	color: #d1f7c4;
	fill: #d1f7c4
}
[data-color="greenLight"] .parentColoredBackground {
	background-color: #d1f7c4 !important
}
[data-color="greenLight"] .parentBrightColoredBackground {
	background-color: #d1f7c4
}
[data-color="greenLight"] .parentLightColoredBackground {
	background-color: #d1f7c4
}
[data-color="greenLight"] .parentColoredBorder {
	border-color: #d1f7c4 !important
}
[data-color="greenLight"] .parentColoredText {
	color: #d1f7c4
}
[data-color="greenLight"] .parentLightColoredText {
	color: #d1f7c4
}
[data-color="greenLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(209, 247, 196, 0), #d1f7c4)
}
[data-color="greenLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(209, 247, 196, 0), #d1f7c4)
}
[data-color="greenLight"] .parentComplementaryColoredBackground {
	background-color: #e52e4d !important
}
[data-color="greenLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.gray,
.grayBright,
.gray-focus:focus {
	background-color: #666
}
.gray-base,
.grayBase,
.gray-base:focus,
.hover-container:hover .parent-hover-gray-base {
	background-color: #666
}
.gray-dark1,
.grayDark1,
.grayDark1-hover:hover,
.grayDark1-focus:focus {
	background-color: #444
}
.gray-light1,
.grayLight1,
.grayLight1-hover:hover,
.grayLight1-focus:focus {
	background-color: #ccc
}
.gray-light2,
.gray-light,
.grayLight2,
.grayLight,
.grayLight2-hover:hover,
.grayLight-hover:hover,
.grayLight2-focus:focus,
.grayLight-focus:focus {
	background-color: #eee
}
.gray-border,
.border-gray {
	border-color: #666
}
.border-gray-base {
	border-color: #666
}
.border-gray-light1 {
	border-color: #ccc
}
.border-gray-light2 {
	border-color: #eee
}
.border-gray-focus:focus,
.focus-container:focus .parent-focus-border-gray {
	border-color: #666
}
.stroked-gray-light1 {
	box-shadow: 0 0 0 2px #ccc
}
.stroked-gray-light2 {
	box-shadow: 0 0 0 2px #eee
}
.stroked-gray {
	box-shadow: 0 0 0 2px #666
}
.stroked-gray-inset {
	box-shadow: inset 0 0 0 2px #666
}
.stroked-gray-light2-inset {
	box-shadow: inset 0 0 0 2px #eee
}
.text-gray,
a.text-gray,
.text-gray-focus:focus,
.focus-container:focus .text-gray-focus,
.shopperLand a.text-gray,
.text-gray a,
.shopperLand .text-gray a {
	color: #666;
	fill: #666
}
.text-gray.link:hover,
a.text-gray.link:hover,
.text-gray-focus:focus.link:hover,
.focus-container:focus .text-gray-focus.link:hover,
.shopperLand a.text-gray.link:hover,
.text-gray a.link:hover,
.shopperLand .text-gray a.link:hover {
	color: #333;
	fill: #333
}
.text-gray.understroke,
a.text-gray.understroke,
.text-gray-focus:focus.understroke,
.focus-container:focus .text-gray-focus.understroke,
.shopperLand a.text-gray.understroke,
.text-gray a.understroke,
.shopperLand .text-gray a.understroke {
	border-color: #666
}
.text-gray.understroke.link:hover,
a.text-gray.understroke.link:hover,
.text-gray-focus:focus.understroke.link:hover,
.focus-container:focus .text-gray-focus.understroke.link:hover,
.shopperLand a.text-gray.understroke.link:hover,
.text-gray a.understroke.link:hover,
.shopperLand .text-gray a.understroke.link:hover,
.text-gray.understroke.link.active,
a.text-gray.understroke.link.active,
.text-gray-focus:focus.understroke.link.active,
.focus-container:focus .text-gray-focus.understroke.link.active,
.shopperLand a.text-gray.understroke.link.active,
.text-gray a.understroke.link.active,
.shopperLand .text-gray a.understroke.link.active {
	border-color: #333
}
.text-gray-dark1-hover:hover,
a.text-gray-dark1-hover:hover {
	color: #444;
	fill: #444
}
[data-color=gray].coloredBackground {
	background-color: #666 !important
}
[data-color=gray].brightColoredBackground {
	background-color: #666
}
[data-color=gray].lightColoredBackground {
	background-color: #eee
}
[data-color=gray].light1ColoredBackground {
	background-color: #ccc
}
[data-color=gray].darkColoredBackground {
	background-color: #444
}
[data-color=gray].coloredBorder {
	border-color: #666
}
[data-color=gray].lightColoredBorder {
	border-color: #eee
}
[data-color=gray].coloredText {
	color: #666;
	fill: #666
}
[data-color=gray].darkColoredText {
	color: #444;
	fill: #444
}
[data-color=gray] .parentColoredBackground {
	background-color: #666 !important
}
[data-color=gray] .parentBrightColoredBackground {
	background-color: #666
}
[data-color=gray] .parentLightColoredBackground {
	background-color: #eee
}
[data-color=gray] .parentDarkColoredBackground {
	background-color: #444
}
[data-color=gray] .parentColoredBorder {
	border-color: #444 !important
}
[data-color=gray] .parentColoredText {
	color: #666
}
[data-color=gray] .parentLightColoredText {
	color: #eee
}
[data-color=gray] .parentColoredFill {
	fill: #666
}
[data-color=gray] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(102, 102, 102, 0), #666)
}
[data-color=gray] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(102, 102, 102, 0), #666)
}
[data-color=gray] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(102, 102, 102, 0), #808080)
}
[data-color=gray] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(102, 102, 102, 0), #808080)
}
[data-color=gray] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="grayLight"].coloredBackground {
	background-color: #eee !important
}
[data-color="grayLight"].brightColoredBackground {
	background-color: #eee
}
[data-color="grayLight"].lightColoredBackground {
	background-color: #eee
}
[data-color="grayLight"].light1ColoredBackground {
	background-color: #eee
}
[data-color="grayLight"].coloredBorder {
	border-color: #eee
}
[data-color="grayLight"].lightColoredBorder {
	border-color: #eee
}
[data-color="grayLight"].coloredText {
	color: #eee;
	fill: #eee
}
[data-color="grayLight"] .parentColoredBackground {
	background-color: #eee !important
}
[data-color="grayLight"] .parentBrightColoredBackground {
	background-color: #eee
}
[data-color="grayLight"] .parentLightColoredBackground {
	background-color: #eee
}
[data-color="grayLight"] .parentColoredBorder {
	border-color: #eee !important
}
[data-color="grayLight"] .parentColoredText {
	color: #eee
}
[data-color="grayLight"] .parentLightColoredText {
	color: #eee
}
[data-color="grayLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #eee)
}
[data-color="grayLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(238, 238, 238, 0), #eee)
}
[data-color="grayLight"] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="grayLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.grey,
.greyBright,
.grey-focus:focus {
	background-color: #666
}
.grey-base,
.greyBase,
.grey-base:focus,
.hover-container:hover .parent-hover-grey-base {
	background-color: #666
}
.grey-dark1,
.greyDark1,
.greyDark1-hover:hover,
.greyDark1-focus:focus {
	background-color: #444
}
.grey-light1,
.greyLight1,
.greyLight1-hover:hover,
.greyLight1-focus:focus {
	background-color: #ccc
}
.grey-light2,
.grey-light,
.greyLight2,
.greyLight,
.greyLight2-hover:hover,
.greyLight-hover:hover,
.greyLight2-focus:focus,
.greyLight-focus:focus {
	background-color: #eee
}
.grey-border,
.border-grey {
	border-color: #666
}
.border-grey-base {
	border-color: #666
}
.border-grey-light1 {
	border-color: #ccc
}
.border-grey-light2 {
	border-color: #eee
}
.border-grey-focus:focus,
.focus-container:focus .parent-focus-border-grey {
	border-color: #666
}
.stroked-grey-light1 {
	box-shadow: 0 0 0 2px #ccc
}
.stroked-grey-light2 {
	box-shadow: 0 0 0 2px #eee
}
.stroked-grey {
	box-shadow: 0 0 0 2px #666
}
.stroked-grey-inset {
	box-shadow: inset 0 0 0 2px #666
}
.stroked-grey-light2-inset {
	box-shadow: inset 0 0 0 2px #eee
}
.text-grey,
a.text-grey,
.text-grey-focus:focus,
.focus-container:focus .text-grey-focus,
.shopperLand a.text-grey,
.text-grey a,
.shopperLand .text-grey a {
	color: #666;
	fill: #666
}
.text-grey.link:hover,
a.text-grey.link:hover,
.text-grey-focus:focus.link:hover,
.focus-container:focus .text-grey-focus.link:hover,
.shopperLand a.text-grey.link:hover,
.text-grey a.link:hover,
.shopperLand .text-grey a.link:hover {
	color: #333;
	fill: #333
}
.text-grey.understroke,
a.text-grey.understroke,
.text-grey-focus:focus.understroke,
.focus-container:focus .text-grey-focus.understroke,
.shopperLand a.text-grey.understroke,
.text-grey a.understroke,
.shopperLand .text-grey a.understroke {
	border-color: #666
}
.text-grey.understroke.link:hover,
a.text-grey.understroke.link:hover,
.text-grey-focus:focus.understroke.link:hover,
.focus-container:focus .text-grey-focus.understroke.link:hover,
.shopperLand a.text-grey.understroke.link:hover,
.text-grey a.understroke.link:hover,
.shopperLand .text-grey a.understroke.link:hover,
.text-grey.understroke.link.active,
a.text-grey.understroke.link.active,
.text-grey-focus:focus.understroke.link.active,
.focus-container:focus .text-grey-focus.understroke.link.active,
.shopperLand a.text-grey.understroke.link.active,
.text-grey a.understroke.link.active,
.shopperLand .text-grey a.understroke.link.active {
	border-color: #333
}
.text-grey-dark1-hover:hover,
a.text-grey-dark1-hover:hover {
	color: #444;
	fill: #444
}
[data-color=grey].coloredBackground {
	background-color: #666 !important
}
[data-color=grey].brightColoredBackground {
	background-color: #666
}
[data-color=grey].lightColoredBackground {
	background-color: #eee
}
[data-color=grey].light1ColoredBackground {
	background-color: #ccc
}
[data-color=grey].darkColoredBackground {
	background-color: #444
}
[data-color=grey].coloredBorder {
	border-color: #666
}
[data-color=grey].lightColoredBorder {
	border-color: #eee
}
[data-color=grey].coloredText {
	color: #666;
	fill: #666
}
[data-color=grey].darkColoredText {
	color: #444;
	fill: #444
}
[data-color=grey] .parentColoredBackground {
	background-color: #666 !important
}
[data-color=grey] .parentBrightColoredBackground {
	background-color: #666
}
[data-color=grey] .parentLightColoredBackground {
	background-color: #eee
}
[data-color=grey] .parentDarkColoredBackground {
	background-color: #444
}
[data-color=grey] .parentColoredBorder {
	border-color: #444 !important
}
[data-color=grey] .parentColoredText {
	color: #666
}
[data-color=grey] .parentLightColoredText {
	color: #eee
}
[data-color=grey] .parentColoredFill {
	fill: #666
}
[data-color=grey] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(102, 102, 102, 0), #666)
}
[data-color=grey] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(102, 102, 102, 0), #666)
}
[data-color=grey] .parentColoredLinearGradientFromTransparentToRightLight {
	background-image: linear-gradient(to right, rgba(102, 102, 102, 0), #808080)
}
[data-color=grey] .parentColoredLinearGradientFromTransparentToLeftLight {
	background-image: linear-gradient(to left, rgba(102, 102, 102, 0), #808080)
}
[data-color=grey] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="greyLight"].coloredBackground {
	background-color: #eee !important
}
[data-color="greyLight"].brightColoredBackground {
	background-color: #eee
}
[data-color="greyLight"].lightColoredBackground {
	background-color: #eee
}
[data-color="greyLight"].light1ColoredBackground {
	background-color: #eee
}
[data-color="greyLight"].coloredBorder {
	border-color: #eee
}
[data-color="greyLight"].lightColoredBorder {
	border-color: #eee
}
[data-color="greyLight"].coloredText {
	color: #eee;
	fill: #eee
}
[data-color="greyLight"] .parentColoredBackground {
	background-color: #eee !important
}
[data-color="greyLight"] .parentBrightColoredBackground {
	background-color: #eee
}
[data-color="greyLight"] .parentLightColoredBackground {
	background-color: #eee
}
[data-color="greyLight"] .parentColoredBorder {
	border-color: #eee !important
}
[data-color="greyLight"] .parentColoredText {
	color: #eee
}
[data-color="greyLight"] .parentLightColoredText {
	color: #eee
}
[data-color="greyLight"] .parentColoredLinearGradientFromTransparentToRight {
	background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #eee)
}
[data-color="greyLight"] .parentColoredLinearGradientFromTransparentToLeft {
	background-image: linear-gradient(to left, rgba(238, 238, 238, 0), #eee)
}
[data-color="greyLight"] .parentComplementaryColoredBackground {
	background-color: #fcb400 !important
}
[data-color="greyLight"].appIcon .symbol {
	color: hsl(0, 0%, 20%)
}
.text-yellow-dark1,
a.text-yellow-dark1,
.text-yellow-dark1-focus:focus,
.focus-container:focus .text-yellow-dark1-focus,
.shopperLand a.text-yellow-dark1,
.text-yellow-dark1 a,
.shopperLand .text-yellow-dark1 a {
	color: #3b2501;
	fill: #3b2501
}
.text-yellow-dark1.link:hover,
a.text-yellow-dark1.link:hover,
.text-yellow-dark1-focus:focus.link:hover,
.focus-container:focus .text-yellow-dark1-focus.link:hover,
.shopperLand a.text-yellow-dark1.link:hover,
.text-yellow-dark1 a.link:hover,
.shopperLand .text-yellow-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-yellow-dark1.understroke,
a.text-yellow-dark1.understroke,
.text-yellow-dark1-focus:focus.understroke,
.focus-container:focus .text-yellow-dark1-focus.understroke,
.shopperLand a.text-yellow-dark1.understroke,
.text-yellow-dark1 a.understroke,
.shopperLand .text-yellow-dark1 a.understroke {
	border-color: #3b2501
}
.text-yellow-dark1.understroke.link:hover,
a.text-yellow-dark1.understroke.link:hover,
.text-yellow-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-yellow-dark1-focus.understroke.link:hover,
.shopperLand a.text-yellow-dark1.understroke.link:hover,
.text-yellow-dark1 a.understroke.link:hover,
.shopperLand .text-yellow-dark1 a.understroke.link:hover,
.text-yellow-dark1.understroke.link.active,
a.text-yellow-dark1.understroke.link.active,
.text-yellow-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-yellow-dark1-focus.understroke.link.active,
.shopperLand a.text-yellow-dark1.understroke.link.active,
.text-yellow-dark1 a.understroke.link.active,
.shopperLand .text-yellow-dark1 a.understroke.link.active {
	border-color: #000
}
.text-orange-dark1,
a.text-orange-dark1,
.text-orange-dark1-focus:focus,
.focus-container:focus .text-orange-dark1-focus,
.shopperLand a.text-orange-dark1,
.text-orange-dark1 a,
.shopperLand .text-orange-dark1 a {
	color: #6b2613;
	fill: #6b2613
}
.text-orange-dark1.link:hover,
a.text-orange-dark1.link:hover,
.text-orange-dark1-focus:focus.link:hover,
.focus-container:focus .text-orange-dark1-focus.link:hover,
.shopperLand a.text-orange-dark1.link:hover,
.text-orange-dark1 a.link:hover,
.shopperLand .text-orange-dark1 a.link:hover {
	color: #140704;
	fill: #140704
}
.text-orange-dark1.understroke,
a.text-orange-dark1.understroke,
.text-orange-dark1-focus:focus.understroke,
.focus-container:focus .text-orange-dark1-focus.understroke,
.shopperLand a.text-orange-dark1.understroke,
.text-orange-dark1 a.understroke,
.shopperLand .text-orange-dark1 a.understroke {
	border-color: #6b2613
}
.text-orange-dark1.understroke.link:hover,
a.text-orange-dark1.understroke.link:hover,
.text-orange-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-orange-dark1-focus.understroke.link:hover,
.shopperLand a.text-orange-dark1.understroke.link:hover,
.text-orange-dark1 a.understroke.link:hover,
.shopperLand .text-orange-dark1 a.understroke.link:hover,
.text-orange-dark1.understroke.link.active,
a.text-orange-dark1.understroke.link.active,
.text-orange-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-orange-dark1-focus.understroke.link.active,
.shopperLand a.text-orange-dark1.understroke.link.active,
.text-orange-dark1 a.understroke.link.active,
.shopperLand .text-orange-dark1 a.understroke.link.active {
	border-color: #140704
}
.text-red-dark1,
a.text-red-dark1,
.text-red-dark1-focus:focus,
.focus-container:focus .text-red-dark1-focus,
.shopperLand a.text-red-dark1,
.text-red-dark1 a,
.shopperLand .text-red-dark1 a {
	color: #4c0c1c;
	fill: #4c0c1c
}
.text-red-dark1.link:hover,
a.text-red-dark1.link:hover,
.text-red-dark1-focus:focus.link:hover,
.focus-container:focus .text-red-dark1-focus.link:hover,
.shopperLand a.text-red-dark1.link:hover,
.text-red-dark1 a.link:hover,
.shopperLand .text-red-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-red-dark1.understroke,
a.text-red-dark1.understroke,
.text-red-dark1-focus:focus.understroke,
.focus-container:focus .text-red-dark1-focus.understroke,
.shopperLand a.text-red-dark1.understroke,
.text-red-dark1 a.understroke,
.shopperLand .text-red-dark1 a.understroke {
	border-color: #4c0c1c
}
.text-red-dark1.understroke.link:hover,
a.text-red-dark1.understroke.link:hover,
.text-red-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-red-dark1-focus.understroke.link:hover,
.shopperLand a.text-red-dark1.understroke.link:hover,
.text-red-dark1 a.understroke.link:hover,
.shopperLand .text-red-dark1 a.understroke.link:hover,
.text-red-dark1.understroke.link.active,
a.text-red-dark1.understroke.link.active,
.text-red-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-red-dark1-focus.understroke.link.active,
.shopperLand a.text-red-dark1.understroke.link.active,
.text-red-dark1 a.understroke.link.active,
.shopperLand .text-red-dark1 a.understroke.link.active {
	border-color: #000
}
.text-pink-dark1,
a.text-pink-dark1,
.text-pink-dark1-focus:focus,
.focus-container:focus .text-pink-dark1-focus,
.shopperLand a.text-pink-dark1,
.text-pink-dark1 a,
.shopperLand .text-pink-dark1 a {
	color: #400832;
	fill: #400832
}
.text-pink-dark1.link:hover,
a.text-pink-dark1.link:hover,
.text-pink-dark1-focus:focus.link:hover,
.focus-container:focus .text-pink-dark1-focus.link:hover,
.shopperLand a.text-pink-dark1.link:hover,
.text-pink-dark1 a.link:hover,
.shopperLand .text-pink-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-pink-dark1.understroke,
a.text-pink-dark1.understroke,
.text-pink-dark1-focus:focus.understroke,
.focus-container:focus .text-pink-dark1-focus.understroke,
.shopperLand a.text-pink-dark1.understroke,
.text-pink-dark1 a.understroke,
.shopperLand .text-pink-dark1 a.understroke {
	border-color: #400832
}
.text-pink-dark1.understroke.link:hover,
a.text-pink-dark1.understroke.link:hover,
.text-pink-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-pink-dark1-focus.understroke.link:hover,
.shopperLand a.text-pink-dark1.understroke.link:hover,
.text-pink-dark1 a.understroke.link:hover,
.shopperLand .text-pink-dark1 a.understroke.link:hover,
.text-pink-dark1.understroke.link.active,
a.text-pink-dark1.understroke.link.active,
.text-pink-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-pink-dark1-focus.understroke.link.active,
.shopperLand a.text-pink-dark1.understroke.link.active,
.text-pink-dark1 a.understroke.link.active,
.shopperLand .text-pink-dark1 a.understroke.link.active {
	border-color: #000
}
.text-purple-dark1,
a.text-purple-dark1,
.text-purple-dark1-focus:focus,
.focus-container:focus .text-purple-dark1-focus,
.shopperLand a.text-purple-dark1,
.text-purple-dark1 a,
.shopperLand .text-purple-dark1 a {
	color: #280b42;
	fill: #280b42
}
.text-purple-dark1.link:hover,
a.text-purple-dark1.link:hover,
.text-purple-dark1-focus:focus.link:hover,
.focus-container:focus .text-purple-dark1-focus.link:hover,
.shopperLand a.text-purple-dark1.link:hover,
.text-purple-dark1 a.link:hover,
.shopperLand .text-purple-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-purple-dark1.understroke,
a.text-purple-dark1.understroke,
.text-purple-dark1-focus:focus.understroke,
.focus-container:focus .text-purple-dark1-focus.understroke,
.shopperLand a.text-purple-dark1.understroke,
.text-purple-dark1 a.understroke,
.shopperLand .text-purple-dark1 a.understroke {
	border-color: #280b42
}
.text-purple-dark1.understroke.link:hover,
a.text-purple-dark1.understroke.link:hover,
.text-purple-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-purple-dark1-focus.understroke.link:hover,
.shopperLand a.text-purple-dark1.understroke.link:hover,
.text-purple-dark1 a.understroke.link:hover,
.shopperLand .text-purple-dark1 a.understroke.link:hover,
.text-purple-dark1.understroke.link.active,
a.text-purple-dark1.understroke.link.active,
.text-purple-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-purple-dark1-focus.understroke.link.active,
.shopperLand a.text-purple-dark1.understroke.link.active,
.text-purple-dark1 a.understroke.link.active,
.shopperLand .text-purple-dark1 a.understroke.link.active {
	border-color: #000
}
.text-blue-dark1,
a.text-blue-dark1,
.text-blue-dark1-focus:focus,
.focus-container:focus .text-blue-dark1-focus,
.shopperLand a.text-blue-dark1,
.text-blue-dark1 a,
.shopperLand .text-blue-dark1 a {
	color: #102046;
	fill: #102046
}
.text-blue-dark1.link:hover,
a.text-blue-dark1.link:hover,
.text-blue-dark1-focus:focus.link:hover,
.focus-container:focus .text-blue-dark1-focus.link:hover,
.shopperLand a.text-blue-dark1.link:hover,
.text-blue-dark1 a.link:hover,
.shopperLand .text-blue-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-blue-dark1.understroke,
a.text-blue-dark1.understroke,
.text-blue-dark1-focus:focus.understroke,
.focus-container:focus .text-blue-dark1-focus.understroke,
.shopperLand a.text-blue-dark1.understroke,
.text-blue-dark1 a.understroke,
.shopperLand .text-blue-dark1 a.understroke {
	border-color: #102046
}
.text-blue-dark1.understroke.link:hover,
a.text-blue-dark1.understroke.link:hover,
.text-blue-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-blue-dark1-focus.understroke.link:hover,
.shopperLand a.text-blue-dark1.understroke.link:hover,
.text-blue-dark1 a.understroke.link:hover,
.shopperLand .text-blue-dark1 a.understroke.link:hover,
.text-blue-dark1.understroke.link.active,
a.text-blue-dark1.understroke.link.active,
.text-blue-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-blue-dark1-focus.understroke.link.active,
.shopperLand a.text-blue-dark1.understroke.link.active,
.text-blue-dark1 a.understroke.link.active,
.shopperLand .text-blue-dark1 a.understroke.link.active {
	border-color: #000
}
.text-cyan-dark1,
a.text-cyan-dark1,
.text-cyan-dark1-focus:focus,
.focus-container:focus .text-cyan-dark1-focus,
.shopperLand a.text-cyan-dark1,
.text-cyan-dark1 a,
.shopperLand .text-cyan-dark1 a {
	color: #04283f;
	fill: #04283f
}
.text-cyan-dark1.link:hover,
a.text-cyan-dark1.link:hover,
.text-cyan-dark1-focus:focus.link:hover,
.focus-container:focus .text-cyan-dark1-focus.link:hover,
.shopperLand a.text-cyan-dark1.link:hover,
.text-cyan-dark1 a.link:hover,
.shopperLand .text-cyan-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-cyan-dark1.understroke,
a.text-cyan-dark1.understroke,
.text-cyan-dark1-focus:focus.understroke,
.focus-container:focus .text-cyan-dark1-focus.understroke,
.shopperLand a.text-cyan-dark1.understroke,
.text-cyan-dark1 a.understroke,
.shopperLand .text-cyan-dark1 a.understroke {
	border-color: #04283f
}
.text-cyan-dark1.understroke.link:hover,
a.text-cyan-dark1.understroke.link:hover,
.text-cyan-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-cyan-dark1-focus.understroke.link:hover,
.shopperLand a.text-cyan-dark1.understroke.link:hover,
.text-cyan-dark1 a.understroke.link:hover,
.shopperLand .text-cyan-dark1 a.understroke.link:hover,
.text-cyan-dark1.understroke.link.active,
a.text-cyan-dark1.understroke.link.active,
.text-cyan-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-cyan-dark1-focus.understroke.link.active,
.shopperLand a.text-cyan-dark1.understroke.link.active,
.text-cyan-dark1 a.understroke.link.active,
.shopperLand .text-cyan-dark1 a.understroke.link.active {
	border-color: #000
}
.text-teal-dark1,
a.text-teal-dark1,
.text-teal-dark1-focus:focus,
.focus-container:focus .text-teal-dark1-focus,
.shopperLand a.text-teal-dark1,
.text-teal-dark1 a,
.shopperLand .text-teal-dark1 a {
	color: #012524;
	fill: #012524
}
.text-teal-dark1.link:hover,
a.text-teal-dark1.link:hover,
.text-teal-dark1-focus:focus.link:hover,
.focus-container:focus .text-teal-dark1-focus.link:hover,
.shopperLand a.text-teal-dark1.link:hover,
.text-teal-dark1 a.link:hover,
.shopperLand .text-teal-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-teal-dark1.understroke,
a.text-teal-dark1.understroke,
.text-teal-dark1-focus:focus.understroke,
.focus-container:focus .text-teal-dark1-focus.understroke,
.shopperLand a.text-teal-dark1.understroke,
.text-teal-dark1 a.understroke,
.shopperLand .text-teal-dark1 a.understroke {
	border-color: #012524
}
.text-teal-dark1.understroke.link:hover,
a.text-teal-dark1.understroke.link:hover,
.text-teal-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-teal-dark1-focus.understroke.link:hover,
.shopperLand a.text-teal-dark1.understroke.link:hover,
.text-teal-dark1 a.understroke.link:hover,
.shopperLand .text-teal-dark1 a.understroke.link:hover,
.text-teal-dark1.understroke.link.active,
a.text-teal-dark1.understroke.link.active,
.text-teal-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-teal-dark1-focus.understroke.link.active,
.shopperLand a.text-teal-dark1.understroke.link.active,
.text-teal-dark1 a.understroke.link.active,
.shopperLand .text-teal-dark1 a.understroke.link.active {
	border-color: #000
}
.text-green-dark1,
a.text-green-dark1,
.text-green-dark1-focus:focus,
.focus-container:focus .text-green-dark1-focus,
.shopperLand a.text-green-dark1,
.text-green-dark1 a,
.shopperLand .text-green-dark1 a {
	color: #0b1d05;
	fill: #0b1d05
}
.text-green-dark1.link:hover,
a.text-green-dark1.link:hover,
.text-green-dark1-focus:focus.link:hover,
.focus-container:focus .text-green-dark1-focus.link:hover,
.shopperLand a.text-green-dark1.link:hover,
.text-green-dark1 a.link:hover,
.shopperLand .text-green-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-green-dark1.understroke,
a.text-green-dark1.understroke,
.text-green-dark1-focus:focus.understroke,
.focus-container:focus .text-green-dark1-focus.understroke,
.shopperLand a.text-green-dark1.understroke,
.text-green-dark1 a.understroke,
.shopperLand .text-green-dark1 a.understroke {
	border-color: #0b1d05
}
.text-green-dark1.understroke.link:hover,
a.text-green-dark1.understroke.link:hover,
.text-green-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-green-dark1-focus.understroke.link:hover,
.shopperLand a.text-green-dark1.understroke.link:hover,
.text-green-dark1 a.understroke.link:hover,
.shopperLand .text-green-dark1 a.understroke.link:hover,
.text-green-dark1.understroke.link.active,
a.text-green-dark1.understroke.link.active,
.text-green-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-green-dark1-focus.understroke.link.active,
.shopperLand a.text-green-dark1.understroke.link.active,
.text-green-dark1 a.understroke.link.active,
.shopperLand .text-green-dark1 a.understroke.link.active {
	border-color: #000
}
.text-gray-dark1,
a.text-gray-dark1,
.text-gray-dark1-focus:focus,
.focus-container:focus .text-gray-dark1-focus,
.shopperLand a.text-gray-dark1,
.text-gray-dark1 a,
.shopperLand .text-gray-dark1 a {
	color: #040404;
	fill: #040404
}
.text-gray-dark1.link:hover,
a.text-gray-dark1.link:hover,
.text-gray-dark1-focus:focus.link:hover,
.focus-container:focus .text-gray-dark1-focus.link:hover,
.shopperLand a.text-gray-dark1.link:hover,
.text-gray-dark1 a.link:hover,
.shopperLand .text-gray-dark1 a.link:hover {
	color: #000;
	fill: #000
}
.text-gray-dark1.understroke,
a.text-gray-dark1.understroke,
.text-gray-dark1-focus:focus.understroke,
.focus-container:focus .text-gray-dark1-focus.understroke,
.shopperLand a.text-gray-dark1.understroke,
.text-gray-dark1 a.understroke,
.shopperLand .text-gray-dark1 a.understroke {
	border-color: #040404
}
.text-gray-dark1.understroke.link:hover,
a.text-gray-dark1.understroke.link:hover,
.text-gray-dark1-focus:focus.understroke.link:hover,
.focus-container:focus .text-gray-dark1-focus.understroke.link:hover,
.shopperLand a.text-gray-dark1.understroke.link:hover,
.text-gray-dark1 a.understroke.link:hover,
.shopperLand .text-gray-dark1 a.understroke.link:hover,
.text-gray-dark1.understroke.link.active,
a.text-gray-dark1.understroke.link.active,
.text-gray-dark1-focus:focus.understroke.link.active,
.focus-container:focus .text-gray-dark1-focus.understroke.link.active,
.shopperLand a.text-gray-dark1.understroke.link.active,
.text-gray-dark1 a.understroke.link.active,
.shopperLand .text-gray-dark1 a.understroke.link.active {
	border-color: #000
}
.text-yellow-light2,
a.text-yellow-light2,
.text-yellow-light2-focus:focus,
.focus-container:focus .text-yellow-light2-focus,
.shopperLand a.text-yellow-light2,
.text-yellow-light2 a,
.shopperLand .text-yellow-light2 a {
	color: #ffeab6;
	fill: #ffeab6
}
.text-yellow-light2.link:hover,
a.text-yellow-light2.link:hover,
.text-yellow-light2-focus:focus.link:hover,
.focus-container:focus .text-yellow-light2-focus.link:hover,
.shopperLand a.text-yellow-light2.link:hover,
.text-yellow-light2 a.link:hover,
.shopperLand .text-yellow-light2 a.link:hover {
	color: #ffcd50;
	fill: #ffcd50
}
.text-yellow-light2.understroke,
a.text-yellow-light2.understroke,
.text-yellow-light2-focus:focus.understroke,
.focus-container:focus .text-yellow-light2-focus.understroke,
.shopperLand a.text-yellow-light2.understroke,
.text-yellow-light2 a.understroke,
.shopperLand .text-yellow-light2 a.understroke {
	border-color: #ffeab6
}
.text-yellow-light2.understroke.link:hover,
a.text-yellow-light2.understroke.link:hover,
.text-yellow-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-yellow-light2-focus.understroke.link:hover,
.shopperLand a.text-yellow-light2.understroke.link:hover,
.text-yellow-light2 a.understroke.link:hover,
.shopperLand .text-yellow-light2 a.understroke.link:hover,
.text-yellow-light2.understroke.link.active,
a.text-yellow-light2.understroke.link.active,
.text-yellow-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-yellow-light2-focus.understroke.link.active,
.shopperLand a.text-yellow-light2.understroke.link.active,
.text-yellow-light2 a.understroke.link.active,
.shopperLand .text-yellow-light2 a.understroke.link.active {
	border-color: #ffcd50
}
.text-orange-light2,
a.text-orange-light2,
.text-orange-light2-focus:focus,
.focus-container:focus .text-orange-light2-focus,
.shopperLand a.text-orange-light2,
.text-orange-light2 a,
.shopperLand .text-orange-light2 a {
	color: #fee2d5;
	fill: #fee2d5
}
.text-orange-light2.link:hover,
a.text-orange-light2.link:hover,
.text-orange-light2-focus:focus.link:hover,
.focus-container:focus .text-orange-light2-focus.link:hover,
.shopperLand a.text-orange-light2.link:hover,
.text-orange-light2 a.link:hover,
.shopperLand .text-orange-light2 a.link:hover {
	color: #fc9d71;
	fill: #fc9d71
}
.text-orange-light2.understroke,
a.text-orange-light2.understroke,
.text-orange-light2-focus:focus.understroke,
.focus-container:focus .text-orange-light2-focus.understroke,
.shopperLand a.text-orange-light2.understroke,
.text-orange-light2 a.understroke,
.shopperLand .text-orange-light2 a.understroke {
	border-color: #fee2d5
}
.text-orange-light2.understroke.link:hover,
a.text-orange-light2.understroke.link:hover,
.text-orange-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-orange-light2-focus.understroke.link:hover,
.shopperLand a.text-orange-light2.understroke.link:hover,
.text-orange-light2 a.understroke.link:hover,
.shopperLand .text-orange-light2 a.understroke.link:hover,
.text-orange-light2.understroke.link.active,
a.text-orange-light2.understroke.link.active,
.text-orange-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-orange-light2-focus.understroke.link.active,
.shopperLand a.text-orange-light2.understroke.link.active,
.text-orange-light2 a.understroke.link.active,
.shopperLand .text-orange-light2 a.understroke.link.active {
	border-color: #fc9d71
}
.text-red-light2,
a.text-red-light2,
.text-red-light2-focus:focus,
.focus-container:focus .text-red-light2-focus,
.shopperLand a.text-red-light2,
.text-red-light2 a,
.shopperLand .text-red-light2 a {
	color: #ffdce5;
	fill: #ffdce5
}
.text-red-light2.link:hover,
a.text-red-light2.link:hover,
.text-red-light2-focus:focus.link:hover,
.focus-container:focus .text-red-light2-focus.link:hover,
.shopperLand a.text-red-light2.link:hover,
.text-red-light2 a.link:hover,
.shopperLand .text-red-light2 a.link:hover {
	color: #ff7699;
	fill: #ff7699
}
.text-red-light2.understroke,
a.text-red-light2.understroke,
.text-red-light2-focus:focus.understroke,
.focus-container:focus .text-red-light2-focus.understroke,
.shopperLand a.text-red-light2.understroke,
.text-red-light2 a.understroke,
.shopperLand .text-red-light2 a.understroke {
	border-color: #ffdce5
}
.text-red-light2.understroke.link:hover,
a.text-red-light2.understroke.link:hover,
.text-red-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-red-light2-focus.understroke.link:hover,
.shopperLand a.text-red-light2.understroke.link:hover,
.text-red-light2 a.understroke.link:hover,
.shopperLand .text-red-light2 a.understroke.link:hover,
.text-red-light2.understroke.link.active,
a.text-red-light2.understroke.link.active,
.text-red-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-red-light2-focus.understroke.link.active,
.shopperLand a.text-red-light2.understroke.link.active,
.text-red-light2 a.understroke.link.active,
.shopperLand .text-red-light2 a.understroke.link.active {
	border-color: #ff7699
}
.text-pink-light2,
a.text-pink-light2,
.text-pink-light2-focus:focus,
.focus-container:focus .text-pink-light2-focus,
.shopperLand a.text-pink-light2,
.text-pink-light2 a,
.shopperLand .text-pink-light2 a {
	color: #ffdaf6;
	fill: #ffdaf6
}
.text-pink-light2.link:hover,
a.text-pink-light2.link:hover,
.text-pink-light2-focus:focus.link:hover,
.focus-container:focus .text-pink-light2-focus.link:hover,
.shopperLand a.text-pink-light2.link:hover,
.text-pink-light2 a.link:hover,
.shopperLand .text-pink-light2 a.link:hover {
	color: #ff74dd;
	fill: #ff74dd
}
.text-pink-light2.understroke,
a.text-pink-light2.understroke,
.text-pink-light2-focus:focus.understroke,
.focus-container:focus .text-pink-light2-focus.understroke,
.shopperLand a.text-pink-light2.understroke,
.text-pink-light2 a.understroke,
.shopperLand .text-pink-light2 a.understroke {
	border-color: #ffdaf6
}
.text-pink-light2.understroke.link:hover,
a.text-pink-light2.understroke.link:hover,
.text-pink-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-pink-light2-focus.understroke.link:hover,
.shopperLand a.text-pink-light2.understroke.link:hover,
.text-pink-light2 a.understroke.link:hover,
.shopperLand .text-pink-light2 a.understroke.link:hover,
.text-pink-light2.understroke.link.active,
a.text-pink-light2.understroke.link.active,
.text-pink-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-pink-light2-focus.understroke.link.active,
.shopperLand a.text-pink-light2.understroke.link.active,
.text-pink-light2 a.understroke.link.active,
.shopperLand .text-pink-light2 a.understroke.link.active {
	border-color: #ff74dd
}
.text-purple-light2,
a.text-purple-light2,
.text-purple-light2-focus:focus,
.focus-container:focus .text-purple-light2-focus,
.shopperLand a.text-purple-light2,
.text-purple-light2 a,
.shopperLand .text-purple-light2 a {
	color: #ede2fe;
	fill: #ede2fe
}
.text-purple-light2.link:hover,
a.text-purple-light2.link:hover,
.text-purple-light2-focus:focus.link:hover,
.focus-container:focus .text-purple-light2-focus.link:hover,
.shopperLand a.text-purple-light2.link:hover,
.text-purple-light2 a.link:hover,
.shopperLand .text-purple-light2 a.link:hover {
	color: #b07ffb;
	fill: #b07ffb
}
.text-purple-light2.understroke,
a.text-purple-light2.understroke,
.text-purple-light2-focus:focus.understroke,
.focus-container:focus .text-purple-light2-focus.understroke,
.shopperLand a.text-purple-light2.understroke,
.text-purple-light2 a.understroke,
.shopperLand .text-purple-light2 a.understroke {
	border-color: #ede2fe
}
.text-purple-light2.understroke.link:hover,
a.text-purple-light2.understroke.link:hover,
.text-purple-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-purple-light2-focus.understroke.link:hover,
.shopperLand a.text-purple-light2.understroke.link:hover,
.text-purple-light2 a.understroke.link:hover,
.shopperLand .text-purple-light2 a.understroke.link:hover,
.text-purple-light2.understroke.link.active,
a.text-purple-light2.understroke.link.active,
.text-purple-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-purple-light2-focus.understroke.link.active,
.shopperLand a.text-purple-light2.understroke.link.active,
.text-purple-light2 a.understroke.link.active,
.shopperLand .text-purple-light2 a.understroke.link.active {
	border-color: #b07ffb
}
.text-blue-light2,
a.text-blue-light2,
.text-blue-light2-focus:focus,
.focus-container:focus .text-blue-light2-focus,
.shopperLand a.text-blue-light2,
.text-blue-light2 a,
.shopperLand .text-blue-light2 a {
	color: #cfdfff;
	fill: #cfdfff
}
.text-blue-light2.link:hover,
a.text-blue-light2.link:hover,
.text-blue-light2-focus:focus.link:hover,
.focus-container:focus .text-blue-light2-focus.link:hover,
.shopperLand a.text-blue-light2.link:hover,
.text-blue-light2 a.link:hover,
.shopperLand .text-blue-light2 a.link:hover {
	color: #699bff;
	fill: #699bff
}
.text-blue-light2.understroke,
a.text-blue-light2.understroke,
.text-blue-light2-focus:focus.understroke,
.focus-container:focus .text-blue-light2-focus.understroke,
.shopperLand a.text-blue-light2.understroke,
.text-blue-light2 a.understroke,
.shopperLand .text-blue-light2 a.understroke {
	border-color: #cfdfff
}
.text-blue-light2.understroke.link:hover,
a.text-blue-light2.understroke.link:hover,
.text-blue-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-blue-light2-focus.understroke.link:hover,
.shopperLand a.text-blue-light2.understroke.link:hover,
.text-blue-light2 a.understroke.link:hover,
.shopperLand .text-blue-light2 a.understroke.link:hover,
.text-blue-light2.understroke.link.active,
a.text-blue-light2.understroke.link.active,
.text-blue-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-blue-light2-focus.understroke.link.active,
.shopperLand a.text-blue-light2.understroke.link.active,
.text-blue-light2 a.understroke.link.active,
.shopperLand .text-blue-light2 a.understroke.link.active {
	border-color: #699bff
}
.text-cyan-light2,
a.text-cyan-light2,
.text-cyan-light2-focus:focus,
.focus-container:focus .text-cyan-light2-focus,
.shopperLand a.text-cyan-light2,
.text-cyan-light2 a,
.shopperLand .text-cyan-light2 a {
	color: #d0f0fd;
	fill: #d0f0fd
}
.text-cyan-light2.link:hover,
a.text-cyan-light2.link:hover,
.text-cyan-light2-focus:focus.link:hover,
.focus-container:focus .text-cyan-light2-focus.link:hover,
.shopperLand a.text-cyan-light2.link:hover,
.text-cyan-light2 a.link:hover,
.shopperLand .text-cyan-light2 a.link:hover {
	color: #6ed1f9;
	fill: #6ed1f9
}
.text-cyan-light2.understroke,
a.text-cyan-light2.understroke,
.text-cyan-light2-focus:focus.understroke,
.focus-container:focus .text-cyan-light2-focus.understroke,
.shopperLand a.text-cyan-light2.understroke,
.text-cyan-light2 a.understroke,
.shopperLand .text-cyan-light2 a.understroke {
	border-color: #d0f0fd
}
.text-cyan-light2.understroke.link:hover,
a.text-cyan-light2.understroke.link:hover,
.text-cyan-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-cyan-light2-focus.understroke.link:hover,
.shopperLand a.text-cyan-light2.understroke.link:hover,
.text-cyan-light2 a.understroke.link:hover,
.shopperLand .text-cyan-light2 a.understroke.link:hover,
.text-cyan-light2.understroke.link.active,
a.text-cyan-light2.understroke.link.active,
.text-cyan-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-cyan-light2-focus.understroke.link.active,
.shopperLand a.text-cyan-light2.understroke.link.active,
.text-cyan-light2 a.understroke.link.active,
.shopperLand .text-cyan-light2 a.understroke.link.active {
	border-color: #6ed1f9
}
.text-teal-light2,
a.text-teal-light2,
.text-teal-light2-focus:focus,
.focus-container:focus .text-teal-light2-focus,
.shopperLand a.text-teal-light2,
.text-teal-light2 a,
.shopperLand .text-teal-light2 a {
	color: #c2f5e9;
	fill: #c2f5e9
}
.text-teal-light2.link:hover,
a.text-teal-light2.link:hover,
.text-teal-light2-focus:focus.link:hover,
.focus-container:focus .text-teal-light2-focus.link:hover,
.shopperLand a.text-teal-light2.link:hover,
.text-teal-light2 a.link:hover,
.shopperLand .text-teal-light2 a.link:hover {
	color: #6ae7c9;
	fill: #6ae7c9
}
.text-teal-light2.understroke,
a.text-teal-light2.understroke,
.text-teal-light2-focus:focus.understroke,
.focus-container:focus .text-teal-light2-focus.understroke,
.shopperLand a.text-teal-light2.understroke,
.text-teal-light2 a.understroke,
.shopperLand .text-teal-light2 a.understroke {
	border-color: #c2f5e9
}
.text-teal-light2.understroke.link:hover,
a.text-teal-light2.understroke.link:hover,
.text-teal-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-teal-light2-focus.understroke.link:hover,
.shopperLand a.text-teal-light2.understroke.link:hover,
.text-teal-light2 a.understroke.link:hover,
.shopperLand .text-teal-light2 a.understroke.link:hover,
.text-teal-light2.understroke.link.active,
a.text-teal-light2.understroke.link.active,
.text-teal-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-teal-light2-focus.understroke.link.active,
.shopperLand a.text-teal-light2.understroke.link.active,
.text-teal-light2 a.understroke.link.active,
.shopperLand .text-teal-light2 a.understroke.link.active {
	border-color: #6ae7c9
}
.text-green-light2,
a.text-green-light2,
.text-green-light2-focus:focus,
.focus-container:focus .text-green-light2-focus,
.shopperLand a.text-green-light2,
.text-green-light2 a,
.shopperLand .text-green-light2 a {
	color: #d1f7c4;
	fill: #d1f7c4
}
.text-green-light2.link:hover,
a.text-green-light2.link:hover,
.text-green-light2-focus:focus.link:hover,
.focus-container:focus .text-green-light2-focus.link:hover,
.shopperLand a.text-green-light2.link:hover,
.text-green-light2 a.link:hover,
.shopperLand .text-green-light2 a.link:hover {
	color: #8beb6a;
	fill: #8beb6a
}
.text-green-light2.understroke,
a.text-green-light2.understroke,
.text-green-light2-focus:focus.understroke,
.focus-container:focus .text-green-light2-focus.understroke,
.shopperLand a.text-green-light2.understroke,
.text-green-light2 a.understroke,
.shopperLand .text-green-light2 a.understroke {
	border-color: #d1f7c4
}
.text-green-light2.understroke.link:hover,
a.text-green-light2.understroke.link:hover,
.text-green-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-green-light2-focus.understroke.link:hover,
.shopperLand a.text-green-light2.understroke.link:hover,
.text-green-light2 a.understroke.link:hover,
.shopperLand .text-green-light2 a.understroke.link:hover,
.text-green-light2.understroke.link.active,
a.text-green-light2.understroke.link.active,
.text-green-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-green-light2-focus.understroke.link.active,
.shopperLand a.text-green-light2.understroke.link.active,
.text-green-light2 a.understroke.link.active,
.shopperLand .text-green-light2 a.understroke.link.active {
	border-color: #8beb6a
}
.text-gray-light2,
a.text-gray-light2,
.text-gray-light2-focus:focus,
.focus-container:focus .text-gray-light2-focus,
.shopperLand a.text-gray-light2,
.text-gray-light2 a,
.shopperLand .text-gray-light2 a {
	color: #eee;
	fill: #eee
}
.text-gray-light2.link:hover,
a.text-gray-light2.link:hover,
.text-gray-light2-focus:focus.link:hover,
.focus-container:focus .text-gray-light2-focus.link:hover,
.shopperLand a.text-gray-light2.link:hover,
.text-gray-light2 a.link:hover,
.shopperLand .text-gray-light2 a.link:hover {
	color: #bbb;
	fill: #bbb
}
.text-gray-light2.understroke,
a.text-gray-light2.understroke,
.text-gray-light2-focus:focus.understroke,
.focus-container:focus .text-gray-light2-focus.understroke,
.shopperLand a.text-gray-light2.understroke,
.text-gray-light2 a.understroke,
.shopperLand .text-gray-light2 a.understroke {
	border-color: #eee
}
.text-gray-light2.understroke.link:hover,
a.text-gray-light2.understroke.link:hover,
.text-gray-light2-focus:focus.understroke.link:hover,
.focus-container:focus .text-gray-light2-focus.understroke.link:hover,
.shopperLand a.text-gray-light2.understroke.link:hover,
.text-gray-light2 a.understroke.link:hover,
.shopperLand .text-gray-light2 a.understroke.link:hover,
.text-gray-light2.understroke.link.active,
a.text-gray-light2.understroke.link.active,
.text-gray-light2-focus:focus.understroke.link.active,
.focus-container:focus .text-gray-light2-focus.understroke.link.active,
.shopperLand a.text-gray-light2.understroke.link.active,
.text-gray-light2 a.understroke.link.active,
.shopperLand .text-gray-light2 a.understroke.link.active {
	border-color: #bbb
}
.text-darken1,
a.text-darken1,
.text-darken1-focus:focus,
.focus-container:focus .text-darken1-focus,
.shopperLand a.text-darken1,
.text-darken1 a,
.shopperLand .text-darken1 a {
	color: hsla(0, 0%, 0%, 0.05);
	fill: hsla(0, 0%, 0%, 0.05)
}
.text-darken1.link:hover,
a.text-darken1.link:hover,
.text-darken1-focus:focus.link:hover,
.focus-container:focus .text-darken1-focus.link:hover,
.shopperLand a.text-darken1.link:hover,
.text-darken1 a.link:hover,
.shopperLand .text-darken1 a.link:hover {
	color: hsla(0, 0%, 0%, 0.05);
	fill: hsla(0, 0%, 0%, 0.05)
}
.text-darken1.understroke,
a.text-darken1.understroke,
.text-darken1-focus:focus.understroke,
.focus-container:focus .text-darken1-focus.understroke,
.shopperLand a.text-darken1.understroke,
.text-darken1 a.understroke,
.shopperLand .text-darken1 a.understroke {
	border-color: hsla(0, 0%, 0%, 0.05)
}
.text-darken1.understroke.link:hover,
a.text-darken1.understroke.link:hover,
.text-darken1-focus:focus.understroke.link:hover,
.focus-container:focus .text-darken1-focus.understroke.link:hover,
.shopperLand a.text-darken1.understroke.link:hover,
.text-darken1 a.understroke.link:hover,
.shopperLand .text-darken1 a.understroke.link:hover,
.text-darken1.understroke.link.active,
a.text-darken1.understroke.link.active,
.text-darken1-focus:focus.understroke.link.active,
.focus-container:focus .text-darken1-focus.understroke.link.active,
.shopperLand a.text-darken1.understroke.link.active,
.text-darken1 a.understroke.link.active,
.shopperLand .text-darken1 a.understroke.link.active {
	border-color: hsla(0, 0%, 0%, 0.05)
}
.text-darken2,
a.text-darken2,
.text-darken2-focus:focus,
.focus-container:focus .text-darken2-focus,
.shopperLand a.text-darken2,
.text-darken2 a,
.shopperLand .text-darken2 a {
	color: hsla(0, 0%, 0%, 0.1);
	fill: hsla(0, 0%, 0%, 0.1)
}
.text-darken2.link:hover,
a.text-darken2.link:hover,
.text-darken2-focus:focus.link:hover,
.focus-container:focus .text-darken2-focus.link:hover,
.shopperLand a.text-darken2.link:hover,
.text-darken2 a.link:hover,
.shopperLand .text-darken2 a.link:hover {
	color: hsla(0, 0%, 0%, 0.1);
	fill: hsla(0, 0%, 0%, 0.1)
}
.text-darken2.understroke,
a.text-darken2.understroke,
.text-darken2-focus:focus.understroke,
.focus-container:focus .text-darken2-focus.understroke,
.shopperLand a.text-darken2.understroke,
.text-darken2 a.understroke,
.shopperLand .text-darken2 a.understroke {
	border-color: hsla(0, 0%, 0%, 0.1)
}
.text-darken2.understroke.link:hover,
a.text-darken2.understroke.link:hover,
.text-darken2-focus:focus.understroke.link:hover,
.focus-container:focus .text-darken2-focus.understroke.link:hover,
.shopperLand a.text-darken2.understroke.link:hover,
.text-darken2 a.understroke.link:hover,
.shopperLand .text-darken2 a.understroke.link:hover,
.text-darken2.understroke.link.active,
a.text-darken2.understroke.link.active,
.text-darken2-focus:focus.understroke.link.active,
.focus-container:focus .text-darken2-focus.understroke.link.active,
.shopperLand a.text-darken2.understroke.link.active,
.text-darken2 a.understroke.link.active,
.shopperLand .text-darken2 a.understroke.link.active {
	border-color: hsla(0, 0%, 0%, 0.1)
}
.text-darken3,
a.text-darken3,
.text-darken3-focus:focus,
.focus-container:focus .text-darken3-focus,
.shopperLand a.text-darken3,
.text-darken3 a,
.shopperLand .text-darken3 a {
	color: hsla(0, 0%, 0%, 0.25);
	fill: hsla(0, 0%, 0%, 0.25)
}
.text-darken3.link:hover,
a.text-darken3.link:hover,
.text-darken3-focus:focus.link:hover,
.focus-container:focus .text-darken3-focus.link:hover,
.shopperLand a.text-darken3.link:hover,
.text-darken3 a.link:hover,
.shopperLand .text-darken3 a.link:hover {
	color: hsla(0, 0%, 0%, 0.25);
	fill: hsla(0, 0%, 0%, 0.25)
}
.text-darken3.understroke,
a.text-darken3.understroke,
.text-darken3-focus:focus.understroke,
.focus-container:focus .text-darken3-focus.understroke,
.shopperLand a.text-darken3.understroke,
.text-darken3 a.understroke,
.shopperLand .text-darken3 a.understroke {
	border-color: hsla(0, 0%, 0%, 0.25)
}
.text-darken3.understroke.link:hover,
a.text-darken3.understroke.link:hover,
.text-darken3-focus:focus.understroke.link:hover,
.focus-container:focus .text-darken3-focus.understroke.link:hover,
.shopperLand a.text-darken3.understroke.link:hover,
.text-darken3 a.understroke.link:hover,
.shopperLand .text-darken3 a.understroke.link:hover,
.text-darken3.understroke.link.active,
a.text-darken3.understroke.link.active,
.text-darken3-focus:focus.understroke.link.active,
.focus-container:focus .text-darken3-focus.understroke.link.active,
.shopperLand a.text-darken3.understroke.link.active,
.text-darken3 a.understroke.link.active,
.shopperLand .text-darken3 a.understroke.link.active {
	border-color: hsla(0, 0%, 0%, 0.25)
}
.text-darken4,
a.text-darken4,
.text-darken4-focus:focus,
.focus-container:focus .text-darken4-focus,
.shopperLand a.text-darken4,
.text-darken4 a,
.shopperLand .text-darken4 a {
	color: hsla(0, 0%, 0%, 0.5);
	fill: hsla(0, 0%, 0%, 0.5)
}
.text-darken4.link:hover,
a.text-darken4.link:hover,
.text-darken4-focus:focus.link:hover,
.focus-container:focus .text-darken4-focus.link:hover,
.shopperLand a.text-darken4.link:hover,
.text-darken4 a.link:hover,
.shopperLand .text-darken4 a.link:hover {
	color: hsla(0, 0%, 0%, 0.5);
	fill: hsla(0, 0%, 0%, 0.5)
}
.text-darken4.understroke,
a.text-darken4.understroke,
.text-darken4-focus:focus.understroke,
.focus-container:focus .text-darken4-focus.understroke,
.shopperLand a.text-darken4.understroke,
.text-darken4 a.understroke,
.shopperLand .text-darken4 a.understroke {
	border-color: hsla(0, 0%, 0%, 0.5)
}
.text-darken4.understroke.link:hover,
a.text-darken4.understroke.link:hover,
.text-darken4-focus:focus.understroke.link:hover,
.focus-container:focus .text-darken4-focus.understroke.link:hover,
.shopperLand a.text-darken4.understroke.link:hover,
.text-darken4 a.understroke.link:hover,
.shopperLand .text-darken4 a.understroke.link:hover,
.text-darken4.understroke.link.active,
a.text-darken4.understroke.link.active,
.text-darken4-focus:focus.understroke.link.active,
.focus-container:focus .text-darken4-focus.understroke.link.active,
.shopperLand a.text-darken4.understroke.link.active,
.text-darken4 a.understroke.link.active,
.shopperLand .text-darken4 a.understroke.link.active {
	border-color: hsla(0, 0%, 0%, 0.5)
}
.text-lighten1,
a.text-lighten1,
.text-lighten1-focus:focus,
.focus-container:focus .text-lighten1-focus,
.shopperLand a.text-lighten1,
.text-lighten1 a,
.shopperLand .text-lighten1 a {
	color: hsla(0, 0%, 100%, 0.05);
	fill: hsla(0, 0%, 100%, 0.05)
}
.text-lighten1.link:hover,
a.text-lighten1.link:hover,
.text-lighten1-focus:focus.link:hover,
.focus-container:focus .text-lighten1-focus.link:hover,
.shopperLand a.text-lighten1.link:hover,
.text-lighten1 a.link:hover,
.shopperLand .text-lighten1 a.link:hover {
	color: hsla(0, 0%, 80%, 0.05);
	fill: hsla(0, 0%, 80%, 0.05)
}
.text-lighten1.understroke,
a.text-lighten1.understroke,
.text-lighten1-focus:focus.understroke,
.focus-container:focus .text-lighten1-focus.understroke,
.shopperLand a.text-lighten1.understroke,
.text-lighten1 a.understroke,
.shopperLand .text-lighten1 a.understroke {
	border-color: hsla(0, 0%, 100%, 0.05)
}
.text-lighten1.understroke.link:hover,
a.text-lighten1.understroke.link:hover,
.text-lighten1-focus:focus.understroke.link:hover,
.focus-container:focus .text-lighten1-focus.understroke.link:hover,
.shopperLand a.text-lighten1.understroke.link:hover,
.text-lighten1 a.understroke.link:hover,
.shopperLand .text-lighten1 a.understroke.link:hover,
.text-lighten1.understroke.link.active,
a.text-lighten1.understroke.link.active,
.text-lighten1-focus:focus.understroke.link.active,
.focus-container:focus .text-lighten1-focus.understroke.link.active,
.shopperLand a.text-lighten1.understroke.link.active,
.text-lighten1 a.understroke.link.active,
.shopperLand .text-lighten1 a.understroke.link.active {
	border-color: hsla(0, 0%, 80%, 0.05)
}
.text-lighten2,
a.text-lighten2,
.text-lighten2-focus:focus,
.focus-container:focus .text-lighten2-focus,
.shopperLand a.text-lighten2,
.text-lighten2 a,
.shopperLand .text-lighten2 a {
	color: hsla(0, 0%, 100%, 0.1);
	fill: hsla(0, 0%, 100%, 0.1)
}
.text-lighten2.link:hover,
a.text-lighten2.link:hover,
.text-lighten2-focus:focus.link:hover,
.focus-container:focus .text-lighten2-focus.link:hover,
.shopperLand a.text-lighten2.link:hover,
.text-lighten2 a.link:hover,
.shopperLand .text-lighten2 a.link:hover {
	color: hsla(0, 0%, 80%, 0.1);
	fill: hsla(0, 0%, 80%, 0.1)
}
.text-lighten2.understroke,
a.text-lighten2.understroke,
.text-lighten2-focus:focus.understroke,
.focus-container:focus .text-lighten2-focus.understroke,
.shopperLand a.text-lighten2.understroke,
.text-lighten2 a.understroke,
.shopperLand .text-lighten2 a.understroke {
	border-color: hsla(0, 0%, 100%, 0.1)
}
.text-lighten2.understroke.link:hover,
a.text-lighten2.understroke.link:hover,
.text-lighten2-focus:focus.understroke.link:hover,
.focus-container:focus .text-lighten2-focus.understroke.link:hover,
.shopperLand a.text-lighten2.understroke.link:hover,
.text-lighten2 a.understroke.link:hover,
.shopperLand .text-lighten2 a.understroke.link:hover,
.text-lighten2.understroke.link.active,
a.text-lighten2.understroke.link.active,
.text-lighten2-focus:focus.understroke.link.active,
.focus-container:focus .text-lighten2-focus.understroke.link.active,
.shopperLand a.text-lighten2.understroke.link.active,
.text-lighten2 a.understroke.link.active,
.shopperLand .text-lighten2 a.understroke.link.active {
	border-color: hsla(0, 0%, 80%, 0.1)
}
.text-lighten3,
a.text-lighten3,
.text-lighten3-focus:focus,
.focus-container:focus .text-lighten3-focus,
.shopperLand a.text-lighten3,
.text-lighten3 a,
.shopperLand .text-lighten3 a {
	color: hsla(0, 0%, 100%, 0.25);
	fill: hsla(0, 0%, 100%, 0.25)
}
.text-lighten3.link:hover,
a.text-lighten3.link:hover,
.text-lighten3-focus:focus.link:hover,
.focus-container:focus .text-lighten3-focus.link:hover,
.shopperLand a.text-lighten3.link:hover,
.text-lighten3 a.link:hover,
.shopperLand .text-lighten3 a.link:hover {
	color: hsla(0, 0%, 80%, 0.25);
	fill: hsla(0, 0%, 80%, 0.25)
}
.text-lighten3.understroke,
a.text-lighten3.understroke,
.text-lighten3-focus:focus.understroke,
.focus-container:focus .text-lighten3-focus.understroke,
.shopperLand a.text-lighten3.understroke,
.text-lighten3 a.understroke,
.shopperLand .text-lighten3 a.understroke {
	border-color: hsla(0, 0%, 100%, 0.25)
}
.text-lighten3.understroke.link:hover,
a.text-lighten3.understroke.link:hover,
.text-lighten3-focus:focus.understroke.link:hover,
.focus-container:focus .text-lighten3-focus.understroke.link:hover,
.shopperLand a.text-lighten3.understroke.link:hover,
.text-lighten3 a.understroke.link:hover,
.shopperLand .text-lighten3 a.understroke.link:hover,
.text-lighten3.understroke.link.active,
a.text-lighten3.understroke.link.active,
.text-lighten3-focus:focus.understroke.link.active,
.focus-container:focus .text-lighten3-focus.understroke.link.active,
.shopperLand a.text-lighten3.understroke.link.active,
.text-lighten3 a.understroke.link.active,
.shopperLand .text-lighten3 a.understroke.link.active {
	border-color: hsla(0, 0%, 80%, 0.25)
}
.text-lighten4,
a.text-lighten4,
.text-lighten4-focus:focus,
.focus-container:focus .text-lighten4-focus,
.shopperLand a.text-lighten4,
.text-lighten4 a,
.shopperLand .text-lighten4 a {
	color: hsla(0, 0%, 100%, 0.5);
	fill: hsla(0, 0%, 100%, 0.5)
}
.text-lighten4.link:hover,
a.text-lighten4.link:hover,
.text-lighten4-focus:focus.link:hover,
.focus-container:focus .text-lighten4-focus.link:hover,
.shopperLand a.text-lighten4.link:hover,
.text-lighten4 a.link:hover,
.shopperLand .text-lighten4 a.link:hover {
	color: hsla(0, 0%, 80%, 0.5);
	fill: hsla(0, 0%, 80%, 0.5)
}
.text-lighten4.understroke,
a.text-lighten4.understroke,
.text-lighten4-focus:focus.understroke,
.focus-container:focus .text-lighten4-focus.understroke,
.shopperLand a.text-lighten4.understroke,
.text-lighten4 a.understroke,
.shopperLand .text-lighten4 a.understroke {
	border-color: hsla(0, 0%, 100%, 0.5)
}
.text-lighten4.understroke.link:hover,
a.text-lighten4.understroke.link:hover,
.text-lighten4-focus:focus.understroke.link:hover,
.focus-container:focus .text-lighten4-focus.understroke.link:hover,
.shopperLand a.text-lighten4.understroke.link:hover,
.text-lighten4 a.understroke.link:hover,
.shopperLand .text-lighten4 a.understroke.link:hover,
.text-lighten4.understroke.link.active,
a.text-lighten4.understroke.link.active,
.text-lighten4-focus:focus.understroke.link.active,
.focus-container:focus .text-lighten4-focus.understroke.link.active,
.shopperLand a.text-lighten4.understroke.link.active,
.text-lighten4 a.understroke.link.active,
.shopperLand .text-lighten4 a.understroke.link.active {
	border-color: hsla(0, 0%, 80%, 0.5)
}
.animate {
	transition: .085s all ease-in
}
.animate-slow {
	transition: .25s all ease-in
}
.animate-slower {
	transition: .4s all ease-in
}
.animate-ease-in-out {
	transition-timing-function: ease-in-out
}
.animate-infinite {
	animation-iteration-count: infinite
}
.animate-background-color {
	transition: .085s background-color ease-in
}
.animate-spin-scale {
	animation-name: spinScale;
	animation-duration: 1800ms;
	animation-timing-function: cubic-bezier(.785, .135, .15, .86)
}
.animate-bounce-in {
	animation-name: bounceIn;
	animation-duration: 240ms
}
.animate-bounce-in-loud {
	animation-name: bounceInLoud;
	animation-duration: 240ms
}
.animate-opacity-fade-in {
	animation-name: opacityFadeIn;
	animation-duration: 400ms
}
.animate-fade-up-in {
	animation-name: fadeUpIn;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out
}
.animate-spin-slow {
	animation-name: spin;
	animation-duration: 40s;
	animation-timing-function: linear
}
.animate-highlight {
	animation-name: highlight;
	animation-duration: 800ms
}
.animate-spin-slow-reverse {
	animation-name: spin;
	animation-duration: 40s;
	animation-timing-function: linear;
	animation-name: spinReverse
}
.animate-progress {
	animation-timing-function: linear;
	animation-name: progress
}
.animate-pulse-blue {
	animation-name: pulseBlue;
	animation-timing-function: cubic-bezier(0, .42, .26, .96);
	animation-duration: 1.5s;
	animation-iteration-count: infinite
}
.animate-pulse-white {
	animation-name: pulseWhite;
	animation-timing-function: cubic-bezier(0, .42, .26, .96);
	animation-duration: 1.5s;
	animation-iteration-count: infinite
}
.animate-pulse-dark {
	animation-name: pulseDark;
	animation-timing-function: cubic-bezier(0, .42, .26, .96);
	animation-duration: 1.5s;
	animation-iteration-count: infinite
}
.animate-sway-horizontal {
	animation-name: swayHorizontal;
	animation-timing-function: ease-in-out;
	animation-duration: 2s;
	animation-iteration-count: infinite
}
@keyframes progress {
	0% {
		width: 0%
	}
	100% {
		width: 100%
	}
}
@keyframes spinScale {
	0% {
		transform: rotate(0) scale(1)
	}
	50% {
		transform: rotate(360deg) scale(.9)
	}
	100% {
		transform: rotate(720deg) scale(1)
	}
}
@keyframes swayHorizontal {
	0% {
		transform: translateX(-3%)
	}
	60% {
		transform: translateX(3%)
	}
	100% {
		transform: translateX(-3%)
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(360deg)
	}
}
@keyframes spinReverse {
	from {
		transform: rotate(0deg)
	}
	to {
		transform: rotate(-360deg)
	}
}
@keyframes highlight {
	from {
		background-color: #d0f0fd;
		border-radius: 20px;
		box-shadow: 0 0 0 20px #d0f0fd
	}
	to {
		background-color: rgba(255, 255, 255, 0);
		border-radius: 20px;
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0)
	}
}
@keyframes spacey {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0)
	}
	to {
		-webkit-transform: translateX(-2560px);
		transform: translateX(-2560px)
	}
}
@keyframes bounceIn {
	from, 50%, to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: .9;
		-webkit-transform: scale3d(.98, .98, .98);
		transform: scale3d(.98, .98, .98)
	}
	70% {
		opacity: 1;
		-webkit-transform: scale3d(1.01, 1.01, 1.01);
		transform: scale3d(1.01, 1.01, 1.01)
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
}
@keyframes bounceIn {
	from, 50%, to {
		-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}
	0% {
		opacity: .9;
		-webkit-transform: scale3d(.98, .98, .98);
		transform: scale3d(.98, .98, .98)
	}
	70% {
		opacity: 1;
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03)
	}
	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
}
@keyframes opacityFadeIn {
	from {
		opacity: 0
	}
	to {
		opacity: 1
	}
}
@keyframes opacityFadeOut {
	from {
		opacity: 1
	}
	to {
		opacity: 0
	}
}
@keyframes fadeUpIn {
	from {
		opacity: 0;
		transform: translate(0, -4px)
	}
	to {
		opacity: 1;
		transform: translate(0, 0)
	}
}
@keyframes pulseBlue {
	0% {
		box-shadow: 0 0 0 4px #2d7ff9
	}
	40% {
		box-shadow: 0 0 0 8px #2d7ff9
	}
	100% {
		box-shadow: 0 0 0 4px #2d7ff9
	}
}
@keyframes pulseWhite {
	0% {
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7)
	}
	40% {
		box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.8)
	}
	100% {
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.7)
	}
}
@keyframes pulseDark {
	0% {
		box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5)
	}
	40% {
		box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6)
	}
	100% {
		box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5)
	}
}
.transition-group-opacity-enter,
.transition-group-opacity-appear,
.transition-group-opacity-leave.transition-group-opacity-leave-active {
	opacity: .01
}
.trantision-group-opacity-enter.transition-group-opacity-enter-active,
.transition-group-opacity-leave,
.transition-group-opacity-appear.transition-group-opacity-appear-active {
	opacity: 1
}
.trantision-group-opacity-enter.transition-group-opacity-enter-active,
.transition-group-opacity-leave.transition-group-opacity-leave-active,
.transition-group-opacity-appear.transition-group-opacity-appear-active {
	transition: opacity 500ms ease-in
}

.img-tight {
	width:100%
}
.col {
	float: left;
	box-sizing: border-box
}
.col-right {
	float: right;
	box-sizing: border-box
}
.col-1 {
	width: 8.33333%
}
.col-2 {
	width: 16.66667%
}
.col-3 {
	width: 25%
}
.col-4 {
	width: 33.33333%
}
.col-5 {
	width: 41.66667%
}
.col-6 {
	width: 50%
}
.col-7 {
	width: 58.33333%
}
.col-8 {
	width: 66.66667%
}
.col-9 {
	width: 75%
}
.col-10 {
	width: 83.33333%
}
.col-11 {
	width: 91.66667%
}
.col-12 {
	width: 100%
}
.width-full {
	width: 100%
}
.height-full {
	height: 100%
}
.width-fifth {
	width: 20%
}
@media (min-width: 40rem) {
	.sm-col {
		float: left;
		box-sizing: border-box
	}
	.sm-col-right {
		float: right;
		box-sizing: border-box
	}
	.sm-col-1 {
		width: 8.33333%
	}
	.sm-col-2 {
		width: 16.66667%
	}
	.sm-col-3 {
		width: 25%
	}
	.sm-col-4 {
		width: 33.33333%
	}
	.sm-col-5 {
		width: 41.66667%
	}
	.sm-col-6 {
		width: 50%
	}
	.sm-col-7 {
		width: 58.33333%
	}
	.sm-col-8 {
		width: 66.66667%
	}
	.sm-col-9 {
		width: 75%
	}
	.sm-col-10 {
		width: 83.33333%
	}
	.sm-col-11 {
		width: 91.66667%
	}
	.sm-col-12 {
		width: 100%
	}
	.sm-width-full {
		width: 100%
	}
	.sm-height-full {
		height: 100%
	}
	.sm-width-fifth {
		width: 20%
	}
}
@media (min-width: 52rem) {
	.md-col {
		float: left;
		box-sizing: border-box
	}
	.md-col-right {
		float: right;
		box-sizing: border-box
	}
	.md-col-1 {
		width: 8.33333%
	}
	.md-col-2 {
		width: 16.66667%
	}
	.md-col-3 {
		width: 25%
	}
	.md-col-4 {
		width: 33.33333%
	}
	.md-col-5 {
		width: 41.66667%
	}
	.md-col-6 {
		width: 50%
	}
	.md-col-7 {
		width: 58.33333%
	}
	.md-col-8 {
		width: 66.66667%
	}
	.md-col-9 {
		width: 75%
	}
	.md-col-10 {
		width: 83.33333%
	}
	.md-col-11 {
		width: 91.66667%
	}
	.md-col-12 {
		width: 100%
	}
	.md-width-full {
		width: 100%
	}
	.md-height-full {
		height: 100%
	}
	.md-width-fifth {
		width: 20%
	}
}
@media (min-width: 72rem) {
	.lg-col {
		float: left;
		box-sizing: border-box
	}
	.lg-col-right {
		float: right;
		box-sizing: border-box
	}
	.lg-col-1 {
		width: 8.33333%
	}
	.lg-col-2 {
		width: 16.66667%
	}
	.lg-col-3 {
		width: 25%
	}
	.lg-col-4 {
		width: 33.33333%
	}
	.lg-col-5 {
		width: 41.66667%
	}
	.lg-col-6 {
		width: 50%
	}
	.lg-col-7 {
		width: 58.33333%
	}
	.lg-col-8 {
		width: 66.66667%
	}
	.lg-col-9 {
		width: 75%
	}
	.lg-col-10 {
		width: 83.33333%
	}
	.lg-col-11 {
		width: 91.66667%
	}
	.lg-col-12 {
		width: 100%
	}
	.lg-width-full {
		width: 100%
	}
	.lg-height-full {
		height: 100%
	}
	.lg-width-fifth {
		width: 20%
	}
}
.p0 {
	padding: 0
}
.pt0 {
	padding-top: 0
}
.pr0 {
	padding-right: 0
}
.pb0 {
	padding-bottom: 0
}
.pl0 {
	padding-left: 0
}
.px0 {
	padding-left: 0;
	padding-right: 0
}
.py0 {
	padding-top: 0;
	padding-bottom: 0
}
.p-half {
	padding: .25rem
}
.pt-half {
	padding-top: .25rem
}
.pr-half {
	padding-right: .25rem
}
.pb-half {
	padding-bottom: .25rem
}
.pl-half {
	padding-left: .25rem
}
.px-half {
	padding-left: .25rem;
	padding-right: .25rem
}
.py-half {
	padding-top: .25rem;
	padding-bottom: .25rem
}
.p1 {
	padding: .5rem
}
.pt1 {
	padding-top: .5rem
}
.pr1 {
	padding-right: .5rem
}
.pb1 {
	padding-bottom: .5rem
}
.pl1 {
	padding-left: .5rem
}
.py1 {
	padding-top: .5rem;
	padding-bottom: .5rem
}
.px1 {
	padding-left: .5rem;
	padding-right: .5rem
}
.p2 {
	padding: 1rem
}
.pt2 {
	padding-top: 1rem
}
.pr2 {
	padding-right: 1rem
}
.pb2 {
	padding-bottom: 1rem
}
.pl2 {
	padding-left: 1rem
}
.py2 {
	padding-top: 1rem;
	padding-bottom: 1rem
}
.px2 {
	padding-left: 1rem;
	padding-right: 1rem
}
.p3 {
	padding: 2rem
}
.pt3 {
	padding-top: 2rem
}
.pr3 {
	padding-right: 2rem
}
.pb3 {
	padding-bottom: 2rem
}
.pl3 {
	padding-left: 2rem
}
.py3 {
	padding-top: 2rem;
	padding-bottom: 2rem
}
.px3 {
	padding-left: 2rem;
	padding-right: 2rem
}
.p4 {
	padding: 4rem
}
.pt4 {
	padding-top: 4rem
}
.pr4 {
	padding-right: 4rem
}
.pb4 {
	padding-bottom: 4rem
}
.pl4 {
	padding-left: 4rem
}
.py4 {
	padding-top: 4rem;
	padding-bottom: 4rem
}
.px4 {
	padding-left: 4rem;
	padding-right: 4rem
}
.p5 {
	padding: 8rem
}
.pt5 {
	padding-top: 8rem
}
.pr5 {
	padding-right: 8rem
}
.pb5 {
	padding-bottom: 8rem
}
.pl5 {
	padding-left: 8rem
}
.py5 {
	padding-top: 8rem;
	padding-bottom: 8rem
}
.px5 {
	padding-left: 8rem;
	padding-right: 8rem
}
.m0 {
	margin: 0
}
.mt0 {
	margin-top: 0
}
.mr0 {
	margin-right: 0
}
.mb0 {
	margin-bottom: 0
}
.ml0 {
	margin-left: 0
}
.mx0 {
	margin-left: 0;
	margin-right: 0
}
.my0 {
	margin-top: 0;
	margin-bottom: 0
}
.m-half {
	margin: .25rem
}
.mt-half {
	margin-top: .25rem
}
.mr-half {
	margin-right: .25rem
}
.mb-half {
	margin-bottom: .25rem
}
.ml-half {
	margin-left: .25rem
}
.mx-half {
	margin-left: .25rem;
	margin-right: .25rem
}
.my-half {
	margin-top: .25rem;
	margin-bottom: .25rem
}
.m1 {
	margin: .5rem
}
.mt1 {
	margin-top: .5rem
}
.mr1 {
	margin-right: .5rem
}
.mb1 {
	margin-bottom: .5rem
}
.ml1 {
	margin-left: .5rem
}
.mx1 {
	margin-left: .5rem;
	margin-right: .5rem
}
.my1 {
	margin-top: .5rem;
	margin-bottom: .5rem
}
.m2 {
	margin: 1rem
}
.mt2 {
	margin-top: 1rem
}
.mr2 {
	margin-right: 1rem
}
.mb2 {
	margin-bottom: 1rem
}
.ml2 {
	margin-left: 1rem
}
.mx2 {
	margin-left: 1rem;
	margin-right: 1rem
}
.my2 {
	margin-top: 1rem;
	margin-bottom: 1rem
}
.m3 {
	margin: 2rem
}
.mt3 {
	margin-top: 2rem
}
.mr3 {
	margin-right: 2rem
}
.mb3 {
	margin-bottom: 2rem
}
.ml3 {
	margin-left: 2rem
}
.mx3 {
	margin-left: 2rem;
	margin-right: 2rem
}
.my3 {
	margin-top: 2rem;
	margin-bottom: 2rem
}
.m4 {
	margin: 4rem
}
.mt4 {
	margin-top: 4rem
}
.mr4 {
	margin-right: 4rem
}
.mb4 {
	margin-bottom: 4rem
}
.ml4 {
	margin-left: 4rem
}
.mx4 {
	margin-left: 4rem;
	margin-right: 4rem
}
.my4 {
	margin-top: 4rem;
	margin-bottom: 4rem
}
.m5 {
	margin: 8rem
}
.mt5 {
	margin-top: 8rem
}
.mr5 {
	margin-right: 8rem
}
.mb5 {
	margin-bottom: 8rem
}
.ml5 {
	margin-left: 8rem
}
.mx5 {
	margin-left: 8rem;
	margin-right: 8rem
}
.my5 {
	margin-top: 8rem;
	margin-bottom: 8rem
}
.mln1 {
	margin-left: -0.5rem
}
.mln2 {
	margin-left: -1rem
}
.mln3 {
	margin-left: -2rem
}
.mln4 {
	margin-left: -4rem
}
.mln5 {
	margin-top: -8rem
}
.mtn1 {
	margin-top: -0.5rem
}
.mtn2 {
	margin-top: -1rem
}
.mtn3 {
	margin-top: -2rem
}
.mtn4 {
	margin-top: -4rem
}
.mtn5 {
	margin-top: -8rem
}
.mxn1 {
	margin-left: -0.5rem;
	margin-right: -0.5rem
}
.mxn2 {
	margin-left: -1rem;
	margin-right: -1rem
}
.mxn3 {
	margin-left: -2rem;
	margin-right: -2rem
}
.mxn4 {
	margin-left: -4rem;
	margin-right: -4rem
}
.mxn5 {
	margin-left: -8rem;
	margin-right: -8rem
}
.ml-auto {
	margin-left: auto
}
.mr-auto {
	margin-right: auto
}
.mx-auto {
	margin-left: auto;
	margin-right: auto
}
@media (max-width: 39.99rem) {
	.xs-p0 {
		padding: 0
	}
	.xs-pt0 {
		padding-top: 0
	}
	.xs-pr0 {
		padding-right: 0
	}
	.xs-pb0 {
		padding-bottom: 0
	}
	.xs-pl0 {
		padding-left: 0
	}
	.xs-px0 {
		padding-left: 0;
		padding-right: 0
	}
	.xs-py0 {
		padding-top: 0;
		padding-bottom: 0
	}
	.xs-p-half {
		padding: .25rem
	}
	.xs-pt-half {
		padding-top: .25rem
	}
	.xs-pr-half {
		padding-right: .25rem
	}
	.xs-pb-half {
		padding-bottom: .25rem
	}
	.xs-pl-half {
		padding-left: .25rem
	}
	.xs-px-half {
		padding-left: .25rem;
		padding-right: .25rem
	}
	.xs-py-half {
		padding-top: .25rem;
		padding-bottom: .25rem
	}
	.xs-p1 {
		padding: .5rem
	}
	.xs-pt1 {
		padding-top: .5rem
	}
	.xs-pr1 {
		padding-right: .5rem
	}
	.xs-pb1 {
		padding-bottom: .5rem
	}
	.xs-pl1 {
		padding-left: .5rem
	}
	.xs-py1 {
		padding-top: .5rem;
		padding-bottom: .5rem
	}
	.xs-px1 {
		padding-left: .5rem;
		padding-right: .5rem
	}
	.xs-p2 {
		padding: 1rem
	}
	.xs-pt2 {
		padding-top: 1rem
	}
	.xs-pr2 {
		padding-right: 1rem
	}
	.xs-pb2 {
		padding-bottom: 1rem
	}
	.xs-pl2 {
		padding-left: 1rem
	}
	.xs-py2 {
		padding-top: 1rem;
		padding-bottom: 1rem
	}
	.xs-px2 {
		padding-left: 1rem;
		padding-right: 1rem
	}
	.xs-p3 {
		padding: 2rem
	}
	.xs-pt3 {
		padding-top: 2rem
	}
	.xs-pr3 {
		padding-right: 2rem
	}
	.xs-pb3 {
		padding-bottom: 2rem
	}
	.xs-pl3 {
		padding-left: 2rem
	}
	.xs-py3 {
		padding-top: 2rem;
		padding-bottom: 2rem
	}
	.xs-px3 {
		padding-left: 2rem;
		padding-right: 2rem
	}
	.xs-p4 {
		padding: 4rem
	}
	.xs-pt4 {
		padding-top: 4rem
	}
	.xs-pr4 {
		padding-right: 4rem
	}
	.xs-pb4 {
		padding-bottom: 4rem
	}
	.xs-pl4 {
		padding-left: 4rem
	}
	.xs-py4 {
		padding-top: 4rem;
		padding-bottom: 4rem
	}
	.xs-px4 {
		padding-left: 4rem;
		padding-right: 4rem
	}
	.xs-p5 {
		padding: 8rem
	}
	.xs-pt5 {
		padding-top: 8rem
	}
	.xs-pr5 {
		padding-right: 8rem
	}
	.xs-pb5 {
		padding-bottom: 8rem
	}
	.xs-pl5 {
		padding-left: 8rem
	}
	.xs-py5 {
		padding-top: 8rem;
		padding-bottom: 8rem
	}
	.xs-px5 {
		padding-left: 8rem;
		padding-right: 8rem
	}
	.xs-m0 {
		margin: 0
	}
	.xs-mt0 {
		margin-top: 0
	}
	.xs-mr0 {
		margin-right: 0
	}
	.xs-mb0 {
		margin-bottom: 0
	}
	.xs-ml0 {
		margin-left: 0
	}
	.xs-mx0 {
		margin-left: 0;
		margin-right: 0
	}
	.xs-my0 {
		margin-top: 0;
		margin-bottom: 0
	}
	.xs-m-half {
		margin: .25rem
	}
	.xs-mt-half {
		margin-top: .25rem
	}
	.xs-mr-half {
		margin-right: .25rem
	}
	.xs-mb-half {
		margin-bottom: .25rem
	}
	.xs-ml-half {
		margin-left: .25rem
	}
	.xs-mx-half {
		margin-left: .25rem;
		margin-right: .25rem
	}
	.xs-my-half {
		margin-top: .25rem;
		margin-bottom: .25rem
	}
	.xs-m1 {
		margin: .5rem
	}
	.xs-mt1 {
		margin-top: .5rem
	}
	.xs-mr1 {
		margin-right: .5rem
	}
	.xs-mb1 {
		margin-bottom: .5rem
	}
	.xs-ml1 {
		margin-left: .5rem
	}
	.xs-mx1 {
		margin-left: .5rem;
		margin-right: .5rem
	}
	.xs-my1 {
		margin-top: .5rem;
		margin-bottom: .5rem
	}
	.xs-m2 {
		margin: 1rem
	}
	.xs-mt2 {
		margin-top: 1rem
	}
	.xs-mr2 {
		margin-right: 1rem
	}
	.xs-mb2 {
		margin-bottom: 1rem
	}
	.xs-ml2 {
		margin-left: 1rem
	}
	.xs-mx2 {
		margin-left: 1rem;
		margin-right: 1rem
	}
	.xs-my2 {
		margin-top: 1rem;
		margin-bottom: 1rem
	}
	.xs-m3 {
		margin: 2rem
	}
	.xs-mt3 {
		margin-top: 2rem
	}
	.xs-mr3 {
		margin-right: 2rem
	}
	.xs-mb3 {
		margin-bottom: 2rem
	}
	.xs-ml3 {
		margin-left: 2rem
	}
	.xs-mx3 {
		margin-left: 2rem;
		margin-right: 2rem
	}
	.xs-my3 {
		margin-top: 2rem;
		margin-bottom: 2rem
	}
	.xs-m4 {
		margin: 4rem
	}
	.xs-mt4 {
		margin-top: 4rem
	}
	.xs-mr4 {
		margin-right: 4rem
	}
	.xs-mb4 {
		margin-bottom: 4rem
	}
	.xs-ml4 {
		margin-left: 4rem
	}
	.xs-mx4 {
		margin-left: 4rem;
		margin-right: 4rem
	}
	.xs-my4 {
		margin-top: 4rem;
		margin-bottom: 4rem
	}
	.xs-m5 {
		margin: 8rem
	}
	.xs-mt5 {
		margin-top: 8rem
	}
	.xs-mr5 {
		margin-right: 8rem
	}
	.xs-mb5 {
		margin-bottom: 8rem
	}
	.xs-ml5 {
		margin-left: 8rem
	}
	.xs-mx5 {
		margin-left: 8rem;
		margin-right: 8rem
	}
	.xs-my5 {
		margin-top: 8rem;
		margin-bottom: 8rem
	}
	.xs-mln1 {
		margin-left: -0.5rem
	}
	.xs-mln2 {
		margin-left: -1rem
	}
	.xs-mln3 {
		margin-left: -2rem
	}
	.xs-mln4 {
		margin-left: -4rem
	}
	.xs-mln5 {
		margin-top: -8rem
	}
	.xs-mtn1 {
		margin-top: -0.5rem
	}
	.xs-mtn2 {
		margin-top: -1rem
	}
	.xs-mtn3 {
		margin-top: -2rem
	}
	.xs-mtn4 {
		margin-top: -4rem
	}
	.xs-mtn5 {
		margin-top: -8rem
	}
	.xs-mxn1 {
		margin-left: -0.5rem;
		margin-right: -0.5rem
	}
	.xs-mxn2 {
		margin-left: -1rem;
		margin-right: -1rem
	}
	.xs-mxn3 {
		margin-left: -2rem;
		margin-right: -2rem
	}
	.xs-mxn4 {
		margin-left: -4rem;
		margin-right: -4rem
	}
	.xs-mxn5 {
		margin-left: -8rem;
		margin-right: -8rem
	}
	.xs-ml-auto {
		margin-left: auto
	}
	.xs-mr-auto {
		margin-right: auto
	}
	.xs-mx-auto {
		margin-left: auto;
		margin-right: auto
	}
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
	.sm-p0 {
		padding: 0
	}
	.sm-pt0 {
		padding-top: 0
	}
	.sm-pr0 {
		padding-right: 0
	}
	.sm-pb0 {
		padding-bottom: 0
	}
	.sm-pl0 {
		padding-left: 0
	}
	.sm-px0 {
		padding-left: 0;
		padding-right: 0
	}
	.sm-py0 {
		padding-top: 0;
		padding-bottom: 0
	}
	.sm-p-half {
		padding: .25rem
	}
	.sm-pt-half {
		padding-top: .25rem
	}
	.sm-pr-half {
		padding-right: .25rem
	}
	.sm-pb-half {
		padding-bottom: .25rem
	}
	.sm-pl-half {
		padding-left: .25rem
	}
	.sm-px-half {
		padding-left: .25rem;
		padding-right: .25rem
	}
	.sm-py-half {
		padding-top: .25rem;
		padding-bottom: .25rem
	}
	.sm-p1 {
		padding: .5rem
	}
	.sm-pt1 {
		padding-top: .5rem
	}
	.sm-pr1 {
		padding-right: .5rem
	}
	.sm-pb1 {
		padding-bottom: .5rem
	}
	.sm-pl1 {
		padding-left: .5rem
	}
	.sm-py1 {
		padding-top: .5rem;
		padding-bottom: .5rem
	}
	.sm-px1 {
		padding-left: .5rem;
		padding-right: .5rem
	}
	.sm-p2 {
		padding: 1rem
	}
	.sm-pt2 {
		padding-top: 1rem
	}
	.sm-pr2 {
		padding-right: 1rem
	}
	.sm-pb2 {
		padding-bottom: 1rem
	}
	.sm-pl2 {
		padding-left: 1rem
	}
	.sm-py2 {
		padding-top: 1rem;
		padding-bottom: 1rem
	}
	.sm-px2 {
		padding-left: 1rem;
		padding-right: 1rem
	}
	.sm-p3 {
		padding: 2rem
	}
	.sm-pt3 {
		padding-top: 2rem
	}
	.sm-pr3 {
		padding-right: 2rem
	}
	.sm-pb3 {
		padding-bottom: 2rem
	}
	.sm-pl3 {
		padding-left: 2rem
	}
	.sm-py3 {
		padding-top: 2rem;
		padding-bottom: 2rem
	}
	.sm-px3 {
		padding-left: 2rem;
		padding-right: 2rem
	}
	.sm-p4 {
		padding: 4rem
	}
	.sm-pt4 {
		padding-top: 4rem
	}
	.sm-pr4 {
		padding-right: 4rem
	}
	.sm-pb4 {
		padding-bottom: 4rem
	}
	.sm-pl4 {
		padding-left: 4rem
	}
	.sm-py4 {
		padding-top: 4rem;
		padding-bottom: 4rem
	}
	.sm-px4 {
		padding-left: 4rem;
		padding-right: 4rem
	}
	.sm-p5 {
		padding: 8rem
	}
	.sm-pt5 {
		padding-top: 8rem
	}
	.sm-pr5 {
		padding-right: 8rem
	}
	.sm-pb5 {
		padding-bottom: 8rem
	}
	.sm-pl5 {
		padding-left: 8rem
	}
	.sm-py5 {
		padding-top: 8rem;
		padding-bottom: 8rem
	}
	.sm-px5 {
		padding-left: 8rem;
		padding-right: 8rem
	}
	.sm-m0 {
		margin: 0
	}
	.sm-mt0 {
		margin-top: 0
	}
	.sm-mr0 {
		margin-right: 0
	}
	.sm-mb0 {
		margin-bottom: 0
	}
	.sm-ml0 {
		margin-left: 0
	}
	.sm-mx0 {
		margin-left: 0;
		margin-right: 0
	}
	.sm-my0 {
		margin-top: 0;
		margin-bottom: 0
	}
	.sm-m-half {
		margin: .25rem
	}
	.sm-mt-half {
		margin-top: .25rem
	}
	.sm-mr-half {
		margin-right: .25rem
	}
	.sm-mb-half {
		margin-bottom: .25rem
	}
	.sm-ml-half {
		margin-left: .25rem
	}
	.sm-mx-half {
		margin-left: .25rem;
		margin-right: .25rem
	}
	.sm-my-half {
		margin-top: .25rem;
		margin-bottom: .25rem
	}
	.sm-m1 {
		margin: .5rem
	}
	.sm-mt1 {
		margin-top: .5rem
	}
	.sm-mr1 {
		margin-right: .5rem
	}
	.sm-mb1 {
		margin-bottom: .5rem
	}
	.sm-ml1 {
		margin-left: .5rem
	}
	.sm-mx1 {
		margin-left: .5rem;
		margin-right: .5rem
	}
	.sm-my1 {
		margin-top: .5rem;
		margin-bottom: .5rem
	}
	.sm-m2 {
		margin: 1rem
	}
	.sm-mt2 {
		margin-top: 1rem
	}
	.sm-mr2 {
		margin-right: 1rem
	}
	.sm-mb2 {
		margin-bottom: 1rem
	}
	.sm-ml2 {
		margin-left: 1rem
	}
	.sm-mx2 {
		margin-left: 1rem;
		margin-right: 1rem
	}
	.sm-my2 {
		margin-top: 1rem;
		margin-bottom: 1rem
	}
	.sm-m3 {
		margin: 2rem
	}
	.sm-mt3 {
		margin-top: 2rem
	}
	.sm-mr3 {
		margin-right: 2rem
	}
	.sm-mb3 {
		margin-bottom: 2rem
	}
	.sm-ml3 {
		margin-left: 2rem
	}
	.sm-mx3 {
		margin-left: 2rem;
		margin-right: 2rem
	}
	.sm-my3 {
		margin-top: 2rem;
		margin-bottom: 2rem
	}
	.sm-m4 {
		margin: 4rem
	}
	.sm-mt4 {
		margin-top: 4rem
	}
	.sm-mr4 {
		margin-right: 4rem
	}
	.sm-mb4 {
		margin-bottom: 4rem
	}
	.sm-ml4 {
		margin-left: 4rem
	}
	.sm-mx4 {
		margin-left: 4rem;
		margin-right: 4rem
	}
	.sm-my4 {
		margin-top: 4rem;
		margin-bottom: 4rem
	}
	.sm-m5 {
		margin: 8rem
	}
	.sm-mt5 {
		margin-top: 8rem
	}
	.sm-mr5 {
		margin-right: 8rem
	}
	.sm-mb5 {
		margin-bottom: 8rem
	}
	.sm-ml5 {
		margin-left: 8rem
	}
	.sm-mx5 {
		margin-left: 8rem;
		margin-right: 8rem
	}
	.sm-my5 {
		margin-top: 8rem;
		margin-bottom: 8rem
	}
	.sm-mln1 {
		margin-left: -0.5rem
	}
	.sm-mln2 {
		margin-left: -1rem
	}
	.sm-mln3 {
		margin-left: -2rem
	}
	.sm-mln4 {
		margin-left: -4rem
	}
	.sm-mln5 {
		margin-top: -8rem
	}
	.sm-mtn1 {
		margin-top: -0.5rem
	}
	.sm-mtn2 {
		margin-top: -1rem
	}
	.sm-mtn3 {
		margin-top: -2rem
	}
	.sm-mtn4 {
		margin-top: -4rem
	}
	.sm-mtn5 {
		margin-top: -8rem
	}
	.sm-mxn1 {
		margin-left: -0.5rem;
		margin-right: -0.5rem
	}
	.sm-mxn2 {
		margin-left: -1rem;
		margin-right: -1rem
	}
	.sm-mxn3 {
		margin-left: -2rem;
		margin-right: -2rem
	}
	.sm-mxn4 {
		margin-left: -4rem;
		margin-right: -4rem
	}
	.sm-mxn5 {
		margin-left: -8rem;
		margin-right: -8rem
	}
	.sm-ml-auto {
		margin-left: auto
	}
	.sm-mr-auto {
		margin-right: auto
	}
	.sm-mx-auto {
		margin-left: auto;
		margin-right: auto
	}
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
	.md-p0 {
		padding: 0
	}
	.md-pt0 {
		padding-top: 0
	}
	.md-pr0 {
		padding-right: 0
	}
	.md-pb0 {
		padding-bottom: 0
	}
	.md-pl0 {
		padding-left: 0
	}
	.md-px0 {
		padding-left: 0;
		padding-right: 0
	}
	.md-py0 {
		padding-top: 0;
		padding-bottom: 0
	}
	.md-p-half {
		padding: .25rem
	}
	.md-pt-half {
		padding-top: .25rem
	}
	.md-pr-half {
		padding-right: .25rem
	}
	.md-pb-half {
		padding-bottom: .25rem
	}
	.md-pl-half {
		padding-left: .25rem
	}
	.md-px-half {
		padding-left: .25rem;
		padding-right: .25rem
	}
	.md-py-half {
		padding-top: .25rem;
		padding-bottom: .25rem
	}
	.md-p1 {
		padding: .5rem
	}
	.md-pt1 {
		padding-top: .5rem
	}
	.md-pr1 {
		padding-right: .5rem
	}
	.md-pb1 {
		padding-bottom: .5rem
	}
	.md-pl1 {
		padding-left: .5rem
	}
	.md-py1 {
		padding-top: .5rem;
		padding-bottom: .5rem
	}
	.md-px1 {
		padding-left: .5rem;
		padding-right: .5rem
	}
	.md-p2 {
		padding: 1rem
	}
	.md-pt2 {
		padding-top: 1rem
	}
	.md-pr2 {
		padding-right: 1rem
	}
	.md-pb2 {
		padding-bottom: 1rem
	}
	.md-pl2 {
		padding-left: 1rem
	}
	.md-py2 {
		padding-top: 1rem;
		padding-bottom: 1rem
	}
	.md-px2 {
		padding-left: 1rem;
		padding-right: 1rem
	}
	.md-p3 {
		padding: 2rem
	}
	.md-pt3 {
		padding-top: 2rem
	}
	.md-pr3 {
		padding-right: 2rem
	}
	.md-pb3 {
		padding-bottom: 2rem
	}
	.md-pl3 {
		padding-left: 2rem
	}
	.md-py3 {
		padding-top: 2rem;
		padding-bottom: 2rem
	}
	.md-px3 {
		padding-left: 2rem;
		padding-right: 2rem
	}
	.md-p4 {
		padding: 4rem
	}
	.md-pt4 {
		padding-top: 4rem
	}
	.md-pr4 {
		padding-right: 4rem
	}
	.md-pb4 {
		padding-bottom: 4rem
	}
	.md-pl4 {
		padding-left: 4rem
	}
	.md-py4 {
		padding-top: 4rem;
		padding-bottom: 4rem
	}
	.md-px4 {
		padding-left: 4rem;
		padding-right: 4rem
	}
	.md-p5 {
		padding: 8rem
	}
	.md-pt5 {
		padding-top: 8rem
	}
	.md-pr5 {
		padding-right: 8rem
	}
	.md-pb5 {
		padding-bottom: 8rem
	}
	.md-pl5 {
		padding-left: 8rem
	}
	.md-py5 {
		padding-top: 8rem;
		padding-bottom: 8rem
	}
	.md-px5 {
		padding-left: 8rem;
		padding-right: 8rem
	}
	.md-m0 {
		margin: 0
	}
	.md-mt0 {
		margin-top: 0
	}
	.md-mr0 {
		margin-right: 0
	}
	.md-mb0 {
		margin-bottom: 0
	}
	.md-ml0 {
		margin-left: 0
	}
	.md-mx0 {
		margin-left: 0;
		margin-right: 0
	}
	.md-my0 {
		margin-top: 0;
		margin-bottom: 0
	}
	.md-m-half {
		margin: .25rem
	}
	.md-mt-half {
		margin-top: .25rem
	}
	.md-mr-half {
		margin-right: .25rem
	}
	.md-mb-half {
		margin-bottom: .25rem
	}
	.md-ml-half {
		margin-left: .25rem
	}
	.md-mx-half {
		margin-left: .25rem;
		margin-right: .25rem
	}
	.md-my-half {
		margin-top: .25rem;
		margin-bottom: .25rem
	}
	.md-m1 {
		margin: .5rem
	}
	.md-mt1 {
		margin-top: .5rem
	}
	.md-mr1 {
		margin-right: .5rem
	}
	.md-mb1 {
		margin-bottom: .5rem
	}
	.md-ml1 {
		margin-left: .5rem
	}
	.md-mx1 {
		margin-left: .5rem;
		margin-right: .5rem
	}
	.md-my1 {
		margin-top: .5rem;
		margin-bottom: .5rem
	}
	.md-m2 {
		margin: 1rem
	}
	.md-mt2 {
		margin-top: 1rem
	}
	.md-mr2 {
		margin-right: 1rem
	}
	.md-mb2 {
		margin-bottom: 1rem
	}
	.md-ml2 {
		margin-left: 1rem
	}
	.md-mx2 {
		margin-left: 1rem;
		margin-right: 1rem
	}
	.md-my2 {
		margin-top: 1rem;
		margin-bottom: 1rem
	}
	.md-m3 {
		margin: 2rem
	}
	.md-mt3 {
		margin-top: 2rem
	}
	.md-mr3 {
		margin-right: 2rem
	}
	.md-mb3 {
		margin-bottom: 2rem
	}
	.md-ml3 {
		margin-left: 2rem
	}
	.md-mx3 {
		margin-left: 2rem;
		margin-right: 2rem
	}
	.md-my3 {
		margin-top: 2rem;
		margin-bottom: 2rem
	}
	.md-m4 {
		margin: 4rem
	}
	.md-mt4 {
		margin-top: 4rem
	}
	.md-mr4 {
		margin-right: 4rem
	}
	.md-mb4 {
		margin-bottom: 4rem
	}
	.md-ml4 {
		margin-left: 4rem
	}
	.md-mx4 {
		margin-left: 4rem;
		margin-right: 4rem
	}
	.md-my4 {
		margin-top: 4rem;
		margin-bottom: 4rem
	}
	.md-m5 {
		margin: 8rem
	}
	.md-mt5 {
		margin-top: 8rem
	}
	.md-mr5 {
		margin-right: 8rem
	}
	.md-mb5 {
		margin-bottom: 8rem
	}
	.md-ml5 {
		margin-left: 8rem
	}
	.md-mx5 {
		margin-left: 8rem;
		margin-right: 8rem
	}
	.md-my5 {
		margin-top: 8rem;
		margin-bottom: 8rem
	}
	.md-mln1 {
		margin-left: -0.5rem
	}
	.md-mln2 {
		margin-left: -1rem
	}
	.md-mln3 {
		margin-left: -2rem
	}
	.md-mln4 {
		margin-left: -4rem
	}
	.md-mln5 {
		margin-top: -8rem
	}
	.md-mtn1 {
		margin-top: -0.5rem
	}
	.md-mtn2 {
		margin-top: -1rem
	}
	.md-mtn3 {
		margin-top: -2rem
	}
	.md-mtn4 {
		margin-top: -4rem
	}
	.md-mtn5 {
		margin-top: -8rem
	}
	.md-mxn1 {
		margin-left: -0.5rem;
		margin-right: -0.5rem
	}
	.md-mxn2 {
		margin-left: -1rem;
		margin-right: -1rem
	}
	.md-mxn3 {
		margin-left: -2rem;
		margin-right: -2rem
	}
	.md-mxn4 {
		margin-left: -4rem;
		margin-right: -4rem
	}
	.md-mxn5 {
		margin-left: -8rem;
		margin-right: -8rem
	}
	.md-ml-auto {
		margin-left: auto
	}
	.md-mr-auto {
		margin-right: auto
	}
	.md-mx-auto {
		margin-left: auto;
		margin-right: auto
	}
}
@media (min-width: 72rem) {
	.lg-p0 {
		padding: 0
	}
	.lg-pt0 {
		padding-top: 0
	}
	.lg-pr0 {
		padding-right: 0
	}
	.lg-pb0 {
		padding-bottom: 0
	}
	.lg-pl0 {
		padding-left: 0
	}
	.lg-px0 {
		padding-left: 0;
		padding-right: 0
	}
	.lg-py0 {
		padding-top: 0;
		padding-bottom: 0
	}
	.lg-p-half {
		padding: .25rem
	}
	.lg-pt-half {
		padding-top: .25rem
	}
	.lg-pr-half {
		padding-right: .25rem
	}
	.lg-pb-half {
		padding-bottom: .25rem
	}
	.lg-pl-half {
		padding-left: .25rem
	}
	.lg-px-half {
		padding-left: .25rem;
		padding-right: .25rem
	}
	.lg-py-half {
		padding-top: .25rem;
		padding-bottom: .25rem
	}
	.lg-p1 {
		padding: .5rem
	}
	.lg-pt1 {
		padding-top: .5rem
	}
	.lg-pr1 {
		padding-right: .5rem
	}
	.lg-pb1 {
		padding-bottom: .5rem
	}
	.lg-pl1 {
		padding-left: .5rem
	}
	.lg-py1 {
		padding-top: .5rem;
		padding-bottom: .5rem
	}
	.lg-px1 {
		padding-left: .5rem;
		padding-right: .5rem
	}
	.lg-p2 {
		padding: 1rem
	}
	.lg-pt2 {
		padding-top: 1rem
	}
	.lg-pr2 {
		padding-right: 1rem
	}
	.lg-pb2 {
		padding-bottom: 1rem
	}
	.lg-pl2 {
		padding-left: 1rem
	}
	.lg-py2 {
		padding-top: 1rem;
		padding-bottom: 1rem
	}
	.lg-px2 {
		padding-left: 1rem;
		padding-right: 1rem
	}
	.lg-p3 {
		padding: 2rem
	}
	.lg-pt3 {
		padding-top: 2rem
	}
	.lg-pr3 {
		padding-right: 2rem
	}
	.lg-pb3 {
		padding-bottom: 2rem
	}
	.lg-pl3 {
		padding-left: 2rem
	}
	.lg-py3 {
		padding-top: 2rem;
		padding-bottom: 2rem
	}
	.lg-px3 {
		padding-left: 2rem;
		padding-right: 2rem
	}
	.lg-p4 {
		padding: 4rem
	}
	.lg-pt4 {
		padding-top: 4rem
	}
	.lg-pr4 {
		padding-right: 4rem
	}
	.lg-pb4 {
		padding-bottom: 4rem
	}
	.lg-pl4 {
		padding-left: 4rem
	}
	.lg-py4 {
		padding-top: 4rem;
		padding-bottom: 4rem
	}
	.lg-px4 {
		padding-left: 4rem;
		padding-right: 4rem
	}
	.lg-p5 {
		padding: 8rem
	}
	.lg-pt5 {
		padding-top: 8rem
	}
	.lg-pr5 {
		padding-right: 8rem
	}
	.lg-pb5 {
		padding-bottom: 8rem
	}
	.lg-pl5 {
		padding-left: 8rem
	}
	.lg-py5 {
		padding-top: 8rem;
		padding-bottom: 8rem
	}
	.lg-px5 {
		padding-left: 8rem;
		padding-right: 8rem
	}
	.lg-m0 {
		margin: 0
	}
	.lg-mt0 {
		margin-top: 0
	}
	.lg-mr0 {
		margin-right: 0
	}
	.lg-mb0 {
		margin-bottom: 0
	}
	.lg-ml0 {
		margin-left: 0
	}
	.lg-mx0 {
		margin-left: 0;
		margin-right: 0
	}
	.lg-my0 {
		margin-top: 0;
		margin-bottom: 0
	}
	.lg-m-half {
		margin: .25rem
	}
	.lg-mt-half {
		margin-top: .25rem
	}
	.lg-mr-half {
		margin-right: .25rem
	}
	.lg-mb-half {
		margin-bottom: .25rem
	}
	.lg-ml-half {
		margin-left: .25rem
	}
	.lg-mx-half {
		margin-left: .25rem;
		margin-right: .25rem
	}
	.lg-my-half {
		margin-top: .25rem;
		margin-bottom: .25rem
	}
	.lg-m1 {
		margin: .5rem
	}
	.lg-mt1 {
		margin-top: .5rem
	}
	.lg-mr1 {
		margin-right: .5rem
	}
	.lg-mb1 {
		margin-bottom: .5rem
	}
	.lg-ml1 {
		margin-left: .5rem
	}
	.lg-mx1 {
		margin-left: .5rem;
		margin-right: .5rem
	}
	.lg-my1 {
		margin-top: .5rem;
		margin-bottom: .5rem
	}
	.lg-m2 {
		margin: 1rem
	}
	.lg-mt2 {
		margin-top: 1rem
	}
	.lg-mr2 {
		margin-right: 1rem
	}
	.lg-mb2 {
		margin-bottom: 1rem
	}
	.lg-ml2 {
		margin-left: 1rem
	}
	.lg-mx2 {
		margin-left: 1rem;
		margin-right: 1rem
	}
	.lg-my2 {
		margin-top: 1rem;
		margin-bottom: 1rem
	}
	.lg-m3 {
		margin: 2rem
	}
	.lg-mt3 {
		margin-top: 2rem
	}
	.lg-mr3 {
		margin-right: 2rem
	}
	.lg-mb3 {
		margin-bottom: 2rem
	}
	.lg-ml3 {
		margin-left: 2rem
	}
	.lg-mx3 {
		margin-left: 2rem;
		margin-right: 2rem
	}
	.lg-my3 {
		margin-top: 2rem;
		margin-bottom: 2rem
	}
	.lg-m4 {
		margin: 4rem
	}
	.lg-mt4 {
		margin-top: 4rem
	}
	.lg-mr4 {
		margin-right: 4rem
	}
	.lg-mb4 {
		margin-bottom: 4rem
	}
	.lg-ml4 {
		margin-left: 4rem
	}
	.lg-mx4 {
		margin-left: 4rem;
		margin-right: 4rem
	}
	.lg-my4 {
		margin-top: 4rem;
		margin-bottom: 4rem
	}
	.lg-m5 {
		margin: 8rem
	}
	.lg-mt5 {
		margin-top: 8rem
	}
	.lg-mr5 {
		margin-right: 8rem
	}
	.lg-mb5 {
		margin-bottom: 8rem
	}
	.lg-ml5 {
		margin-left: 8rem
	}
	.lg-mx5 {
		margin-left: 8rem;
		margin-right: 8rem
	}
	.lg-my5 {
		margin-top: 8rem;
		margin-bottom: 8rem
	}
	.lg-mln1 {
		margin-left: -0.5rem
	}
	.lg-mln2 {
		margin-left: -1rem
	}
	.lg-mln3 {
		margin-left: -2rem
	}
	.lg-mln4 {
		margin-left: -4rem
	}
	.lg-mln5 {
		margin-top: -8rem
	}
	.lg-mtn1 {
		margin-top: -0.5rem
	}
	.lg-mtn2 {
		margin-top: -1rem
	}
	.lg-mtn3 {
		margin-top: -2rem
	}
	.lg-mtn4 {
		margin-top: -4rem
	}
	.lg-mtn5 {
		margin-top: -8rem
	}
	.lg-mxn1 {
		margin-left: -0.5rem;
		margin-right: -0.5rem
	}
	.lg-mxn2 {
		margin-left: -1rem;
		margin-right: -1rem
	}
	.lg-mxn3 {
		margin-left: -2rem;
		margin-right: -2rem
	}
	.lg-mxn4 {
		margin-left: -4rem;
		margin-right: -4rem
	}
	.lg-mxn5 {
		margin-left: -8rem;
		margin-right: -8rem
	}
	.lg-ml-auto {
		margin-left: auto
	}
	.lg-mr-auto {
		margin-right: auto
	}
	.lg-mx-auto {
		margin-left: auto;
		margin-right: auto
	}
}
.unround {
	border-radius: 0
}
.rounded {
	border-radius: 3px
}
.rounded-right {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px
}
.rounded-left {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px
}
.rounded-top {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}
.rounded-bottom {
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}
.rounded-big {
	border-radius: 6px
}
.rounded-big-right {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px
}
.rounded-big-left {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px
}
.rounded-big-top {
	border-top-right-radius: 6px;
	border-top-left-radius: 6px
}
.rounded-big-top-left {
	border-top-left-radius: 6px
}
.rounded-big-top-right {
	border-top-right-radius: 6px
}
.rounded-big-bottom {
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px
}
.rounded-huge {
	border-radius: 14px
}
.rounded-huge-right {
	border-top-right-radius: 14px;
	border-bottom-right-radius: 14px
}
.rounded-huge-left {
	border-top-left-radius: 14px;
	border-bottom-left-radius: 14px
}
.rounded-huge-top {
	border-top-right-radius: 14px;
	border-top-left-radius: 14px
}
.rounded-huge-top-left {
	border-top-left-radius: 14px
}
.rounded-huge-top-right {
	border-top-right-radius: 14px
}
.rounded-huge-bottom {
	border-bottom-right-radius: 14px;
	border-bottom-left-radius: 14px
}
@media (min-width: 40rem) {
	.sm-rounded {
		border-radius: 3px
	}
	.sm-rounded-right {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px
	}
	.sm-rounded-left {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.sm-rounded-top {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px
	}
	.sm-rounded-bottom {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.sm-rounded-big {
		border-radius: 6px
	}
	.sm-rounded-big-right {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px
	}
	.sm-rounded-big-left {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.sm-rounded-big-top {
		border-top-right-radius: 6px;
		border-top-left-radius: 6px
	}
	.sm-rounded-big-top-left {
		border-top-left-radius: 6px
	}
	.sm-rounded-big-top-right {
		border-top-right-radius: 6px
	}
	.sm-rounded-big-bottom {
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.sm-rounded-huge {
		border-radius: 14px
	}
	.sm-rounded-huge-right {
		border-top-right-radius: 14px;
		border-bottom-right-radius: 14px
	}
	.sm-rounded-huge-left {
		border-top-left-radius: 14px;
		border-bottom-left-radius: 14px
	}
	.sm-rounded-huge-top {
		border-top-right-radius: 14px;
		border-top-left-radius: 14px
	}
	.sm-rounded-huge-top-left {
		border-top-left-radius: 14px
	}
	.sm-rounded-huge-top-right {
		border-top-right-radius: 14px
	}
	.sm-rounded-huge-bottom {
		border-bottom-right-radius: 14px;
		border-bottom-left-radius: 14px
	}
}
@media (min-width: 52rem) {
	.md-rounded {
		border-radius: 3px
	}
	.md-rounded-right {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px
	}
	.md-rounded-left {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.md-rounded-top {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px
	}
	.md-rounded-bottom {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.md-rounded-big {
		border-radius: 6px
	}
	.md-rounded-big-right {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px
	}
	.md-rounded-big-left {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.md-rounded-big-top {
		border-top-right-radius: 6px;
		border-top-left-radius: 6px
	}
	.md-rounded-big-top-left {
		border-top-left-radius: 6px
	}
	.md-rounded-big-top-right {
		border-top-right-radius: 6px
	}
	.md-rounded-big-bottom {
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.md-rounded-huge {
		border-radius: 14px
	}
	.md-rounded-huge-right {
		border-top-right-radius: 14px;
		border-bottom-right-radius: 14px
	}
	.md-rounded-huge-left {
		border-top-left-radius: 14px;
		border-bottom-left-radius: 14px
	}
	.md-rounded-huge-top {
		border-top-right-radius: 14px;
		border-top-left-radius: 14px
	}
	.md-rounded-huge-top-left {
		border-top-left-radius: 14px
	}
	.md-rounded-huge-top-right {
		border-top-right-radius: 14px
	}
	.md-rounded-huge-bottom {
		border-bottom-right-radius: 14px;
		border-bottom-left-radius: 14px
	}
}
@media (min-width: 72rem) {
	.lg-rounded {
		border-radius: 3px
	}
	.lg-rounded-right {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px
	}
	.lg-rounded-left {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.lg-rounded-top {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px
	}
	.lg-rounded-bottom {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px
	}
	.lg-rounded-big {
		border-radius: 6px
	}
	.lg-rounded-big-right {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px
	}
	.lg-rounded-big-left {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.lg-rounded-big-top {
		border-top-right-radius: 6px;
		border-top-left-radius: 6px
	}
	.lg-rounded-big-top-left {
		border-top-left-radius: 6px
	}
	.lg-rounded-big-top-right {
		border-top-right-radius: 6px
	}
	.lg-rounded-big-bottom {
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px
	}
	.lg-rounded-huge {
		border-radius: 14px
	}
	.lg-rounded-huge-right {
		border-top-right-radius: 14px;
		border-bottom-right-radius: 14px
	}
	.lg-rounded-huge-left {
		border-top-left-radius: 14px;
		border-bottom-left-radius: 14px
	}
	.lg-rounded-huge-top {
		border-top-right-radius: 14px;
		border-top-left-radius: 14px
	}
	.lg-rounded-huge-top-left {
		border-top-left-radius: 14px
	}
	.lg-rounded-huge-top-right {
		border-top-right-radius: 14px
	}
	.lg-rounded-huge-bottom {
		border-bottom-right-radius: 14px;
		border-bottom-left-radius: 14px
	}
}

.display {
	font-family:'CircularStd',
	-apple-system,
	system-ui,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	Oxygen-Sans,
	Ubuntu,
	Cantarell,
	'Helvetica Neue',
	sans-serif,
	'Apple Color Emoji',
	'Segoe UI Emoji',
	'Segoe UI Symbol';
	letter-spacing:.01em;
	-webkit-font-smoothing:antialiased;
	font-feature-settings:'calt' 0
}
.display.strong,
.strong .display,
.display .strong {
	font-family:'CircularStd',
	-apple-system,
	system-ui,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	Oxygen-Sans,
	Ubuntu,
	Cantarell,
	'Helvetica Neue',
	sans-serif,
	'Apple Color Emoji',
	'Segoe UI Emoji',
	'Segoe UI Symbol'
}
.monospace {
	font-family:Menlo,
	monospace
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin:0;
	font-weight:normal
}
h1 {
	font-size:2rem
}
h2 {
	font-size:1.5rem
}
h3 {
	font-size:1.25rem
}
h4 {
	font-size:1rem
}
h5 {
	font-size:.875rem
}
.normal {
	font-size:.8rem
}
.small {
	font-size:11px
}
.micro {
	font-size:9px
}
.font-family-inherit {
	font-family:inherit
}
.font-size-inherit {
	font-size:inherit
}
.text-decoration-none {
	text-decoration:none
}
.thin {
	font-weight:300
}
.bold,
.strong {
	font-weight:500
}
.stronger {
	font-weight:600
}
.strongest {
	font-weight:700
}
.double-strong {
	font-weight:800
}
.regular {
	font-weight:normal
}
.italic {
	font-style:italic
}
.caps {
	text-transform:uppercase;
	letter-spacing:.1em
}
.uppercase {
	text-transform:uppercase
}
.strikethrough {
	text-decoration:line-through
}
.left-align {
	text-align:left
}
.center {
	text-align:center
}
.right-align {
	text-align:right
}
.justify {
	text-align:justify
}
.nowrap {
	white-space:nowrap
}
.break-word {
	word-wrap:break-word
}
.pre-wrap,
pre.pre-wrap {
	white-space:pre-wrap
}
.hyphens {
	-webkit-hyphens:auto;
	-moz-hyphens:auto;
	-ms-hyphens:auto;
	hyphens:auto
}
.line-height-0 {
	line-height:0
}
.line-height-1 {
	line-height:1
}
.line-height-2 {
	line-height:1.125
}
.line-height-3 {
	line-height:1.25
}
.line-height-4 {
	line-height:1.5
}
.line-height-5 {
	line-height:1.6
}
.list-style-none {
	list-style:none
}
.list-style-inherit {
	list-style-type:inherit
}
.underline {
	text-decoration:underline
}
.truncate {
	max-width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap
}
.truncate-block {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline
}
.truncate-block.line-height-1 {
	max-height:3em
}
.truncate-block.line-height-2 {
	max-height:3.375em
}
.truncate-block.line-height-3 {
	max-height:3.75em
}
.truncate-block.line-height-4 {
	max-height:4.5em
}
.truncate-block.line-height-5 {
	max-height:4.8em
}
.truncate-block-2-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:2
}
.truncate-block-2-lines.line-height-1 {
	max-height:3em
}
.truncate-block-2-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-2-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-2-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-2-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-2-lines.line-height-3 {
	max-height:2.5em
}
.truncate-block-2-lines.line-height-4 {
	max-height:3em
}
.truncate-block-3-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline
}
.truncate-block-3-lines.line-height-1 {
	max-height:3em
}
.truncate-block-3-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-3-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-3-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-3-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-4-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:4
}
.truncate-block-4-lines.line-height-1 {
	max-height:3em
}
.truncate-block-4-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-4-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-4-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-4-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-5-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:5
}
.truncate-block-5-lines.line-height-1 {
	max-height:3em
}
.truncate-block-5-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-5-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-5-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-5-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-6-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:6
}
.truncate-block-6-lines.line-height-1 {
	max-height:3em
}
.truncate-block-6-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-6-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-6-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-6-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-7-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:7
}
.truncate-block-7-lines.line-height-1 {
	max-height:3em
}
.truncate-block-7-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-7-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-7-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-7-lines.line-height-5 {
	max-height:4.8em
}
.truncate-block-8-lines {
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
	text-overflow:-o-ellipsis-lastline;
	-webkit-line-clamp:8
}
.truncate-block-8-lines.line-height-1 {
	max-height:3em
}
.truncate-block-8-lines.line-height-2 {
	max-height:3.375em
}
.truncate-block-8-lines.line-height-3 {
	max-height:3.75em
}
.truncate-block-8-lines.line-height-4 {
	max-height:4.5em
}
.truncate-block-8-lines.line-height-5 {
	max-height:4.8em
}
.cell .truncate-block,
.cell .truncate-block-2-lines,
.cell .truncate-block-3-lines,
.cell .truncate-block-4-lines,
.cell .truncate-block-5-lines,
.cell .truncate-block-6-lines,
.cell .truncate-block-7-lines,
.cell .truncate-block-8-lines {
	max-height:none
}
.huge {
	font-size:1rem
}
.huge.h1 {
	font-size:2.8rem
}
.huge.h2 {
	font-size:2rem
}
.huge.h3 {
	font-size:1.4rem
}
.huge.h4 {
	font-size:1.2rem
}
.huge.h5 {
	font-size:1rem
}
h1.huge,
.huge h1 {
	font-size:2.8rem
}
h2.huge,
.huge h2 {
	font-size:2rem
}
h3.huge,
.huge h3 {
	font-size:1.4rem
}
h4.huge,
.huge h4 {
	font-size:1.2rem
}
h5.huge,
.huge h5 {
	font-size:1rem
}
.big {
	font-size:.9rem
}
.big.h1 {
	font-size:2.25rem
}
.big.h2 {
	font-size:1.75rem
}
.big.h3 {
	font-size:1.25rem
}
.big.h4 {
	font-size:1rem
}
.big.h5 {
	font-size:.9rem
}
h1.big,
.big h1 {
	font-size:2.25rem
}
h2.big,
.big h2 {
	font-size:1.75rem
}
h3.big,
.big h3 {
	font-size:1.25rem
}
h4.big,
.big h4 {
	font-size:1rem
}
h5.big,
.big h5 {
	font-size:.9rem
}
.small {
	font-size:11px
}
.micro {
	font-size:9px
}
@media (max-width: 39.99rem) {
	.xs-huge {
		font-size:1rem
	}
	.xs-huge.h1 {
		font-size: 2.8rem
	}
	.xs-huge.h2 {
		font-size: 2rem
	}
	.xs-huge.h3 {
		font-size: 1.4rem
	}
	.xs-huge.h4 {
		font-size: 1.2rem
	}
	.xs-huge.h5 {
		font-size: 1rem
	}
	*.h1.xs-huge,
	h1.xs-huge,
	.xs-huge h1 {
		font-size: 2.8rem
	}
	*.h2.xs-huge,
	h2.xs-huge,
	.xs-huge h2 {
		font-size: 2rem
	}
	*.h3.xs-huge,
	h3.xs-huge,
	.xs-huge h3 {
		font-size: 1.4rem
	}
	*.h4.xs-huge,
	h4.xs-huge,
	.xs-huge h4 {
		font-size: 1.2rem
	}
	*.h5.xs-huge,
	h5.xs-huge,
	.xs-huge h5 {
		font-size: 1rem
	}
	.xs-big {
		font-size: .9rem
	}
	.xs-big.h1 {
		font-size: 2.25rem
	}
	.xs-big.h2 {
		font-size: 1.75rem
	}
	.xs-big.h3 {
		font-size: 1.25rem
	}
	.xs-big.h4 {
		font-size: 1rem
	}
	.xs-big.h5 {
		font-size: .9rem
	}
	*.h1.xs-big,
	h1.xs-big,
	.xs-big h1 {
		font-size: 2.25rem
	}
	*.h2.xs-big,
	h2.xs-big,
	.xs-big h2 {
		font-size: 1.75rem
	}
	*.h3.xs-big,
	h3.xs-big,
	.xs-big h3 {
		font-size: 1.25rem
	}
	*.h4.xs-big,
	h4.xs-big,
	.xs-big h4 {
		font-size: 1rem
	}
	*.h5.xs-big,
	h5.xs-big,
	.xs-big h5 {
		font-size: .9rem
	}
	.xs-small {
		font-size: 11px
	}
	.xs-micro {
		font-size: 9px
	}
	.xs-right-align {
		text-align: right
	}
	.xs-center {
		text-align: center
	}
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
	.sm-huge {
		font-size:1rem
	}
	.sm-huge.h1 {
		font-size: 2.8rem
	}
	.sm-huge.h2 {
		font-size: 2rem
	}
	.sm-huge.h3 {
		font-size: 1.4rem
	}
	.sm-huge.h4 {
		font-size: 1.2rem
	}
	.sm-huge.h5 {
		font-size: 1rem
	}
	*.h1.sm-huge,
	h1.sm-huge,
	.sm-huge h1 {
		font-size: 2.8rem
	}
	*.h2.sm-huge,
	h2.sm-huge,
	.sm-huge h2 {
		font-size: 2rem
	}
	*.h3.sm-huge,
	h3.sm-huge,
	.sm-huge h3 {
		font-size: 1.4rem
	}
	*.h4.sm-huge,
	h4.sm-huge,
	.sm-huge h4 {
		font-size: 1.2rem
	}
	*.h5.sm-huge,
	h5.sm-huge,
	.sm-huge h5 {
		font-size: 1rem
	}
	.sm-big {
		font-size: .9rem
	}
	.sm-big.h1 {
		font-size: 2.25rem
	}
	.sm-big.h2 {
		font-size: 1.75rem
	}
	.sm-big.h3 {
		font-size: 1.25rem
	}
	.sm-big.h4 {
		font-size: 1rem
	}
	.sm-big.h5 {
		font-size: .9rem
	}
	*.h1.sm-big,
	h1.sm-big,
	.sm-big h1 {
		font-size: 2.25rem
	}
	*.h2.sm-big,
	h2.sm-big,
	.sm-big h2 {
		font-size: 1.75rem
	}
	*.h3.sm-big,
	h3.sm-big,
	.sm-big h3 {
		font-size: 1.25rem
	}
	*.h4.sm-big,
	h4.sm-big,
	.sm-big h4 {
		font-size: 1rem
	}
	*.h5.sm-big,
	h5.sm-big,
	.sm-big h5 {
		font-size: .9rem
	}
	.sm-small {
		font-size: 11px
	}
	.sm-micro {
		font-size: 9px
	}
	.sm-right-align {
		text-align: right
	}
	.sm-center {
		text-align: center
	}
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
	.md-huge {
		font-size:1rem
	}
	.md-huge.h1 {
		font-size: 2.8rem
	}
	.md-huge.h2 {
		font-size: 2rem
	}
	.md-huge.h3 {
		font-size: 1.4rem
	}
	.md-huge.h4 {
		font-size: 1.2rem
	}
	.md-huge.h5 {
		font-size: 1rem
	}
	*.h1.md-huge,
	h1.md-huge,
	.md-huge h1 {
		font-size: 2.8rem
	}
	*.h2.md-huge,
	h2.md-huge,
	.md-huge h2 {
		font-size: 2rem
	}
	*.h3.md-huge,
	h3.md-huge,
	.md-huge h3 {
		font-size: 1.4rem
	}
	*.h4.md-huge,
	h4.md-huge,
	.md-huge h4 {
		font-size: 1.2rem
	}
	*.h5.md-huge,
	h5.md-huge,
	.md-huge h5 {
		font-size: 1rem
	}
	.md-big {
		font-size: .9rem
	}
	.md-big.h1 {
		font-size: 2.25rem
	}
	.md-big.h2 {
		font-size: 1.75rem
	}
	.md-big.h3 {
		font-size: 1.25rem
	}
	.md-big.h4 {
		font-size: 1rem
	}
	.md-big.h5 {
		font-size: .9rem
	}
	*.h1.md-big,
	h1.md-big,
	.md-big h1 {
		font-size: 2.25rem
	}
	*.h2.md-big,
	h2.md-big,
	.md-big h2 {
		font-size: 1.75rem
	}
	*.h3.md-big,
	h3.md-big,
	.md-big h3 {
		font-size: 1.25rem
	}
	*.h4.md-big,
	h4.md-big,
	.md-big h4 {
		font-size: 1rem
	}
	*.h5.md-big,
	h5.md-big,
	.md-big h5 {
		font-size: .9rem
	}
	.md-small {
		font-size: 11px
	}
	.md-micro {
		font-size: 9px
	}
	.md-right-align {
		text-align: right
	}
	.md-center {
		text-align: center
	}
}
@media (min-width: 72rem) {
	.lg-huge {
		font-size:1rem
	}
	.lg-huge.h1 {
		font-size: 2.8rem
	}
	.lg-huge.h2 {
		font-size: 2rem
	}
	.lg-huge.h3 {
		font-size: 1.4rem
	}
	.lg-huge.h4 {
		font-size: 1.2rem
	}
	.lg-huge.h5 {
		font-size: 1rem
	}
	*.h1.lg-huge,
	h1.lg-huge,
	.lg-huge h1 {
		font-size: 2.8rem
	}
	*.h2.lg-huge,
	h2.lg-huge,
	.lg-huge h2 {
		font-size: 2rem
	}
	*.h3.lg-huge,
	h3.lg-huge,
	.lg-huge h3 {
		font-size: 1.4rem
	}
	*.h4.lg-huge,
	h4.lg-huge,
	.lg-huge h4 {
		font-size: 1.2rem
	}
	*.h5.lg-huge,
	h5.lg-huge,
	.lg-huge h5 {
		font-size: 1rem
	}
	.lg-big {
		font-size: .9rem
	}
	.lg-big.h1 {
		font-size: 2.25rem
	}
	.lg-big.h2 {
		font-size: 1.75rem
	}
	.lg-big.h3 {
		font-size: 1.25rem
	}
	.lg-big.h4 {
		font-size: 1rem
	}
	.lg-big.h5 {
		font-size: .9rem
	}
	*.h1.lg-big,
	h1.lg-big,
	.lg-big h1 {
		font-size: 2.25rem
	}
	*.h2.lg-big,
	h2.lg-big,
	.lg-big h2 {
		font-size: 1.75rem
	}
	*.h3.lg-big,
	h3.lg-big,
	.lg-big h3 {
		font-size: 1.25rem
	}
	*.h4.lg-big,
	h4.lg-big,
	.lg-big h4 {
		font-size: 1rem
	}
	*.h5.lg-big,
	h5.lg-big,
	.lg-big h5 {
		font-size: .9rem
	}
	.lg-small {
		font-size: 11px
	}
	.lg-micro {
		font-size: 9px
	}
	.lg-right-align {
		text-align: right
	}
	.lg-center {
		text-align: center
	}
}
.cursor-pointer,
.pointer {
	cursor:pointer
}
.cursor-text {
	cursor:text
}
.cursor-default {
	cursor:default
}
.cursor-grab {
	cursor:grab;
	cursor:-webkit-grab;
	cursor:-moz-grab
}
.cursor-grabbing {
	cursor:grabbing;
	cursor:-moz-grabbing;
	cursor:-webkit-grabbing
}
.cursor-col-resize {
	cursor:col-resize
}
.cursor-row-resize {
	cursor:row-resize
}
.border {
	border-style:solid;
	border-width:1px
}
.border-thick {
	border-style:solid;
	border-width:2px
}
.border-thick1 {
	border-style:solid;
	border-width:3px
}
.border-thick2 {
	border-style:solid;
	border-width:5px
}
.border-top {
	border-top-style:solid;
	border-top-width:1px
}
.border-top-thick {
	border-top-style:solid;
	border-top-width:2px
}
.border-top-thick1 {
	border-top-style:solid;
	border-top-width:3px
}
.border-right {
	border-right-style:solid;
	border-right-width:1px
}
.border-right-thick {
	border-right-style:solid;
	border-right-width:2px
}
.border-right-thick1 {
	border-right-style:solid;
	border-right-width:3px
}
.border-bottom {
	border-bottom-style:solid;
	border-bottom-width:1px
}
.border-bottom-thick {
	border-bottom-style:solid;
	border-bottom-width:2px
}
.border-bottom-thick1 {
	border-bottom-style:solid;
	border-bottom-width:3px
}
.border-left {
	border-left-style:solid;
	border-left-width:1px
}
.border-left-thick {
	border-left-style:solid;
	border-left-width:2px
}
.border-left-thick1 {
	border-left-style:solid;
	border-left-width:3px
}
.border-none {
	border:0
}
.border-transparent {
	border-color:transparent
}
.border-dashed {
	border-style:dashed
}
.border-dotted {
	border-style:dotted
}
.list-reset {
	list-style:none;
	padding-left:0
}
.inline {
	display:inline
}
.block {
	display:block
}
.inline-block {
	display:inline-block
}
.table {
	display:table
}
.table-cell {
	display:table-cell
}
.overflow-hidden {
	overflow:hidden
}
.overflow-scroll {
	overflow:scroll;
	-webkit-overflow-scrolling:touch
}
.overflowy-scroll {
	overflow-y:scroll;
	-webkit-overflow-scrolling:touch
}
.overflowx-scroll {
	overflow-x:scroll;
	-webkit-overflow-scrolling:touch
}
.overflow-auto {
	overflow:auto
}
.overflow-visible {
	overflow:visible
}
.webkit-touch-scroll {
	-webkit-overflow-scrolling:touch
}
.scrollbar-hidden::-webkit-scrollbar {
	display:none;
	width:0;
	height:0
}
.clearfix:before,
.clearfix:after {
	content:" ";
	display:table
}
.clearfix:after {
	clear:both
}
.left {
	float:left
}
.right {
	float:right
}
.fit {
	max-width:100%
}
.border-box {
	box-sizing:border-box
}
.content-box {
	box-sizing:content-box
}
.align-baseline {
	vertical-align:baseline
}
.align-top {
	vertical-align:top
}
.align-middle {
	vertical-align:middle
}
.align-bottom {
	vertical-align:bottom
}
.relative {
	position:relative
}
.absolute {
	position:absolute
}
.fixed {
	position:fixed
}
.static {
	position:static
}
.top-0 {
	top:0
}
.right-0 {
	right:0
}
.bottom-0 {
	bottom:0
}
.left-0 {
	left:0
}
.all-0 {
	top:0;
	left:0;
	right:0;
	bottom:0
}
.z1 {
	z-index:1
}
.z2 {
	z-index:2
}
.z3 {
	z-index:3
}
.z4 {
	z-index:4
}
.circle {
	border-radius:50%
}
.pill {
	border-radius:9999px
}
.max-width-1 {
	max-width:24rem
}
.max-width-2 {
	max-width:32rem
}
.max-width-3 {
	max-width:48rem
}
.max-width-4 {
	max-width:65rem
}
.sm-vh-show {
	display:none
}
@media (max-height: 600px) {
	.sm-vh-hide {
		display:none
	}
	.sm-vh-show {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}
}
@media (max-width: 39.99rem) {
	.xs-top-0 {
		top:0
	}
	.xs-left-0 {
		left: 0
	}
	.xs-right-0 {
		right: 0
	}
	.xs-hide {
		display: none
	}
	.xs-max-width-1 {
		max-width: 24rem
	}
	.xs-max-width-2 {
		max-width: 32rem
	}
	.xs-max-width-3 {
		max-width: 48rem
	}
	.xs-max-width-4 {
		max-width: 72rem
	}
}
@media (min-width: 40rem) and (max-width: 51.99rem) {
	.sm-top-0 {
		top:0
	}
	.sm-left-0 {
		left: 0
	}
	.sm-right-0 {
		right: 0
	}
	.sm-hide {
		display: none
	}
	.sm-max-width-1 {
		max-width: 24rem
	}
	.sm-max-width-2 {
		max-width: 32rem
	}
	.sm-max-width-3 {
		max-width: 48rem
	}
	.sm-max-width-4 {
		max-width: 72rem
	}
}
@media (min-width: 52rem) and (max-width: 71.99rem) {
	.md-top-0 {
		top:0
	}
	.md-left-0 {
		left: 0
	}
	.md-right-0 {
		right: 0
	}
	.md-hide {
		display: none
	}
	.md-max-width-1 {
		max-width: 24rem
	}
	.md-max-width-2 {
		max-width: 32rem
	}
	.md-max-width-3 {
		max-width: 48rem
	}
	.md-max-width-4 {
		max-width: 72rem
	}
}
@media (min-width: 72rem) {
	.lg-top-0 {
		top:0
	}
	.lg-left-0 {
		left: 0
	}
	.lg-right-0 {
		right: 0
	}
	.lg-hide {
		display: none
	}
	.lg-max-width-1 {
		max-width: 24rem
	}
	.lg-max-width-2 {
		max-width: 32rem
	}
	.lg-max-width-3 {
		max-width: 48rem
	}
	.lg-max-width-4 {
		max-width: 72rem
	}
}
.display-none,
.hide {
	display:none
}
.hover-underline:hover {
	text-decoration:underline
}
.quiet {
	opacity:.75
}
.quieter {
	opacity:.5
}
.quietest {
	opacity:.25
}
.noevents {
	pointer-events:none
}
.noevents .events,
.child-events>*,
.events {
	pointer-events:all
}
.no-user-select {
	user-select:none;
	-webkit-user-select:none
}
.no-highlight {
	-webkit-tap-highlight-color:rgba(0,
	0,
	0,
	0)
}
.aspect-scale {
	max-width:100%;
	width:100%;
	height:auto
}
.aspect-scale-height {
	max-width:auto;
	height:100%;
	width:auto
}
input.small {
	font-size:12px
}
.height-fill {
	min-height:100vh
}
.flat-shadow {
	position:relative
}
.flat-shadow::after {
	content:'';
	position:absolute;
	background:hsla(0,
	0%,
	0%,
	0.1);
	pointer-events:none;
	top:6px;
	bottom:-6px;
	left:6px;
	right:-6px;
	z-index:-1
}
.height-fill {
	min-height:100vh
}
.rounded.flat-shadow::after {
	border-radius:3px
}
.rounded-big.flat-shadow::after {
	border-radius:6px;
	top:8px;
	bottom:-8px;
	left:8px;
	right:-8px
}
.appIcon.flat-shadow::after {
	display:none;
	border-radius:16px
}
.dragging .appIcon.flat-shadow::after {
	display:block
}
.multiply {
	mix-blend-mode:multiply
}
.luminosity {
	mix-blend-mode:luminosity
}
.invisible,
.hover-container:hover .parent-hover-invisible {
	opacity:0
}
.visible,
.hover-container:hover .parent-hover-visible,
.hover-container:hover .parent-hover-unquiet {
	opacity:1
}
.background-cover {
	background-size:cover
}
.background-contain {
	background-size:contain
}
.background-norepeat {
	background-repeat:no-repeat
}
.background-center {
	background-position:center center
}
.background-size-half {
	background-size:50%
}
.background-none {
	background-color:none
}
.background-transparent {
	background-color:transparent
}
.image-rendering-optimize-quality {
	image-rendering:optimizeQuality;
	image-rendering:-webkit-optimize-contrast
}
.hover-show:not(:hover) {
	opacity:0
}
.target-show,
.target-hide:target {
	display:none
}
.target-hide,
.target-show:target {
	display:block
}
.dragging .hide-drag {
	display:none
}
.hover-container:hover .parent-hover-flex {
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex
}
.hover-container:hover .parent-hover-flex-inline {
	display:-webkit-inline-flex;
	display:-ms-inline-flexbox;
	display:inline-flex
}
.DraftEditor-root .public-DraftEditor-content {
	border:0
}
.light-scrollbar::-webkit-scrollbar {
	width:12px;
	height:12px;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0
}
.light-scrollbar::-webkit-scrollbar-button {
	display:none;
	height:0;
	width:0
}
.light-scrollbar::-webkit-scrollbar-thumb {
	background-color:hsla(0,
	0%,
	0%,
	0.35);
	background-clip:padding-box;
	border:3px solid rgba(0,
	0,
	0,
	0);
	border-radius:6px;
	min-height:36px
}
.light-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color:hsla(0,
	0%,
	0%,
	0.4)
}
.list-style-disc li {
	list-style-type:disc
}
.list-style-outside li {
	list-style-position:outside
}
.detailCursor .detailCursor-text-blue,
.cell.expanded .detailCursor-text-blue {
	color:#2d7ff9
}
.detailCursor .detailCursor-border-blue,
.cell.expanded .detailCursor-border-blue,
.detailCursor .detailCursor-border-blue:hover,
.cell.expanded .detailCursor-border-blue:hover,
.detailCursor .detailCursor-border-blue:active,
.cell.expanded .detailCursor-border-blue:active,
.detailCursor .detailCursor-border-blue:focus,
.cell.expanded .detailCursor-border-blue:focus {
	border-color:#2d7ff9
}
.detailCursor .detailCursor-stroked-blue-focus:active,
.cell.expanded .detailCursor-stroked-blue-focus:active,
.detailCursor .detailCursor-stroked-blue-focus:focus,
.cell.expanded .detailCursor-stroked-blue-focus:focus {
	box-shadow:inset 0 0 0 2px #cfdfff
}
.content-using-data-attr:before {
	content:attr(data-content)
}
.no-outline {
	outline:0
}
@media print {
	.hide-print {
		display:none;
		visibility:hidden
	}
	.text-white,
	.choiceToken {
		color: black
	}
}




.bubble-wrap {
    overflow: hidden;
    height: 600px;
  }
  
  .bubbles {
    position: relative;
    background: salmon;
  }
  
  .bubble {
    position: absolute;
    width: 152px;
    height: 152px;
    /* border-radius: 50%; */
    /* box-shadow: 
      0 15px 35px rgba(0, 0, 0, 0.1),
      0 3px 10px rgba(0, 0, 0, 0.1); */
    /* background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/9332/stripe-logo-bubbles-spritesheet.png);
    background-size: 1076px 1076px; */
    background-repeat: no-repeat;
  }


  .logoBubble {
      /* background-color: #fff; */
        background-size:100%;
      background-position:center;
      background-repeat: no-repeat;
      
  }



  .how-it-work-block {
      padding:1rem !important;
      margin-bottom: 1rem !important;
	  background: transparent;
	  display:block;
      
  }
  .how-it-work-block.active {
    background: #ffeeed;
  }

  .how-it-work-block  small {
    color: #595959;
    font-weight: 300;
  }

  .hiw-button {
      display:none
  }
  .hiw-button.active {
      display:flex;
  }


  .grid2x2 {
	
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
  }
  .grid2x2 > div {
	display: flex; 
	flex-basis: calc(50% - 40px);  
	
	flex-direction: column;
  }
  .grid2x2 > div > div {
	display: flex;

	flex-direction: row;
  }

  .grid2x2 .box > div {
	background: #f3f3f3;
    height: 10px;
	margin-bottom: 12px;
	width:80%;
  }

  .haa-gridbox-green {
	  background: green;
	  width:0%;
  }

  .fill-orange {
	  fill: chocolate;
  }
  


  .hero-animation-image {
	  width:100%;
	  display:flex;
	  align-items: center;
  }

  .hero-animation-image-wrapper {
	  position: relative;
	  height:55vh;
	  display:flex;
	  align-items:center;
	  border: 1px solid #eee;
	  border-radius: 4px;
	  width:100%;
	  justify-content: center;
  }


  .hero-auto-apply-wrapper {
	  padding:40px;
	  /* height:100%; */
		width:100%;
	  background-size:cover;
	  
  }

  .hero-animation-image .hero-animation-image--abs {
	  position: absolute;
	  z-index:1
  }

  .hero-animation-image .hero-animation-image--first {
	top:0;
	}

	.hero-animation-image .hero-animation-image--sec {
		top:20%
	}

	.hero-animation-image .hero-animation-image--thr {
		top:100px
	}





	.haa-container {
		background: #fff;
		box-shadow: 0px 1px 5px rgba(208,208,208,0.4);
		display:flex;
		border-radius: 10px;
		border: 1px solid #ecececcc;
		/* transition: all 1s ease-in; */
		font-family:'CircularStd', sans-serif;
	}

	.haa-auto-ask-container {
		background: #fff;
		box-shadow: 0px 1px 5px rgba(208,208,208,0.4);
		display:flex;
		border-radius: 10px;
		border: 1px solid #ecececcc;
		/* transition: all 1s ease-in; */
		font-family:'CircularStd', sans-serif;
		width: 60%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 0;
		position: relative;
	}

	.haa-auto-ask-container .haa-cusor {
		width:30px;
		position: absolute;
		top: 80%;
    	right: 10%;
	}

	.haa-auto-ask-container--img {
		display:flex;
		align-items: center;
		margin-bottom:15px;
	}

	.haa-auto-ask-container .haa-auto-ask-container--img > img {
		width:50%;
		margin: 0 auto;
	}


	.cou-ic {

		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
	
	}
	.cou-ic img {
		width:20px;
		margin-right:5px;
		fill: chocolate;
		height:23px;
	}

	.haa-auto-ask-container .haa-auto-ask-text {
		font-size:18px;
		font-weight: 500;
		margin-bottom: 1rem;
		display:flex;
		align-items: center;
	}

	.haa-auto-ask-container .haa-auto-ask-button {
		padding:10px 25px;
		background: #f15521;
		font-size:14px;
		color:#fff;
	}

	/* .haa-auto-ask-container .animate-bounce-in */

	.haa-left {
		padding: 20px 40px;
		display:flex;
		flex-direction: column;
		/* align-items: center; */
		justify-content: center;
		
	}

	.haa-logo img {
		width:90px;
		margin-top: 20px;
	}

	.haa-main-text {
		font-size:18px;
		font-weight:500;
		margin-bottom: 20px;
		
	}

	.progress-contianer {
		width:100%;
		height:5px;
		background: #efefef;
		border-radius: 10px;
		margin-bottom: 20px;
	}

	.progress-bar {
		width:0%;
		background: chocolate;
		height: 5px;
		border-radius: 10px;
	}

	.haa-right {
		background: #f6f7fa;
		width:80%;
		display:flex;
		align-items: center;
		padding:40px;
	}

	.haa-right--img {
		width:100%;
	}

	.haa-success--main {
		font-size: 21px;
		font-weight: 600;
		margin-bottom: 6px;
	}

	.haa-success-price {
		font-size: 22px;
		font-weight: 600;
		color: #417505;
	}

	.chrome-ext {
		position: absolute;
		height:100%;
		top:0;
	}

	.chrome-ext img {
		width:100%;
	}

.StripeBackground {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: skewY(-12deg);
    transform: skewY(-12deg);
    pointer-events: none;
}

.StripeBackground {
    top: calc(50% + 310px);
}



.StripeBackground .stripe {
    height: 200px;
}
.StripeBackground .stripe {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
}

.StripeBackground .s0 {
    height: 5000px;
    bottom: 200px;
    bottom: calc(200px * 1);
    background: -webkit-gradient(linear,left top,right top,from(#e4ecf5),to(#f5fdff));
    background: linear-gradient(90deg,#e4ecf5,#f5fdff);
}

.StripeBackground .s1 {
    bottom: 0;
    left: calc(50% + 220px);
    background: -webkit-gradient(linear,left top,right top,from(#f4fafd),to(#eff8fc));
    background: linear-gradient(90deg,#f4fafd,#eff8fc);
}

.StripeBackground .s2 {
    bottom: 200px;
    bottom: calc(200px * 1);
    right: calc(50% - 220px);
    background: -webkit-gradient(linear,left top,right top,from(#d6e3f0),to(#e7f3fa));
    background: linear-gradient(90deg,#d6e3f0,#e7f3fa);
}

.StripeBackground .s3 {
    bottom: 600px;
    bottom: calc(200px * 3);
    left: 10%;
    right: calc(50% - 300px);
    background: -webkit-gradient(linear,left top,right top,from(#ecf4fa),to(#e4eff6));
    background: linear-gradient(90deg,#ecf4fa,#e4eff6);
}

